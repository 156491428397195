import { computedFrom } from 'aurelia-framework';
import { Person } from '../../../classes/EntityManager/entities/Person/types';
import { PersonAdapter } from '../EntityAdapter/PersonAdapter/PersonAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class PersonPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<PersonAdapter>,
  Person
> {
  @computedFrom('combinedRevision')
  public get canManageContacts(): boolean {
    return this.checkAdapterSpecificPermissionByName('canManageContacts');
  }

  @computedFrom('combinedRevision')
  public get canManageRelations(): boolean {
    return this.checkAdapterSpecificPermissionByName('canManageRelations');
  }
}
