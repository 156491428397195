import { autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { configureHooks } from '../../hooks/configureHooks';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditProcessConfigurationStepDialog {
  @subscribableLifecycle()
  protected readonly processConfigurationStepPermissionHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationStep];

  protected processConfigurationStep: ProcessConfigurationStep | null = null;
  protected processConfiguration: ProcessConfiguration | null = null;
  protected onDialogClosed: ((step: ProcessConfigurationStep) => void) | null =
    null;

  protected dialog: RecordItDialog | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processConfigurationStepPermissionHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessConfigurationStep,
        context: this,
        expression: 'processConfigurationStep'
      });
  }

  public static async open(
    options: TEditProcessConfigurationStepDialogOpenOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(options: TEditProcessConfigurationStepDialogOpenOptions): void {
    this.processConfigurationStep = options.processConfigurationStep;
    this.processConfiguration =
      this.entityManager.processConfigurationRepository.getById(
        this.processConfigurationStep.ownerProcessConfigurationId
      );
    this.onDialogClosed = options.onDialogClosed;

    if (this.dialog) {
      this.dialog.open();
    }
  }

  protected handleDialogClosed(): void {
    assertNotNullOrUndefined(
      this.processConfigurationStep,
      'cannot handleDialogClosed without processConfigurationStep'
    );
    const processConfigurationStep = this.processConfigurationStep;
    const onClosed = this.onDialogClosed;

    this.processConfigurationStep = null;
    this.processConfiguration = null;
    this.onDialogClosed = null;

    onClosed && onClosed(processConfigurationStep);
  }

  protected handleProcessConfigurationStepChanged(): void {
    if (this.processConfigurationStep) {
      this.entityManager.processConfigurationStepRepository.update(
        this.processConfigurationStep
      );
    }
  }
}

export type TEditProcessConfigurationStepDialogOpenOptions = {
  processConfigurationStep: ProcessConfigurationStep;
  onDialogClosed: ((step: ProcessConfigurationStep) => void) | null;
};
