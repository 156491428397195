import { autoinject, bindable } from 'aurelia-framework';
import { Vector } from 'common/Geometry/Vector';
import { Key } from '../../classes/Key';
import { TooltipContent } from '../../aureliaComponents/tooltip-content/tooltip-content';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';

/**
 * @event {ValueChangedEvent} value-changed-event
 */
@autoinject()
export class AutocompleteInput {
  /**
   * the currently inputted text
   */
  @bindable()
  public value: string | null = null;

  @bindable()
  public label: string | null = null;

  @bindable()
  public noLabel: boolean = false;

  /**
   * an array of all autocomplete suggestions
   */
  @bindable()
  public items: Array<string> = [];

  @bindable()
  public placeholder: string = '';

  @bindable()
  public enabled: boolean = false;

  /**
   * minimum length of the inputted text to show the filtered items
   */
  @bindable()
  public minFilterLength: number = 0;

  private readonly marginVector = new Vector(0, 0);
  private readonly dropdownTargetAlignment = new Vector(0, 1);
  private readonly dropdownTooltipAlignment = new Vector(0, 0);

  private isAttached: boolean = false;
  private displayedValue: string = '';
  private displayedItems: Array<string> = [];
  private highlightedItem: string | null = null;
  private tooltipContentOpen: boolean = false;
  private tooltipContentViewModel: TooltipContent | null = null;
  private inputIsFocused: boolean = false;

  constructor(private readonly element: Element) {}

  protected attached(): void {
    this.isAttached = true;
  }

  protected detached(): void {
    this.isAttached = false;
  }

  private itemsChanged(): void {
    if (this.tooltipContentOpen) {
      this.updateDisplayedItems();
    }
  }

  private minFilterLengthChanged(): void {
    if (this.tooltipContentOpen) {
      this.updateDisplayedItems();
    }
  }

  private valueChanged(): void {
    this.displayedValue = this.value ?? '';
  }

  private handleKeyDown(event: KeyboardEvent): boolean {
    switch (event.key) {
      case Key.ENTER:
        if (this.highlightedItem) {
          this.handleItemClick(this.highlightedItem);
          return false;
        }
        break;
      case Key.TAB:
        this.closeTooltipContent();
        return true;

      case Key.ESCAPE:
        this.closeTooltipContent();
        return false;
      default:
    }

    this.openTooltipContent();

    switch (event.key) {
      case Key.ARROW_UP:
        this.highlightPreviousItem();
        return false;
      case Key.ARROW_DOWN:
        this.highlightNextItem();
        return false;
      case Key.HOME:
        this.highlightFirstDisplayedItem();
        return false;
      case Key.END:
        this.highlightLastDisplayedItem();
        return false;
      default:
    }

    return true;
  }

  private handleInput(): boolean {
    this.updateDisplayedItems();
    return true;
  }

  private handleBlur(): void {
    this.setValue(this.displayedValue);
  }

  private handleItemClick(item: string): void {
    this.setValue(item);
    this.closeTooltipContent();
  }

  private handleTooltipContentClosed(): void {
    this.tooltipContentOpen = false;
  }

  private openTooltipContent(): void {
    this.tooltipContentViewModel?.open();
    this.tooltipContentOpen = true;
    this.updateDisplayedItems();
  }

  private closeTooltipContent(): void {
    this.tooltipContentViewModel?.close();
    this.highlightedItem = null;
  }

  private updateDisplayedItems(): void {
    this.displayedItems =
      this.displayedValue.length >= this.minFilterLength
        ? this.items.filter((i) => i.includes(this.displayedValue))
        : [];
    if (
      this.highlightedItem != null &&
      !this.displayedItems.includes(this.highlightedItem)
    ) {
      this.highlightedItem = null;
    }
  }

  private setValue(value: string | null): void {
    const valueToSet = value !== '' ? value : null;
    if (valueToSet !== this.value) {
      this.value = valueToSet;
      setTimeout(() => {
        DomEventHelper.fireEvent<ValueChangedEvent>(this.element, {
          name: 'value-changed',
          detail: { value }
        });
      }, 0);
    }
  }

  private highlightPreviousItem(): void {
    if (this.highlightedItem !== null) {
      const index = this.displayedItems.indexOf(this.highlightedItem);
      this.highlightedItem =
        index >= 0 ? this.displayedItems[index - 1] ?? null : null;
    } else {
      this.highlightLastDisplayedItem();
    }
  }

  private highlightNextItem(): void {
    if (this.highlightedItem !== null) {
      const index = this.displayedItems.indexOf(this.highlightedItem);
      this.highlightedItem =
        index >= 0 ? this.displayedItems[index + 1] ?? null : null;
    } else {
      this.highlightFirstDisplayedItem();
    }
  }

  private highlightFirstDisplayedItem(): void {
    this.highlightedItem = this.displayedItems[0] ?? null;
  }

  private highlightLastDisplayedItem(): void {
    this.highlightedItem =
      this.displayedItems[this.displayedItems.length - 1] ?? null;
  }
}

export type ValueChangedEvent = NamedCustomEvent<
  'value-changed',
  { value: string | null }
>;
