import { autoinject, bindable, computedFrom } from 'aurelia-framework';

import { GalleryThingPictureOverviewEntry } from '../../classes/GalleryThing/GalleryThingPictureOverviewEntryHelper';

@autoinject()
export class GalleryThingPlanBasedOverviewMarker {
  @bindable
  public pictureOverviewEntry: GalleryThingPictureOverviewEntry | null = null;

  /**
   * any css color
   */
  @bindable
  public color: string = '#ff401f';

  @computedFrom('color')
  protected get markerOutlineStyle(): string {
    return `fill:${this.color};fill-opacity:1;stroke:#ffffff;stroke-opacity:1;stroke-width:2;stroke-miterlimit:4;stroke-dasharray:none`;
  }

  @computedFrom('pictureOverviewEntry.coordsFromPositionedPictureInfo')
  protected get position(): Position | null {
    return this.pictureOverviewEntry?.coordsFromPositionedPictureInfo?.top &&
      this.pictureOverviewEntry.coordsFromPositionedPictureInfo.left
      ? {
          top: this.pictureOverviewEntry.coordsFromPositionedPictureInfo.top,
          left: this.pictureOverviewEntry.coordsFromPositionedPictureInfo.left
        }
      : null;
  }
}

type Position = {
  top: string;
  left: string;
};
