import { SortUtils } from 'common/Utils/SortUtils';

import { UserDefinedEntity } from './types';
import {
  SupportedTemplateUserDefinedEntity,
  SupportedUserDefinedEntityBaseEntity,
  UserDefinedEntityOfBaseEntity
} from '../../../../dialogs/copy-user-defined-entity-dialog/copy-user-defined-entity-dialog';
import { UserDefinedEntityConfigPropertyConfig } from '../UserDefinedEntityConfigPropertyConfig/types';
import { AppEntityManager } from '../AppEntityManager';
import { UserDefinedEntityConfigPropertyConfigCustomColumn } from 'common/Types/Entities/UserDefinedEntityConfigPropertyConfig/UserDefinedEntityConfigPropertyConfigDto';

export class UserDefinedEntityUtils {
  public static sortOptions = {
    name: {
      name: 'Name',
      sortFunction: function (a: UserDefinedEntity, b: UserDefinedEntity) {
        const aName = a.name ? a.name : '';
        const bName = b.name ? b.name : '';
        return SortUtils.localeCompareFalsyNumericStrings(aName, bName);
      }
    },
    updatedAt: {
      name: 'Geändert',
      sortFunction: function (a: UserDefinedEntity, b: UserDefinedEntity) {
        const aDate = a.updatedAt ? a.updatedAt : a.createdAt ?? '';
        const bDate = b.updatedAt ? b.updatedAt : b.createdAt ?? '';
        return aDate.localeCompare(bDate);
      }
    },
    customId: {
      name: 'customId',
      sortFunction: function (a: UserDefinedEntity, b: UserDefinedEntity) {
        return SortUtils.localeCompareFalsyNumericStrings(
          a.customId,
          b.customId
        );
      }
    },
    entityConfigName: {
      name: 'entityConfigName',
      sortFunction: function (a: UserDefinedEntity, b: UserDefinedEntity) {
        return SortUtils.localeCompareFalsyNumericStrings(
          a.userDefinedEntityConfigId,
          b.userDefinedEntityConfigId
        );
      }
    }
  };

  public static allShownEntitiesBelongToSameEntityConfig<
    TBaseEntity extends SupportedUserDefinedEntityBaseEntity
  >({
    filteredUserDefinedEntities
  }: {
    filteredUserDefinedEntities: Array<
      | UserDefinedEntityOfBaseEntity<TBaseEntity>
      | SupportedTemplateUserDefinedEntity<TBaseEntity>
    >;
  }): boolean {
    const firstEntityConfigId =
      filteredUserDefinedEntities[0]?.userDefinedEntityConfigId;
    if (
      !!firstEntityConfigId &&
      filteredUserDefinedEntities.every(
        (x) => x.userDefinedEntityConfigId === firstEntityConfigId
      )
    ) {
      return true;
    }

    return false;
  }

  /**
    Use this computed decorator if used within a getter:
    (`entityManager` not necessary if you are in a component and the entityManager is injected by Aurelia)

    @computed(
    expression('filteredUserDefinedEntities'),
    model(EntityName.UserDefinedEntityConfigPropertyConfig),
    model(EntityName.UserDefinedEntityConfig),
    expression('entityManager')
  ) */
  public static getCustomColumnName<
    TBaseEntity extends SupportedUserDefinedEntityBaseEntity
  >({
    filteredUserDefinedEntities,
    entityManager,
    customColumnType
  }: {
    filteredUserDefinedEntities: Array<
      | UserDefinedEntityOfBaseEntity<TBaseEntity>
      | SupportedTemplateUserDefinedEntity<TBaseEntity>
    >;
    entityManager: AppEntityManager;
    customColumnType: UserDefinedEntityConfigPropertyConfigCustomColumn;
  }): string | null {
    const propertiesOfEntityConfig = this.getPropertiesOfFirstEntityConfig({
      filteredUserDefinedEntities,
      entityManager
    });
    const firstCustomColumnProperty = propertiesOfEntityConfig.find(
      (x) => x.customColumn === customColumnType
    );

    return firstCustomColumnProperty?.name ?? null;
  }

  private static getPropertiesOfFirstEntityConfig<
    TBaseEntity extends SupportedUserDefinedEntityBaseEntity
  >({
    filteredUserDefinedEntities,
    entityManager
  }: {
    filteredUserDefinedEntities: Array<
      | UserDefinedEntityOfBaseEntity<TBaseEntity>
      | SupportedTemplateUserDefinedEntity<TBaseEntity>
    >;
    entityManager: AppEntityManager;
  }): Array<UserDefinedEntityConfigPropertyConfig> {
    const firstEntityConfigId =
      filteredUserDefinedEntities[0]?.userDefinedEntityConfigId;
    if (!firstEntityConfigId) return [];

    const entityConfig =
      entityManager.userDefinedEntityConfigRepository.getById(
        firstEntityConfigId
      );
    if (!entityConfig) return [];

    return entityManager.userDefinedEntityConfigPropertyConfigRepository.getByUserDefinedEntityConfigId(
      entityConfig.id
    );
  }
}
