import { autoinject, computedFrom } from 'aurelia-framework';

import {
  allProcessTaskGroupFilterModes,
  ProcessTaskGroupFilterMode
} from 'common/Enums/ProcessTaskGroupFilterMode';
import {
  allProcessTaskAppointmentCalendarWidgetModes,
  ProcessTaskAppointmentCalendarWidgetMode,
  processTaskAppointmentCalendarWidgetModesForFieldUse
} from 'common/Enums/ProcessTaskAppointmentCalendarWidgetMode';
import {
  allTagSortingModes,
  TagSortingMode
} from 'common/Enums/TagSortingMode';
import {
  allPersonListItemFirstColumnContentTypes,
  PersonListItemFirstColumnContent
} from 'common/Enums/PersonListItemFirstColumnContent';
import {
  allPreferredOpenMethodsInApps,
  PreferredOpenMethodInApps
} from 'common/Enums/PreferredOpenMethodInApps';
import { GalleryThingPictureFilterTagMatchMode } from 'common/Types/GalleryThingPictureFilter/GalleryThingPictureFilter';

import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../services/PermissionBindingService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { UserCompanySetting } from '../../classes/EntityManager/entities/UserCompanySetting/types';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { ValueWithLabel } from '../../types/ValueWithLabel';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { defaultEntityLimit as HomePageEntityLimits } from '../../homePageComponents/entity-widget-container/entity-widget-container';
import { configureHooks } from '../../hooks/configureHooks';
import { QuestionCatalogue } from '../../classes/EntityManager/entities/QuestionCatalogue/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { TJsonTextChangedEvent } from '../../inputComponents/json-zod-object-input/json-zod-object-input';
import {
  kukReminderSchema,
  KukReminderSettings
} from 'common/Types/Entities/UserCompanySetting/UserCompanySettingDto';
import { ProcessConfigurationContactPersonPurpose } from 'common/Types/Entities/ProcessConfiguration/ProcessConfigurationDto';
import { ReportType } from '../../classes/EntityManager/entities/ReportType/types';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ArrayUtils } from 'common/Utils/ArrayUtils';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditUserCompanySettingDialog {
  private userCanAdministerUsers: boolean = false;
  private userCompanySetting: UserCompanySetting | null = null;
  private onDialogClosed: OnDialogClosed | null = null;
  private dialog: RecordItDialog | null = null;
  private permissionBindingHandle: PermissionBindingHandle;

  protected HomePageEntityLimits = HomePageEntityLimits;
  protected kukReminderSchema = kukReminderSchema;

  protected kukReminderSchemaDemoObject: KukReminderSettings = [
    {
      text: 'Always displayed',
      propertyName: null,
      propertyValue: null
    },
    {
      text: 'Displayed if property "type" === 1',
      propertyName: 'type',
      propertyValue: '1'
    }
  ];

  public static async open(
    options: EditUserCompanySettingDialogOpenOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionBindingService: PermissionBindingService
  ) {
    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      permissionProperties: {
        canAdministerUsers: 'userCanAdministerUsers'
      }
    });
  }

  public open(options: EditUserCompanySettingDialogOpenOptions): void {
    this.userCompanySetting = options.userCompanySetting;
    this.onDialogClosed = options.onDialogClosed;
    this.permissionBindingHandle.subscribe();

    if (this.dialog) {
      this.dialog.open();
    }
  }

  private handleDialogClosed(): void {
    const userCompanySetting = this.userCompanySetting;
    const onClosed = this.onDialogClosed;

    this.permissionBindingHandle.unsubscribe();
    this.userCompanySetting = null;
    this.onDialogClosed = null;

    if (userCompanySetting) {
      onClosed && onClosed(userCompanySetting);
    }
  }

  private handleUserCompanySettingChanged(): void {
    if (this.userCompanySetting) {
      this.entityManager.userCompanySettingRepository.update(
        this.userCompanySetting
      );
    }
  }

  protected handleUserCompanyKukRemindersSettingChanged(
    event: TJsonTextChangedEvent<KukReminderSettings>
  ): void {
    if (this.userCompanySetting) {
      this.userCompanySetting.kuk.reminders = event.detail.parsedObject;
    }
    this.handleUserCompanySettingChanged();
  }

  /**
   * returns all processTaskGroupFilterModes mapped to an object easily consumable by a custom-select.
   */
  @computedFrom()
  protected get allProcessTaskGroupFilterModes(): Array<
    ValueWithLabel<ProcessTaskGroupFilterMode>
  > {
    return allProcessTaskGroupFilterModes().map((m) => ({
      value: m,
      label: `modelsDetail.UserCompanySettingModel.operations.processTaskGroupFilterModes.${m}`
    }));
  }

  @computedFrom()
  protected get allTagSortingModes(): Array<ValueWithLabel<TagSortingMode>> {
    return allTagSortingModes().map((m) => ({
      value: m,
      label: `modelsDetail.UserCompanySettingModel.general.tagSortingModes.${m}`
    }));
  }

  @computedFrom()
  protected get allPersonListItemFirstColumnContentOptions(): Array<
    ValueWithLabel<PersonListItemFirstColumnContent>
  > {
    return allPersonListItemFirstColumnContentTypes().map((m) => ({
      value: m,
      label: `modelsDetail.UserCompanySettingModel.general.personOverviewFirstColumnContentTypes.${m}`
    }));
  }

  @computedFrom()
  protected get allTagMatchModes(): Array<
    ValueWithLabel<GalleryThingPictureFilterTagMatchMode>
  > {
    return Object.values(GalleryThingPictureFilterTagMatchMode).map((m) => ({
      value: m,
      label: `general.enumLabels.GalleryThingPictureFilterTagMatchMode.tagsFilterMode.${m}`
    }));
  }

  @computedFrom()
  protected get allPreferredOpenMethodsInApps(): Array<
    ValueWithLabel<PreferredOpenMethodInApps>
  > {
    return allPreferredOpenMethodsInApps().map((m) => ({
      value: m,
      label: `modelsDetail.UserCompanySettingModel.general.preferredOpenMethodsInApps.${m}`
    }));
  }

  protected getCalendarHourOptions(
    fromHour: number,
    toHour: number
  ): Array<ValueWithLabel<number>> {
    const hours = Array.from(
      Array(toHour + 1 - fromHour),
      (_, i) => i + fromHour
    );
    return hours.map((h) => ({
      value: h,
      label: `${h.toString().padStart(2, '0')}:00`
    }));
  }

  /**
   * returns all processTaskAppointmentCalendarWidgetModes mapped to an object easily consumable by a custom-select.
   */
  @computedFrom(
    'userCompanySetting.operations.processTaskAppointmentCalendarWidgetYearModeEnabled'
  )
  protected get allProcessTaskAppointmentCalendarWidgetModes(): Array<
    ValueWithLabel<ProcessTaskAppointmentCalendarWidgetMode>
  > {
    const isYearEnabled =
      this.userCompanySetting?.operations
        ?.processTaskAppointmentCalendarWidgetYearModeEnabled ?? false;
    const allModes = allProcessTaskAppointmentCalendarWidgetModes();
    // Filter out the year view if it's disabled in the settings
    const availableModes = isYearEnabled
      ? allModes
      : allModes.filter(
          (m) => m !== ProcessTaskAppointmentCalendarWidgetMode.YEAR
        );

    return availableModes.map((m) => ({
      value: m,
      label: `modelsDetail.UserCompanySettingModel.operations.processTaskAppointmentCalendarWidgetModes.${m}`
    }));
  }

  @computedFrom()
  protected get allProcessTaskAppointmentCalendarWidgetModesForFieldUsers(): Array<
    ValueWithLabel<ProcessTaskAppointmentCalendarWidgetMode>
  > {
    return processTaskAppointmentCalendarWidgetModesForFieldUse().map((m) => ({
      value: m,
      label: `modelsDetail.UserCompanySettingModel.operations.processTaskAppointmentCalendarWidgetModes.${m}`
    }));
  }

  @computed(model(EntityName.QuestionCatalogue))
  protected get availableQuestionCatalogues(): Array<QuestionCatalogue> {
    return this.entityManager.questionCatalogueRepository.getAll();
  }

  @computed(model(EntityName.ReportType))
  protected get availableReportTypes(): Array<ReportType> {
    return this.entityManager.reportTypeRepository.getAll();
  }

  protected handleReportTemplateIdAddButtonClicked(): void {
    if (this.userCompanySetting) {
      this.userCompanySetting.general.automaticProjectCreationConfigs!.push({
        reportTemplateId: ''
      });
    }
    this.handleUserCompanySettingChanged();
  }

  protected handleReportTemplateIdItemChanged(
    value: string,
    index: number
  ): void {
    assertNotNullOrUndefined(
      this.userCompanySetting?.general.automaticProjectCreationConfigs,
      'cannot handleReportTemplateIdItemChanged without automaticProjectCreationConfigs'
    );

    const reportTemplateIds =
      this.userCompanySetting.general.automaticProjectCreationConfigs;
    if (reportTemplateIds[index]) {
      reportTemplateIds[index]!.reportTemplateId = value;
    }
    this.handleUserCompanySettingChanged();
  }

  protected handleReportTemplateIdDeleteButtonClicked(index: number): void {
    if (this.userCompanySetting?.general.automaticProjectCreationConfigs) {
      this.userCompanySetting.general.automaticProjectCreationConfigs.splice(
        index,
        1
      );
      this.handleUserCompanySettingChanged();
    }
  }
}

export type EditUserCompanySettingDialogOpenOptions = {
  userCompanySetting: UserCompanySetting;
  onDialogClosed: OnDialogClosed | null;
};

export type OnDialogClosed = (userCompanySettings: UserCompanySetting) => void;
