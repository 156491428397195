import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { DefectChangeLogEntryAction } from 'common/Types/Entities/DefectChangeLogEntry/DefectChangeLogEntryDto';

import { PictureSketchingService } from '../../services/PictureSketchingService/PictureSketchingService';
import { Dialogs } from '../../classes/Dialogs';
import { RecordItFullScreenDialog } from '../record-it-full-screen-dialog/record-it-full-screen-dialog';
import { SelectCategoryTagsDialog } from '../select-category-tags-dialog/select-category-tags-dialog';
import { CustomCheckboxCheckedChangedEvent } from '../../inputComponents/custom-checkbox/custom-checkbox';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { DefectLoggingService } from '../../classes/EntityManager/entities/Defect/DefectLoggingService';
import { SelectThingPictureDialog } from '../select-thing-picture-dialog/select-thing-picture-dialog';
import { SelectCoordinatesOnPositionedPictureOverlay } from '../select-coordinates-on-positioned-picture-overlay/select-coordinates-on-positioned-picture-overlay';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { configureHooks } from '../../hooks/configureHooks';
import { computed } from '../../hooks/computed';
import { model, expression } from '../../hooks/dependencies';
import { watch } from '../../hooks/watch';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SocketService } from '../../services/SocketService';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

/**
 * Dialog for editing & tagging pictures of a defect.
 */
@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditDefectPicturesDialog {
  protected dialog: RecordItFullScreenDialog | null = null;

  private thingId: string | null = null;

  /**
   * The ID of the defect which pictures are currently currently being edited.
   */
  protected defectId: string | null = null;

  protected selectedPicture: Picture | null = null;

  /**
   * If the user is currently resizing the picture.
   */
  protected isInResizingMode = false;

  private readonly element: HTMLElement;

  protected isOnline = false;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly permissionHandle: EntityNameToPermissionsHandle[EntityName.Picture];

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly defectLoggingService: DefectLoggingService,
    private readonly pictureSketchingService: PictureSketchingService,
    private readonly i18n: I18N,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly socketService: SocketService,
    permissionsService: PermissionsService
  ) {
    this.element = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
    this.permissionHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Picture,
        context: this,
        expression: 'selectedPicture'
      });
  }

  public static async open(options: OpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  // ////////// METHODS //////////

  private open(options: OpenOptions): void {
    assertNotNullOrUndefined(
      this.dialog,
      'cannot open a dialog without a RecordItFullscreenDialog ref'
    );
    this.thingId = options.thingId;
    this.defectId = options.defectId;
    this.selectedPicture = options.selectedPicture;

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );

    this.dialog.open();
  }

  private close(): void {
    assertNotNullOrUndefined(
      this.dialog,
      'cannot open a dialog without a RecordItFullscreenDialog ref'
    );
    this.dialog.close();
  }

  private toggleResizingMode(): void {
    this.isInResizingMode = !this.isInResizingMode;
  }

  private setMainPicture(picture: Picture): void {
    assertNotNullOrUndefined(
      this.defectPictures,
      'cannot setMainPicture without defectPictures'
    );

    // Update defect pictures
    for (const p of this.defectPictures) {
      const isSelected = p.id === picture.id;
      if (p.selected !== isSelected) {
        p.selected = isSelected;
        this.entityManager.pictureRepository.update(p);
      }
    }
  }

  // ////////// WATCHERS //////////

  @watch(expression('selectedPicture'), expression('defectPictures'))
  protected alwaysShowSelectedPicture(): void {
    // If no picture is explicitly selected, show the first one
    if (
      this.selectedPicture === null &&
      this.defectPictures !== null &&
      this.defectPictures.length > 0
    ) {
      this.selectedPicture = this.defectPictures[0]!;
    }
  }

  // ////////// GETTERS //////////

  @computed(expression('defectId'), model(EntityName.Defect))
  protected get defect(): Defect | null {
    if (!this.defectId) {
      return null;
    }

    return this.entityManager.defectRepository.getById(this.defectId);
  }

  @computed(expression('defect'), model(EntityName.Picture))
  protected get defectPictures(): Array<Picture> | null {
    if (!this.defect) {
      return null;
    }

    return this.entityManager.pictureRepository.getByDefectId(this.defect.id);
  }

  // ////////// HANDLERS //////////

  protected handleDialogClosed(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected handlePictureEditorStopClicked(): void {
    this.toggleResizingMode();
  }

  protected handlePictureEditorEditingFinished(): void {
    assertNotNullOrUndefined(
      this.defect,
      'cannot handlePictureEditorEditingFinished without a defect'
    );
    void this.defectLoggingService.log(
      this.defect,
      DefectChangeLogEntryAction.PICTURE_EDITED
    );
  }

  protected handleSidebarSketchPictureClick(): void {
    if (this.selectedPicture) {
      this.pictureSketchingService.sketchPicture(this.selectedPicture, {
        onDone: () => {
          assertNotNullOrUndefined(
            this.defect,
            'cannot handleSidebarSketchPictureClick without a defect'
          );
          void this.defectLoggingService.log(
            this.defect,
            DefectChangeLogEntryAction.PICTURE_EDITED
          );
        }
      });
    }
  }

  protected handleSidebarResizePictureClick(): void {
    this.toggleResizingMode();
  }

  protected async handleSidebarMarkPictureClick(): Promise<void> {
    assertNotNullOrUndefined(
      this.thingId,
      'cannot handleSidebarMarkPictureClick without thingId'
    );

    if (!this.selectedPicture) return;

    const picture = this.selectedPicture;
    const thingId = this.thingId;

    const selectedThingPicture = await new Promise<Picture | null>((res) => {
      void SelectThingPictureDialog.open({
        thingId: thingId,
        onDialogClosed: (p) => res(p)
      });
    });
    if (!selectedThingPicture) return;

    void SelectCoordinatesOnPositionedPictureOverlay.open({
      oldPosition: picture.coords,
      picture: selectedThingPicture,
      pictureNotPositionedText: this.i18n.tr(
        'galleryThing.thingPictureIsNotPositioned'
      ),
      onCoordinateSelected: ({ coords, coordsFromPositionedPictureInfo }) => {
        if (!this.selectedPicture) return;
        this.selectedPicture.coords = coords;
        this.selectedPicture.coordsFromPositionedPictureInfo =
          coordsFromPositionedPictureInfo;
        this.handleDefectPictureChanged();
      }
    });
  }

  protected async handleSidebarDeletePictureClick(): Promise<void> {
    assertNotNullOrUndefined(
      this.defect,
      'cannot handleSidebarDeletePictureClick without a defect'
    );
    if (!this.selectedPicture) return;

    await Dialogs.deleteEntityDialog(this.selectedPicture);
    this.entityManager.pictureRepository.delete(this.selectedPicture);
    this.selectedPicture = this.defectPictures?.[0] ?? null;
    void this.defectLoggingService.log(
      this.defect,
      DefectChangeLogEntryAction.PICTURE_DELETED
    );
  }

  protected handleDefectPictureChanged(): void {
    assertNotNullOrUndefined(
      this.selectedPicture,
      'Cannot update defect if it is undefined'
    );
    this.entityManager.pictureRepository.update(this.selectedPicture);
  }

  protected handleTagButtonClicked(): void {
    assertNotNullOrUndefined(
      this.defect,
      'cannot handleTagButtonClicked without a defect'
    );
    if (this.selectedPicture !== null) {
      void SelectCategoryTagsDialog.open({
        type: 'thing',
        picture: this.selectedPicture,
        thingId: this.defect.ownerThingId,
        ownerUserGroupId: this.defect.ownerUserGroupId
      });
    }
  }

  protected handleMainPictureCheckedChanged(
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    assertNotNullOrUndefined(
      this.selectedPicture,
      'cannot handleMainPictureCheckedChanged without a selectedPicture'
    );
    assertNotNullOrUndefined(
      this.defectPictures,
      'cannot handleMainPictureCheckedChanged without defectPictures'
    );

    if (event.detail.checked) {
      // If the checkbox is checked for the selected picture, set it as the main picture
      this.setMainPicture(this.selectedPicture);
    } else {
      // If the checkbox is unchecked, remove the selected picture as the main one by setting
      // the first unchecked one as the main picture
      if (this.selectedPicture !== this.defectPictures[0]) {
        this.setMainPicture(this.defectPictures[0]!);
      } else if (this.defectPictures.length > 1) {
        this.setMainPicture(this.defectPictures[1]!);
      }
    }
  }
}

type OpenOptions = {
  thingId: string;
  defectId: string;
  selectedPicture: Picture | null;
};
