import { autoinject } from 'aurelia-framework';

import { CoordsFromPositionedPictureInfo } from 'common/Types/Entities/Picture/PictureDto';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';

import { PictureHelper } from './PictureHelper';
import { Dialogs } from '../Dialogs';
import { DataUrlReader } from '../Reader/DataUrlReader/DataUrlReader';
import {
  Picture,
  PictureCreationEntity,
  PictureEntityIdField,
  PictureSubEntityField
} from '../EntityManager/entities/Picture/types';
import { AppEntityManager } from '../EntityManager/entities/AppEntityManager';
import { SavePictureFileDataUrlService } from '../EntityManager/entities/PictureFile/SavePictureFileDataUrlService';
import { SocketService } from '../../services/SocketService';
import { CoordinateHelper } from '../CoordinateHelper';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ExifDataHelper } from './ExifDataHelper';
import { CurrentUserService } from '../EntityManager/entities/User/CurrentUserService';
import { ActiveUserCompanySettingService } from '../EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { GalleryThingPictureCreatorUtils } from '../GalleryThing/GalleryThingPictureCreatorUtils';

/**
 * Handles creating a picture (and syncing it to the server) from multiple input sources, including files, data urls, and more.
 *
 * All createXY function create a new Picture for the given entity configuration.
 */
@autoinject()
export class PictureCreatorService {
  constructor(
    private entityManager: AppEntityManager,
    private savePictureFileDataUrlService: SavePictureFileDataUrlService,
    private socketService: SocketService,
    private readonly currentUserService: CurrentUserService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService
  ) {}

  public withEntityInfos(
    getEntityInfos: GetEntityInfos
  ): PictureCreatorServiceWithEntityData {
    return new PictureCreatorServiceWithEntityData(
      this.entityManager,
      this.savePictureFileDataUrlService,
      this.socketService,
      this.currentUserService,
      getEntityInfos,
      this.activeUserCompanySettingService
    );
  }
}

export class PictureCreatorServiceWithEntityData {
  constructor(
    private entityManager: AppEntityManager,
    private savePictureFileDataUrlService: SavePictureFileDataUrlService,
    private socketService: SocketService,
    private readonly currentUserService: CurrentUserService,
    private getEntityInfos: GetEntityInfos,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService
  ) {}

  public async createPictureFromFile(
    file: File,
    options: AdditionalPictureCreationOptions = {},
    showDialogs?: boolean
  ): Promise<Picture | null> {
    if (PictureHelper.isPictureFileType(file)) {
      return this.uploadPictureFile(file, options, showDialogs);
    } else if (file.type === 'application/pdf') {
      this.uploadPdfFile(file);
      return null;
    } else {
      Dialogs.warningDialog('Dateityp wird nicht unterstützt');
    }

    return null;
  }

  public async createPicturesFromFiles(
    files: Array<File>
  ): Promise<Array<Picture>> {
    const createdPictures: Array<Picture> = [];
    for (const file of files) {
      const createdPicture = await this.createPictureFromFile(file);
      if (createdPicture) {
        createdPictures.push(createdPicture);
      }
    }
    return createdPictures;
  }

  public createWhitePicture(
    options: AdditionalPictureCreationOptions = {}
  ): Picture {
    const picture = this.createPicture({
      ...options,
      coords: options.coords ?? null
    });

    this.createWhiteOriginalPictureFile(picture);

    return picture;
  }

  public createPictureFromDataUrl(dataUrl: string): Picture {
    const picture = this.createPicture({ coords: null });

    this.savePictureFileDataUrlService.saveOriginalPictureDataUrl(
      picture,
      dataUrl
    );

    return picture;
  }

  private async uploadPictureFile(
    file: File,
    options: AdditionalPictureCreationOptions = {},
    showDialogs?: boolean
  ): Promise<Picture> {
    if (!options.coords) {
      options.coords = await ExifDataHelper.getCoordinatesFromExifData(file);
    }
    options.takenAt = await ExifDataHelper.getCreationDateFromExifData(file);

    const picture = this.createPicture(options);

    const reader = new DataUrlReader();

    void reader.readFile(file).then((result) => {
      this.savePictureFileDataUrlService.saveOriginalPictureDataUrl(
        picture,
        result,
        showDialogs
      );
    });

    return picture;
  }

  private uploadPdfFile(file: File): void {
    const reader = new DataUrlReader();

    void reader.readFile(file).then((result) => {
      const entityInfos = this.getEntityInfos();
      assertNotNullOrUndefined(
        entityInfos.subEntityField,
        'pdf uploads only work with a subEntityField'
      );

      this.socketService.uploadPDFFile(
        {
          dataUrl: result,
          mainEntityIdField: entityInfos.mainEntityIdField,
          mainEntityId: entityInfos.mainEntityId,
          subEntityField: entityInfos.subEntityField,
          subEntityValue: entityInfos.subEntityValue
        },
        (response: any) => {
          if (response.success) {
            Dialogs.timedSuccessDialog('Upload erfolgreich!');
          } else {
            void Dialogs.errorDialog('Fehler', response.message);
          }
        }
      );
    });
  }

  /**
   * Creates a picture and links it to the entity with the current entity info.
   *
   * If `options.coords` is undefined, coordinates will be set to the current client location.
   */
  public createPicture(
    options: AdditionalPictureCreationOptions = {}
  ): Picture {
    const entityInfos = this.getEntityInfos();

    const picture = this.entityManager.pictureRepository.createPictureForEntity(
      {
        mainEntityId: entityInfos.mainEntityId,
        mainEntityIdField: entityInfos.mainEntityIdField,
        subEntityField: entityInfos.subEntityField,
        subEntityValue: entityInfos.subEntityValue,
        ownerProjectId: entityInfos.ownerProjectId,
        ownerUserGroupId: entityInfos.ownerUserGroupId
      },
      {
        takenByUserId: this.currentUserService.getCurrentUser()?.id ?? null,
        ...options,
        takenAt:
          options.takenAt !== undefined
            ? options.takenAt
            : new Date().toISOString(),
        selected: this.getDefaultSelectedStatusOfPicture({ entityInfos })
      }
    );

    if (options.coords === undefined) {
      void CoordinateHelper.getClientCoordinatesWithDialog().then((coords) => {
        picture.coords = {
          latitude: coords?.latitude ?? null,
          longitude: coords?.longitude ?? null
        };
        this.patchViaPicturesCoordsIfNecessary(picture);
        this.entityManager.pictureRepository.update(picture);
      });
    }

    return picture;
  }

  private createWhiteOriginalPictureFile(picture: Picture): void {
    const canvas = document.createElement('canvas');
    canvas.width = 1920;
    canvas.height = 1440;

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error("couldn't create a canvas context");
    }

    ctx.fillStyle = 'white';
    ctx.rect(0, 0, 1920, 1440);
    ctx.fill();

    this.savePictureFileDataUrlService.saveOriginalPictureDataUrl(
      picture,
      canvas.toDataURL('image/jpg')
    );
  }

  private patchViaPicturesCoordsIfNecessary(picture: Picture): void {
    if (!!picture.ownerDefectId) {
      const defect = this.entityManager.defectRepository.getById(
        picture.ownerDefectId
      );
      assertNotNullOrUndefined(
        defect,
        `Trying to patch defect picture ${picture.id} but cannot find its defect ${picture.ownerDefectId}`
      );
      picture.coordsFromPositionedPictureInfo =
        this.getCoordsFromPositionedPictureInfoForViaPictures(
          picture,
          defect.ownerThingId
        );
    } else if (!!picture.ownerProjectId) {
      const project = this.entityManager.projectRepository.getById(
        picture.ownerProjectId
      );
      if (project?.projectType === ProjectType.GALLERY) {
        picture.coordsFromPositionedPictureInfo =
          this.getCoordsFromPositionedPictureInfoForViaPictures(
            picture,
            project.thing
          );
      }
    }
  }

  private getCoordsFromPositionedPictureInfoForViaPictures(
    picture: Picture,
    thingId: string
  ): CoordsFromPositionedPictureInfo | null {
    if (
      this.activeUserCompanySettingService.getSettingProperty(
        'via.automaticallyMarkPicturesOnThingPicture'
      ) !== true ||
      !!picture.coordsFromPositionedPictureInfo ||
      !picture.coords?.latitude ||
      !picture.coords?.longitude
    )
      return picture.coordsFromPositionedPictureInfo;

    return GalleryThingPictureCreatorUtils.getCoordsFromPositionedPictureInfo(
      this.entityManager,
      picture.coords,
      thingId
    );
  }

  private getDefaultSelectedStatusOfPicture({
    entityInfos
  }: {
    entityInfos: PictureCreatorEntityInfos;
  }): boolean {
    const pictures = this.entityManager.pictureRepository.getByEntityId(
      entityInfos.mainEntityIdField,
      entityInfos.mainEntityId,
      entityInfos.subEntityField,
      entityInfos.subEntityValue
    );

    return pictures.length === 0;
  }
}

/**
 * Since some functionality is async (e.g. uploadPictureFile, uploadPdfFile) we need to get the newest version of the entity infos.
 *
 * This is especially relevant when an entity gets synced in between, we would still hold a local id here and this will prevent the picture from being synced.
 *
 * You should always create a new object here which is created directly from the entities to prevent stale ids
 */
export type GetEntityInfos = () => PictureCreatorEntityInfos;

export type PictureCreatorEntityInfos = {
  mainEntityIdField: PictureEntityIdField;
  mainEntityId: string;
  subEntityField?: PictureSubEntityField | null;
  subEntityValue?: string | null;
  ownerUserGroupId: string;
  ownerProjectId?: string | null;
};

export type AdditionalPictureCreationOptions = Partial<PictureCreationEntity>;
