import { assertNotNullOrUndefined } from 'common/Asserts';
import { autoinject, Container } from 'aurelia-framework';
import { ValueComputer } from './computers/ValueComputer';
import { Disposable } from '../classes/Utils/DisposableContainer';
import { DisposableUtils } from '../classes/Utils/DisposableUtils';

/**
 * Solves the problem with shared expensive computations in components.
 * If an expensive computation was needed in multiple components, you would have to
 *  a) do the computation in a parent component and pass it down to it's children
 *  b) create a special Service which handles it.
 * Both approaches need a lot of extra work and add some extra complexity.
 *
 * With the ComputedValueService you can subscribe to a ValueComputer and automatically get new values.
 * Also the values are automatically shared/cached, so if 100s of components need the value at the same time, it will get computed only once.
 *
 */
@autoinject()
export class ComputedValueService {
  private container: Container;
  private computerInfos: Array<
    IValueComputerInfo<UnknownValueComputerConstructor>
  > = [];

  constructor(container: Container) {
    this.container = container;
  }

  public subscribeWithSubscriptionUpdating<
    T extends UnknownValueComputerConstructor
  >({
    valueComputerClass,
    createComputeData,
    createUpdaters,
    callback
  }: SubscribeWithSubscriptionUpdatingOptions<T>): Disposable {
    let lastDisposable: Disposable | null = null;
    const updateSubscription = (): void => {
      lastDisposable?.dispose();

      const computeData = createComputeData();
      if (computeData) {
        lastDisposable = this.subscribe({
          valueComputerClass,
          computeData,
          callback
        });
      }
    };

    createUpdaters(updateSubscription);

    // in case the subscription was already update via the createUpdaters
    if (!lastDisposable) {
      updateSubscription();
    }

    return {
      dispose: () => {
        lastDisposable?.dispose();
      }
    };
  }

  public subscribeOnce<T extends UnknownValueComputerConstructor>(
    options: Omit<SubscribeOptions<T>, 'skipInitialCall'>
  ): Disposable {
    const disposable = this.subscribe({
      ...options,
      callback: (data) => {
        disposable.dispose();
        options.callback(data);
      }
    });

    return disposable;
  }

  public subscribe<T extends UnknownValueComputerConstructor>(
    options: SubscribeOptions<T>
  ): Disposable {
    const callback = DisposableUtils.disposableCallback(options.callback);

    const info = this.getOrCreateComputerInfo(options.valueComputerClass);
    const computeDataInfo = this.getOrCreateComputeDataInfo(
      info,
      options.computeData
    );
    computeDataInfo.subscribers.push(callback);

    if (!options.skipInitialCall) {
      void Promise.resolve().then(() =>
        callback(computeDataInfo.computeResult)
      ); // always call the callback async
    }

    return {
      dispose: () => {
        callback.dispose();
        this.unsubscribe(options);
      }
    };
  }

  public recompute<T extends UnknownValueComputerConstructor>(
    valueComputerClass: T
  ): void {
    const valueComputerInfo = this.getComputerInfo(valueComputerClass);
    assertNotNullOrUndefined(
      valueComputerInfo,
      `can't recompute "${valueComputerClass.name}" because no info was found`
    );

    for (const computeDataInfo of valueComputerInfo.computeDataInfos) {
      const lastResult = computeDataInfo.computeResult;
      const newResult = valueComputerInfo.instance.compute(
        computeDataInfo.computeData,
        { lastResult }
      );
      computeDataInfo.computeResult = newResult;
      this.callSubscribers(computeDataInfo);
    }
  }

  /**
   * Returns the value of the computer if there is already one or null.
   */
  public getCurrentValue<T extends UnknownValueComputerConstructor>(
    valueComputerClass: T,
    computeData: ValueComputerComputeDataExtractor<T>
  ): ValueComputerComputeResultExtractor<T> | null {
    const valueComputerInfo = this.getComputerInfo(valueComputerClass);
    const computeDataInfo = valueComputerInfo
      ? this.getComputeDataInfo(valueComputerInfo, computeData)
      : null;
    if (computeDataInfo) {
      return computeDataInfo.computeResult;
    }

    return null;
  }

  /**
   * Subscribes to the computer, retrieves the first value and the disposes the subscriptions.
   */
  public getComputedValue<T extends UnknownValueComputerConstructor>({
    valueComputerClass,
    computeData
  }: {
    valueComputerClass: T;
    computeData: ValueComputerComputeDataExtractor<T>;
  }): Promise<ValueComputerComputeResultExtractor<T>> {
    return new Promise((resolve) => {
      this.subscribeOnce({
        valueComputerClass,
        computeData,
        callback: (value) => {
          resolve(value);
        }
      });
    });
  }

  private getOrCreateComputerInfo<T extends UnknownValueComputerConstructor>(
    valueComputerClass: T
  ): IValueComputerInfo<T> {
    const foundInfo = this.getComputerInfo(valueComputerClass);
    if (foundInfo) {
      return foundInfo;
    }

    const info: IValueComputerInfo<T> = {
      valueComputerClass: valueComputerClass,
      instance: this.container.get(
        valueComputerClass
      ) as UnknownValueComputerConstructorInstance<T>,
      computeDataInfos: []
    };

    this.computerInfos.push(info);

    info.instance.initializeEventListeners(() => {
      this.recompute(info.valueComputerClass);
    });

    return info;
  }

  private getOrCreateComputeDataInfo<T extends UnknownValueComputerConstructor>(
    valueComputerInfo: IValueComputerInfo<T>,
    computeData: ValueComputerComputeDataExtractor<T>
  ): IComputeDataInfo<T> {
    const foundInfo = valueComputerInfo.computeDataInfos.find((info) => {
      return valueComputerInfo.instance.computeDataAreEqual(
        info.computeData,
        computeData
      );
    });

    if (foundInfo) {
      return foundInfo;
    }

    const info: IComputeDataInfo<T> = {
      computeData,
      computeResult: valueComputerInfo.instance.compute(computeData, {
        lastResult: undefined
      }),
      subscribers: []
    };

    valueComputerInfo.computeDataInfos.push(info);
    return info;
  }

  private unsubscribe<T extends UnknownValueComputerConstructor>(
    options: SubscribeOptions<T>
  ): void {
    const valueComputerInfo = this.getComputerInfo(options.valueComputerClass);
    if (!valueComputerInfo) {
      return;
    }

    const computeDataInfo = this.getComputeDataInfo(
      valueComputerInfo,
      options.computeData
    );
    if (!computeDataInfo) {
      return;
    }

    this.removeSubscriber(computeDataInfo, options.callback);
    this.removeEmptyComputeDataInfos(valueComputerInfo);
    this.removeEmptyComputerInfos();
  }

  private getComputerInfo<T extends UnknownValueComputerConstructor>(
    valueComputerClass: T
  ): IValueComputerInfo<T> | null {
    const info = this.computerInfos.find(
      (i) => i.valueComputerClass === valueComputerClass
    ) as IValueComputerInfo<T> | undefined;
    return info ?? null;
  }

  private getComputeDataInfo<T extends UnknownValueComputerConstructor>(
    valueComputerInfo: IValueComputerInfo<T>,
    computeData: ValueComputerComputeDataExtractor<T>
  ): IComputeDataInfo<T> | null {
    const foundInfo = valueComputerInfo.computeDataInfos.find((info) => {
      return valueComputerInfo.instance.computeDataAreEqual(
        info.computeData,
        computeData
      );
    });

    return foundInfo ?? null;
  }

  private callSubscribers<T extends UnknownValueComputerConstructor>(
    computeDataInfo: IComputeDataInfo<T>
  ): void {
    for (const subscriber of computeDataInfo.subscribers) {
      try {
        subscriber(computeDataInfo.computeResult);
      } catch (e) {
        console.error('error in a valueComputerSubscriber', e);
      }
    }
  }

  private removeSubscriber<T extends UnknownValueComputerConstructor>(
    computeDataInfo: IComputeDataInfo<T>,
    callback: ValueComputerCallbackExtractor<T>
  ): void {
    const index = computeDataInfo.subscribers.indexOf(callback);
    if (index >= 0) {
      computeDataInfo.subscribers.splice(index, 1);
    }
  }

  private removeEmptyComputeDataInfos<
    T extends UnknownValueComputerConstructor
  >(valueComputerInfo: IValueComputerInfo<T>): void {
    const computeDataInfosToRemove = valueComputerInfo.computeDataInfos.filter(
      (i) => i.subscribers.length === 0
    );

    for (const computeDataInfo of computeDataInfosToRemove) {
      const index = valueComputerInfo.computeDataInfos.indexOf(computeDataInfo);
      if (index >= 0) {
        valueComputerInfo.computeDataInfos.splice(index, 1);
      }
    }
  }

  private removeEmptyComputerInfos(): void {
    const computerInfosToRemove = this.computerInfos.filter(
      (info) => info.computeDataInfos.length === 0
    );

    for (const computerInfo of computerInfosToRemove) {
      const index = this.computerInfos.indexOf(computerInfo);
      if (index >= 0) {
        this.computerInfos.splice(index, 1);
      }

      computerInfo.instance.removeEventListeners();
    }
  }
}

interface IValueComputerInfo<T extends UnknownValueComputerConstructor> {
  valueComputerClass: T;
  instance: UnknownValueComputerConstructorInstance<T>;
  computeDataInfos: Array<IComputeDataInfo<T>>;
}

interface IComputeDataInfo<T extends UnknownValueComputerConstructor> {
  computeData: ValueComputerComputeDataExtractor<T>;
  computeResult: ValueComputerComputeResultExtractor<T>;
  subscribers: Array<ValueComputerCallbackExtractor<T>>;
}

export type UnknownValueComputerConstructor = new (
  ...args: any
) => ValueComputer<unknown, unknown>;
export type ValueComputerComputeDataExtractor<
  T extends UnknownValueComputerConstructor
> = InstanceType<T> extends ValueComputer<infer U, any>
  ? unknown extends U
    ? Record<string, never>
    : U
  : never;
export type ValueComputerComputeResultExtractor<
  T extends UnknownValueComputerConstructor
> = InstanceType<T> extends ValueComputer<any, infer U> ? U : never;
export type ValueComputerCallbackExtractor<
  T extends UnknownValueComputerConstructor
> = (result: ValueComputerComputeResultExtractor<T>) => void;
export type UnknownValueComputerConstructorInstance<
  T extends UnknownValueComputerConstructor
> = ValueComputer<
  ValueComputerComputeDataExtractor<T>,
  ValueComputerComputeResultExtractor<T>
>;

export type SubscribeWithSubscriptionUpdatingOptions<
  T extends UnknownValueComputerConstructor
> = {
  valueComputerClass: T;
  createComputeData: () => ValueComputerComputeDataExtractor<T> | null;
  createUpdaters: (updateSubscription: () => void) => void;
  callback: ValueComputerCallbackExtractor<T>;
  /**
   * gets called when no compute data got created
   * useful for resetting the state e.g.
   */
  onNoComputeData?: () => void;
};

export type SubscribeOptions<T extends UnknownValueComputerConstructor> = {
  valueComputerClass: T;
  computeData: ValueComputerComputeDataExtractor<T>;
  callback: ValueComputerCallbackExtractor<T>;
  /**
   * Normally the callback gets called after subscribing.
   * If this is set to true, the callback only gets called for future changes
   */
  skipInitialCall?: boolean;
};
