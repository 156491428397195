import _ from 'lodash';

import { autoinject, bindable } from 'aurelia-framework';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { SocketService } from '../../services/SocketService';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  MoreButton,
  MoreButtonChoice
} from '../../aureliaComponents/more-button/more-button';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';

/**
 * a component which provides the user with possibilites/choices to upload pictures
 * actually uploading the pictures is meant to be implemented externally, since this component is meant to be as general as possible.
 *
 * @event {UploadChoiceSelectedEvent} upload-choice-selected
 *
 * @attribute data-hide-description
 * @attribute data-fill-absolute -> positions the component to fill the parent container and centers the icon in the middle
 */
@autoinject()
export class PictureUploadArea {
  /**
   * provide the camera choice (or not)
   */
  @bindable public cameraChoiceEnabled = true;

  /**
   * provide the upload choice (or not)
   */
  @bindable public uploadChoiceEnabled = true;

  /**
   * provide the sketch choice (or not)
   */
  @bindable public sketchChoiceEnabled = false;

  /**
   * provide the map choice (or not)
   */
  @bindable public mapChoiceEnabled = false;

  protected showDragOverFileInputElement = false;

  private moreButton: MoreButton | null = null;
  private fileInputElement: HTMLInputElement | null = null;
  private domElement: HTMLElement;

  private subscriptionManager: SubscriptionManager;

  protected isApp = false;
  protected isConnected = false;
  protected canCapturePicture = false;

  private urfmChoiceEnabled = false;

  protected moreButtonChoices: Array<MoreButtonChoice> = [];

  constructor(
    element: Element,
    private readonly socketService: SocketService,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly activeUserCompanySetting: ActiveUserCompanySettingService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isApp = DeviceInfoHelper.isApp();

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isConnected = isConnected;
        this.updateMoreButtonChoices();
      }),
      DeviceInfoHelper.registerBinding(
        'canCapturePicture',
        (canCapturePicture) => {
          this.canCapturePicture = canCapturePicture;
          this.updateMoreButtonChoices();
        }
      ),
      this.activeUserCompanySetting.bindSettingProperty(
        'ultraRapidFireWidget.useUltraRapidFireWidget',
        (useUltraRapidFireWidget) => {
          this.urfmChoiceEnabled = useUltraRapidFireWidget;
          this.updateMoreButtonChoices();
        }
      )
    );

    this.updateMoreButtonChoices();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected cameraChoiceEnabledChanged(): void {
    this.updateMoreButtonChoices();
  }

  protected uploadChoiceEnabledChanged(): void {
    this.updateMoreButtonChoices();
  }

  protected sketchChoiceEnabledChanged(): void {
    this.updateMoreButtonChoices();
  }

  protected mapChoiceEnabledChanged(): void {
    this.updateMoreButtonChoices();
  }

  private updateMoreButtonChoices(): void {
    const choices: Array<MoreButtonChoice> = [];

    if (this.cameraChoiceEnabled && this.canCapturePicture) {
      choices.push({
        labelTk: 'picture.pictureUploadArea.camera',
        name: 'camera',
        iconClass: 'record-it-icon fal fa-camera-alt'
      });
    }

    if (this.urfmChoiceEnabled && this.canCapturePicture) {
      choices.push({
        labelTk: 'picture.pictureUploadArea.urfm',
        name: 'urfm',
        iconClass: 'record-it-icon fal fa-camera-alt'
      });
    }

    if (this.uploadChoiceEnabled) {
      choices.push({
        labelTk: 'picture.pictureUploadArea.uploadPicture',
        name: 'upload-picture',
        iconClass: 'record-it-icon fal fa-cloud-upload',
        isFileInput: true,
        fileInputAccept: 'image/jpeg, application/pdf, image/png',
        fileInputMultiple: true
      });
    }

    if (this.sketchChoiceEnabled) {
      choices.push({
        labelTk: 'picture.pictureUploadArea.createSketch',
        name: 'create-sketch',
        iconClass: 'record-it-icon fal fa-edit'
      });
    }

    if (this.mapChoiceEnabled && this.isConnected) {
      choices.push({
        labelTk: 'picture.pictureUploadArea.mapPicture',
        name: 'map-picture',
        iconClass: 'record-it-icon fal fa-map'
      });
    }

    this.moreButtonChoices = choices;
  }

  protected handleUrfmClicked(): void {
    this.fireUploadEvent(Method.URFM);
  }

  protected handleCameraClicked(): void {
    this.fireUploadEvent(Method.CAMERA);
  }

  protected handleCreateSketchClicked(): void {
    this.fireUploadEvent(Method.SKETCH);
  }

  protected handleCreateMapPictureClicked(): void {
    this.fireUploadEvent(Method.MAP);
  }

  protected handleUploadPictureChanged(event: CustomEvent): void {
    const input = event.detail.target as HTMLInputElement;
    const files = _.map(input.files, (f) => f);

    if (files) {
      this.fireUploadEvent(Method.FILE, files);
    }

    input.value = '';
  }

  protected handleDragOverFileInputChanged(): void {
    this.showDragOverFileInputElement = false;

    if (this.fileInputElement) {
      this.handleFileInputChanged(this.fileInputElement);
    }
  }

  private handleFileInputChanged(input: HTMLInputElement): void {
    const files = _.map(input.files, (f) => f);

    if (files) {
      this.fireUploadEvent(Method.FILE, files);
    }

    input.value = '';
  }

  protected handleDragEnter(): void {
    this.showDragOverFileInputElement = true;
  }

  protected handleDragLeave(): void {
    this.showDragOverFileInputElement = false;
  }

  protected handleElementClick(event: MouseEvent): boolean {
    if (
      this.moreButton &&
      event.currentTarget &&
      !this.elementIsInsideMoreButton(event.currentTarget as HTMLElement)
    ) {
      this.moreButton.open();
    }

    return true;
  }

  private elementIsInsideMoreButton(element: HTMLElement): boolean {
    let currentElement = element;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (currentElement === this.domElement) {
        return false;
      }

      if (currentElement.tagName === 'MORE-BUTTON') {
        return true;
      }

      if (currentElement.parentNode) {
        currentElement = currentElement.parentNode as HTMLElement;
      } else {
        return false;
      }
    }
  }

  private fireUploadEvent(method: Method, files?: Array<File>): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'upload-choice-selected',
      detail: {
        method,
        files
      }
    });
  }
}

export type UploadChoiceSelectedEventDetail = {
  method: Method;
  files?: Array<File>;
};

/**
 * file is only set when the method is 'file'
 */
export type UploadChoiceSelectedEvent = NamedCustomEvent<
  'upload-choice-selected',
  UploadChoiceSelectedEventDetail
>;

/**
 * camera: the user wants to create a picture with a camera
 * file: the user selected a file
 * map: the user wants to create a picture from the map
 * sketch: the user wants to create a sketch picture
 */
enum Method {
  CAMERA = 'camera',
  FILE = 'file',
  MAP = 'map',
  SKETCH = 'sketch',
  URFM = 'urfm'
}
