import { OptionalProperties } from '../../utilities';
import { UserGroupSubEntity } from '../Base/UserGroupSubEntity';
import { DateType, IdType } from '../Base/types';
import { BaseEntity } from '../Base/BaseEntity';

type FullDefectChangeLogEntry<
  TId extends IdType,
  TDate extends DateType
> = BaseEntity<TDate> &
  UserGroupSubEntity<TId> & {
    userId: TId;
    logAction: DefectChangeLogEntryAction;
    logDataJson: string | null;
    created: TDate;

    ownerDefectId: TId;
    ownerThingId: TId;
  };

export type DefectChangeLogEntryDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullDefectChangeLogEntry<
    TId,
    TDate
  > = never,
  TPropertiesToRemove extends keyof FullDefectChangeLogEntry<TId, TDate> = never
> = Omit<
  OptionalProperties<FullDefectChangeLogEntry<TId, TDate>, TOptionalProperties>,
  TPropertiesToRemove
>;

export enum DefectChangeLogEntryAction {
  CHANGED_TITLE = 10,
  CHANGED_DESCRIPTION = 20,
  CHANGED_DUE_AT = 30,
  CHANGED_ASSIGNEE = 40,
  PICTURE_UPLOADED = 50,
  PICTURE_EDITED = 60,
  PICTURE_DELETED = 70
}
