import { EntityName } from './Types/Entities/Base/ClientEntityName';

export enum ProcessTaskLogEntryLogDataConverterType {
  /*
    converter to look up ids and convert them into a human readable format
    params:
      logDataPropertyName: string (e.g. 'thingId')
      entityName: string (e.g. 'ThingModel')
  */
  ID_LOOKUP = 'id lookup',

  /*
    converter which converts the before and after property and adds an beforeDisplayName and afterDisplayName field to the data
    logData has to extend the ProcessConfigurationActionStatusModifiedData
   */
  PROCESS_CONFIGURATION_ACTION_STATUS = 'process configuration action status',

  /** converter which converts a person id into the person's full name */
  PERSON_ID = 'person id',

  /**
   * looks up the given id in the checklistEntryIdPropertyName and provides a human readable format for it
   * params:
   *  checklistEntryIdPropertyName: string (e.g. 'processTaskChecklistEntryId')
   */
  PROCESS_TASK_CHECKLIST_ENTRY_ID = 'entry id',

  /**
   * converts a date object to more human-readable variants.
   * params:
   *   datePath: string (e.g. `openedAt`)
   */
  DATE = 'date',

  /**
   * converts two date objects to a human readable duration between the two
   * params:
   *   startDatePath: string (e.g. `openedAt`)
   *   endDatePath: string (e.g. `finishedAt`)
   */
  TIME_SPAN = 'time span',

  /**
   * converts the ProcessTaskGroupAssigneeChangedDataChange to a combined string
   * adds the changeDataPropertyPath with a `Formatted` suffix to the log object
   *
   * params:
   *   changeDataPropertyPath: string (e.g. 'after')
   */
  PROCESS_TASK_GROUP_ASSIGNEE_CHANGED_DATA_CHANGE_FORMATTER = 'process task group assignee changed data change stringifier',

  /**
   * Converts the EntityName with the id to a readable name.
   * If there is no special name, then the general entityName will be shown
   *
   * The displayName will be available at the root of the log object as `displayName`.
   * The displayName will also be available as `displayNameWithoutFallbackInQuotesWithSpaceBefore` for better log messages
   *
   * params:
   *   entityNamePath: string (e.g. 'entityName')
   *   entityIdPath: string (e.g. 'id')
   *   fallbackNamePath: string (e.g. 'displayNameAtLogTime')
   */
  DISPLAY_NAME_OF_ENTITY = 'display name of entity',

  /**
   * Looks for the displayNameAtLogTime in newer sub entity logs and overrides the displayNameAtLogTime with the newer value
   *
   * params:
   *   none
   */
  MOST_RECENT_SUB_ENTITY_DISPLAY_NAME_OF_ENTITY = 'most recent sub entity display name of entity'
}

export enum ProcessTaskLogEntryAction {
  CREATED = 10,
  DESCRIPTION_MODIFIED = 20,
  ASSIGNEE_CHANGED = 21,
  PROCESS_CONFIGURATION_STEP_ID_MODIFIED = 30,
  PROCESS_CONFIGURATION_ACTION_STATUS_MODIFIED = 40,
  APPOINTMENT_FINISHED = 50,
  APPOINTMENT_FINISHED_BY_PERSON = 51,
  APPOINTMENT_UNDO_FINISHED = 60,
  APPOINTMENT_UNDO_FINISHED_BY_PERSON = 61,
  PROCESS_TASK_PROPERTY_MODIFIED = 70,
  PROCESS_TASK_NAME_MODIFIED = 80,
  AUTO_APPOINTMENT_CREATED = 90,
  AUTO_APPOINTMENT_DELETED = 91,
  SUB_ENTITY_CREATED = 100,
  SUB_ENTITY_MODIFIED = 101,
  SUB_ENTITY_DELETED = 102,
  PHONE_LINK_CLICKED = 110,
  APPOINTMENT_OPENED_BY_PERSON = 120,
  CHECKLIST_ENTRY_CREATED = 130,
  CHECKLIST_ENTRY_TEXT_CHANGED = 131,
  CHECKLIST_ENTRY_DONE_CHANGED = 132,
  PROCESS_TASK_GROUP_AUTHORIZATION_CREATED = 140,
  PROCESS_TASK_GROUP_AUTHORIZATION_DELETED = 142,
  APPOINTMENT_FINISHED_AUTOMATIC_NEXT_STEP = 200
}

export type LogDataByProcessLogEntryAction = {
  [ProcessTaskLogEntryAction.CREATED]: {};
  [ProcessTaskLogEntryAction.DESCRIPTION_MODIFIED]: {};
  [ProcessTaskLogEntryAction.ASSIGNEE_CHANGED]: ProcessTaskGroupAssigneeChangedData;
  [ProcessTaskLogEntryAction.PROCESS_CONFIGURATION_STEP_ID_MODIFIED]: {
    processConfigurationStepId: string;
  };
  [ProcessTaskLogEntryAction.PROCESS_CONFIGURATION_ACTION_STATUS_MODIFIED]: ProcessConfigurationActionStatusModifiedData;
  [ProcessTaskLogEntryAction.APPOINTMENT_FINISHED]: AppointmentFinishedData;
  [ProcessTaskLogEntryAction.APPOINTMENT_FINISHED_BY_PERSON]: AppointmentFinishedData & {
    personId: string;
  };
  [ProcessTaskLogEntryAction.APPOINTMENT_UNDO_FINISHED]: AppointmentUndoFinishedData;
  [ProcessTaskLogEntryAction.APPOINTMENT_UNDO_FINISHED_BY_PERSON]: AppointmentUndoFinishedData & {
    personId: string;
  };
  [ProcessTaskLogEntryAction.PROCESS_TASK_PROPERTY_MODIFIED]: ProcessTaskPropertyModifiedData;
  [ProcessTaskLogEntryAction.PROCESS_TASK_NAME_MODIFIED]: ProcessTaskNameModifiedData;
  [ProcessTaskLogEntryAction.AUTO_APPOINTMENT_CREATED]: AutoAppointmentCreatedData;
  [ProcessTaskLogEntryAction.AUTO_APPOINTMENT_DELETED]: AutoAppointmentDeletedData;
  [ProcessTaskLogEntryAction.SUB_ENTITY_CREATED]: SubEntityCreatedData;
  [ProcessTaskLogEntryAction.SUB_ENTITY_MODIFIED]: SubEntityModifiedData;
  [ProcessTaskLogEntryAction.SUB_ENTITY_DELETED]: SubEntityDeletedData;
  [ProcessTaskLogEntryAction.PHONE_LINK_CLICKED]: PhoneLinkClickedData;
  [ProcessTaskLogEntryAction.APPOINTMENT_OPENED_BY_PERSON]: AppointmentOpenedByPersonData;
  [ProcessTaskLogEntryAction.CHECKLIST_ENTRY_CREATED]: ChecklistEntryCreatedData;
  [ProcessTaskLogEntryAction.CHECKLIST_ENTRY_TEXT_CHANGED]: ChecklistEntryTextChangedData;
  [ProcessTaskLogEntryAction.CHECKLIST_ENTRY_DONE_CHANGED]: ChecklistEntryDoneChangedData;
  [ProcessTaskLogEntryAction.PROCESS_TASK_GROUP_AUTHORIZATION_CREATED]: ProcessTaskGroupAuthorizationChangedData;
  [ProcessTaskLogEntryAction.PROCESS_TASK_GROUP_AUTHORIZATION_DELETED]: ProcessTaskGroupAuthorizationChangedData;
  [ProcessTaskLogEntryAction.APPOINTMENT_FINISHED_AUTOMATIC_NEXT_STEP]: AppointmentFinishedAutomaticNextStepData;
};

export type ProcessTaskLogEntryActionInfoLogDataConverterInfo = {
  converterType: ProcessTaskLogEntryLogDataConverterType;
  converterArgs: Record<string, string>;
};

export type ProcessTaskGroupAssigneeChangedData = {
  before: ProcessTaskGroupAssigneeChangedDataChange;
  after: ProcessTaskGroupAssigneeChangedDataChange;
};

export type ProcessTaskGroupAssigneeChangedDataChange = {
  assigneeUserId: string | null;
  assigneeHint: string | null;
  assignedProcessTaskName: string | null;
};

export type ProcessConfigurationActionStatusModifiedData = {
  before: ProcessConfigurationActionStatusModifiedChangeData;
  after: ProcessConfigurationActionStatusModifiedChangeData;
};

export type AppointmentFinishedData = {
  processTaskAppointmentId: string;
  openedAt: string | null;
};

export type AutoAppointmentCreatedData = {
  name: string;
  processTaskAppointmentId: string;
  processConfigurationStepAutoAppointmentId: string;
};

export type AutoAppointmentDeletedData = {
  name: string;
  processTaskAppointmentId: string;
  processConfigurationStepAutoAppointmentId: string;
};

export type SubEntityCreatedData = {
  entityId: string;
  entityName: EntityName;
  displayNameAtLogTime: string | null;
};

export type SubEntityDeletedData = {
  entityId: string;
  entityName: EntityName;
  displayNameAtLogTime: string | null;
};

export type SubEntityModifiedData = {
  entityId: string;
  entityName: EntityName;
  changes: Record<string, any>;
  displayNameAtLogTime: string | null;
};

export type AppointmentUndoFinishedData = {
  processTaskAppointmentId: string;
};

export type ProcessTaskPropertyModifiedData = {
  propertyId: string;
};

export type AppointmentFinishedAutomaticNextStepData = {
  /** the new step id */
  processConfigurationStepId: string;

  processTaskAppointmentId: string;
};

export type ProcessTaskNameModifiedData = {
  newName: string | null;
};

export type PhoneLinkClickedData = {
  personName: string;
  phoneNumber: string;
};

export type AppointmentOpenedByPersonData = {
  processTaskAppointmentId: string;
  personId: string;
};

export type ProcessConfigurationActionStatusModifiedChangeData = {
  processConfigurationActionStatusId: string | null;
  customActionStatusName: string | null;
  customActionStatusAbbreviation: string | null;
};

export type ProcessTaskLogEntryActionInfo = {
  logDataConverterInfos: Array<ProcessTaskLogEntryActionInfoLogDataConverterInfo>;
};

export type ChecklistEntryCreatedData = {
  processTaskChecklistEntryId: string;
  text: string | null;
  /**
   * only optional for backwards compatibility, new entries should always have this set
   */
  attachmentNames?: Array<string>;
};

export type ChecklistEntryTextChangedData = {
  processTaskChecklistEntryId: string;
  oldText: string | null;
  newText: string | null;
};

export type ChecklistEntryDoneChangedData = {
  processTaskChecklistEntryId: string;
  done: boolean;
};

export type ProcessTaskGroupAuthorizationChangedData = {
  userId: string;
  processConfigurationAuthorizationTypeId: string;
};

export const logEntryConfigurationByProcessTaskLogEntryAction: Record<
  ProcessTaskLogEntryAction,
  ProcessTaskLogEntryActionInfo
> = {
  [ProcessTaskLogEntryAction.CREATED]: {
    logDataConverterInfos: []
  },
  [ProcessTaskLogEntryAction.DESCRIPTION_MODIFIED]: {
    logDataConverterInfos: []
  },
  [ProcessTaskLogEntryAction.ASSIGNEE_CHANGED]: {
    logDataConverterInfos: [
      {
        converterType:
          ProcessTaskLogEntryLogDataConverterType.PROCESS_TASK_GROUP_ASSIGNEE_CHANGED_DATA_CHANGE_FORMATTER,
        converterArgs: { changeDataPropertyPath: 'before' }
      },
      {
        converterType:
          ProcessTaskLogEntryLogDataConverterType.PROCESS_TASK_GROUP_ASSIGNEE_CHANGED_DATA_CHANGE_FORMATTER,
        converterArgs: { changeDataPropertyPath: 'after' }
      }
    ]
  },
  [ProcessTaskLogEntryAction.PROCESS_CONFIGURATION_STEP_ID_MODIFIED]: {
    logDataConverterInfos: [
      {
        converterType: ProcessTaskLogEntryLogDataConverterType.ID_LOOKUP,
        converterArgs: {
          propertyPath: 'processConfigurationStepId',
          entityName: 'ProcessConfigurationStepModel'
        }
      }
    ]
  },
  [ProcessTaskLogEntryAction.PROCESS_CONFIGURATION_ACTION_STATUS_MODIFIED]: {
    logDataConverterInfos: [
      {
        converterType:
          ProcessTaskLogEntryLogDataConverterType.PROCESS_CONFIGURATION_ACTION_STATUS,
        converterArgs: {}
      }
    ]
  },
  [ProcessTaskLogEntryAction.APPOINTMENT_FINISHED]: {
    logDataConverterInfos: [
      {
        converterType: ProcessTaskLogEntryLogDataConverterType.ID_LOOKUP,
        converterArgs: {
          propertyPath: 'processTaskAppointmentId',
          entityName: 'ProcessTaskAppointmentModel'
        }
      }
    ]
  },
  [ProcessTaskLogEntryAction.APPOINTMENT_FINISHED_BY_PERSON]: {
    logDataConverterInfos: [
      {
        converterType: ProcessTaskLogEntryLogDataConverterType.ID_LOOKUP,
        converterArgs: {
          propertyPath: 'processTaskAppointmentId',
          entityName: 'ProcessTaskAppointmentModel'
        }
      },
      {
        converterType: ProcessTaskLogEntryLogDataConverterType.PERSON_ID,
        converterArgs: {}
      },
      {
        converterType: ProcessTaskLogEntryLogDataConverterType.DATE,
        converterArgs: {
          datePath: 'openedAt'
        }
      },
      {
        converterType: ProcessTaskLogEntryLogDataConverterType.TIME_SPAN,
        converterArgs: {
          startDatePath: 'openedAt',
          endDatePath: 'logDate'
        }
      }
    ]
  },
  [ProcessTaskLogEntryAction.APPOINTMENT_UNDO_FINISHED]: {
    logDataConverterInfos: [
      {
        converterType: ProcessTaskLogEntryLogDataConverterType.ID_LOOKUP,
        converterArgs: {
          propertyPath: 'processTaskAppointmentId',
          entityName: 'ProcessTaskAppointmentModel'
        }
      }
    ]
  },
  [ProcessTaskLogEntryAction.APPOINTMENT_UNDO_FINISHED_BY_PERSON]: {
    logDataConverterInfos: [
      {
        converterType: ProcessTaskLogEntryLogDataConverterType.ID_LOOKUP,
        converterArgs: {
          propertyPath: 'processTaskAppointmentId',
          entityName: 'ProcessTaskAppointmentModel'
        }
      },
      {
        converterType: ProcessTaskLogEntryLogDataConverterType.PERSON_ID,
        converterArgs: {}
      }
    ]
  },
  [ProcessTaskLogEntryAction.PROCESS_TASK_PROPERTY_MODIFIED]: {
    logDataConverterInfos: [
      {
        converterType: ProcessTaskLogEntryLogDataConverterType.ID_LOOKUP,
        converterArgs: {
          propertyPath: 'propertyId',
          entityName: 'PropertyModel'
        }
      }
    ]
  },
  [ProcessTaskLogEntryAction.PROCESS_TASK_NAME_MODIFIED]: {
    logDataConverterInfos: []
  },
  [ProcessTaskLogEntryAction.AUTO_APPOINTMENT_CREATED]: {
    logDataConverterInfos: []
  },
  [ProcessTaskLogEntryAction.AUTO_APPOINTMENT_DELETED]: {
    logDataConverterInfos: []
  },
  [ProcessTaskLogEntryAction.SUB_ENTITY_CREATED]: {
    logDataConverterInfos: [
      {
        converterType:
          ProcessTaskLogEntryLogDataConverterType.MOST_RECENT_SUB_ENTITY_DISPLAY_NAME_OF_ENTITY,
        converterArgs: {}
      },
      {
        converterType:
          ProcessTaskLogEntryLogDataConverterType.DISPLAY_NAME_OF_ENTITY,
        converterArgs: {
          entityNamePath: 'entityName',
          entityIdPath: 'entityId',
          fallbackNamePath: 'displayNameAtLogTime'
        }
      }
    ]
  },
  [ProcessTaskLogEntryAction.SUB_ENTITY_MODIFIED]: {
    logDataConverterInfos: [
      {
        converterType:
          ProcessTaskLogEntryLogDataConverterType.MOST_RECENT_SUB_ENTITY_DISPLAY_NAME_OF_ENTITY,
        converterArgs: {}
      },
      {
        converterType:
          ProcessTaskLogEntryLogDataConverterType.DISPLAY_NAME_OF_ENTITY,
        converterArgs: {
          entityNamePath: 'entityName',
          entityIdPath: 'entityId',
          fallbackNamePath: 'displayNameAtLogTime'
        }
      }
    ]
  },
  [ProcessTaskLogEntryAction.SUB_ENTITY_DELETED]: {
    logDataConverterInfos: [
      {
        converterType:
          ProcessTaskLogEntryLogDataConverterType.MOST_RECENT_SUB_ENTITY_DISPLAY_NAME_OF_ENTITY,
        converterArgs: {}
      },
      {
        converterType:
          ProcessTaskLogEntryLogDataConverterType.DISPLAY_NAME_OF_ENTITY,
        converterArgs: {
          entityNamePath: 'entityName',
          entityIdPath: 'entityId',
          fallbackNamePath: 'displayNameAtLogTime'
        }
      }
    ]
  },
  [ProcessTaskLogEntryAction.PHONE_LINK_CLICKED]: {
    logDataConverterInfos: []
  },
  [ProcessTaskLogEntryAction.APPOINTMENT_OPENED_BY_PERSON]: {
    logDataConverterInfos: [
      {
        converterType: ProcessTaskLogEntryLogDataConverterType.ID_LOOKUP,
        converterArgs: {
          propertyPath: 'processTaskAppointmentId',
          entityName: 'ProcessTaskAppointmentModel'
        }
      },
      {
        converterType: ProcessTaskLogEntryLogDataConverterType.PERSON_ID,
        converterArgs: {}
      }
    ]
  },
  [ProcessTaskLogEntryAction.CHECKLIST_ENTRY_CREATED]: {
    logDataConverterInfos: []
  },
  [ProcessTaskLogEntryAction.CHECKLIST_ENTRY_TEXT_CHANGED]: {
    logDataConverterInfos: []
  },
  [ProcessTaskLogEntryAction.CHECKLIST_ENTRY_DONE_CHANGED]: {
    logDataConverterInfos: [
      {
        converterType:
          ProcessTaskLogEntryLogDataConverterType.PROCESS_TASK_CHECKLIST_ENTRY_ID,
        converterArgs: {
          idPropertyPath: 'processTaskChecklistEntryId'
        }
      }
    ]
  },
  [ProcessTaskLogEntryAction.PROCESS_TASK_GROUP_AUTHORIZATION_CREATED]: {
    logDataConverterInfos: [
      {
        converterType: ProcessTaskLogEntryLogDataConverterType.ID_LOOKUP,
        converterArgs: {
          propertyPath: 'userId',
          entityName: EntityName.User
        }
      },
      {
        converterType: ProcessTaskLogEntryLogDataConverterType.ID_LOOKUP,
        converterArgs: {
          propertyPath: 'processConfigurationAuthorizationTypeId',
          entityName: EntityName.ProcessConfigurationAuthorizationType
        }
      }
    ]
  },
  [ProcessTaskLogEntryAction.PROCESS_TASK_GROUP_AUTHORIZATION_DELETED]: {
    logDataConverterInfos: [
      {
        converterType: ProcessTaskLogEntryLogDataConverterType.ID_LOOKUP,
        converterArgs: {
          propertyPath: 'userId',
          entityName: EntityName.User
        }
      },
      {
        converterType: ProcessTaskLogEntryLogDataConverterType.ID_LOOKUP,
        converterArgs: {
          propertyPath: 'processConfigurationAuthorizationTypeId',
          entityName: EntityName.ProcessConfigurationAuthorizationType
        }
      }
    ]
  },
  [ProcessTaskLogEntryAction.APPOINTMENT_FINISHED_AUTOMATIC_NEXT_STEP]: {
    logDataConverterInfos: [
      {
        converterType: ProcessTaskLogEntryLogDataConverterType.ID_LOOKUP,
        converterArgs: {
          propertyPath: 'processConfigurationStepId',
          entityName: 'ProcessConfigurationStepModel'
        }
      },
      {
        converterType: ProcessTaskLogEntryLogDataConverterType.ID_LOOKUP,
        converterArgs: {
          propertyPath: 'processTaskAppointmentId',
          entityName: 'ProcessTaskAppointmentModel'
        }
      }
    ]
  }
};
