import { OptionalProperties } from '../../utilities';
import { UserGroupSubEntity } from '../Base/UserGroupSubEntity';
import { DateType, IdType } from '../Base/types';
import { BaseEntity } from '../Base/BaseEntity';

type FullProcessConfigurationStep<
  TId extends IdType,
  TDate extends DateType
> = BaseEntity<TDate> &
  UserGroupSubEntity<TId> & {
    ownerProcessConfigurationId: TId;
    name: string | null;
    color: string | null;
    iconClassName: string | null;
    iconColor: string | null;
    groupName: string | null;
    appointmentSignatureText: string | null;
    smsNotifications: boolean;
    archive: boolean;
    inspection: boolean;
    appointmentMeasurePointReadingsRequired: boolean;
    picturesRequired: boolean;
    signatureRequired: boolean;
    validFormsRequired: boolean;
    undeployedDevicesRequired: boolean;
    donePositionsRequired: boolean;
    order: number;
    autoProgressConfigurationType: AutoProgressConfigurationType | null;
    autoProgressConfigurationStepId: TId | null;
  };

export type ProcessConfigurationStepDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullProcessConfigurationStep<
    TId,
    TDate
  > = never,
  TPropertiesToRemove extends keyof FullProcessConfigurationStep<
    TId,
    TDate
  > = never
> = Omit<
  OptionalProperties<
    FullProcessConfigurationStep<TId, TDate>,
    TOptionalProperties
  >,
  TPropertiesToRemove
>;

export enum AutoProgressConfigurationType {
  NONE = 'none',
  NEXT_STEP = 'next_step',
  STEP = 'step'
}
