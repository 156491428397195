import { autoinject, bindable, PLATFORM, observable } from 'aurelia-framework';

import {
  PropertyOption,
  PropertyType
} from 'common/Types/Entities/Property/PropertyDto';
import { TagsChangedEvent } from '../../aureliaComponents/categorized-tags-widget/categorized-tags-widget';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';

import { PropertyCreationBaseData } from '../../classes/EntityManager/entities/Property/types';
import { ThingTag } from '../../classes/EntityManager/entities/Tag/types';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import {
  GalleryThingPictureBulkEditHelper,
  GalleryThingPictureBulkEditOptions
} from '../../classes/GalleryThing/GalleryThingPictureBulkEditHelper';
import { GalleryThingWidgetType } from '../../classes/GalleryThing/GalleryThingWidgetType';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../services/PermissionBindingService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { PersonSelectOptions } from '../../services/ViaFilterBarConfigurationService/ViaFilterBarConfigurationService';

/**
 * @techdebt Initial config aside, this component largely overlaps with gallery-thing-picture-filter-bar. They could probably be merged,
 * starting by merging the sub-widget templates.
 */
@autoinject()
export class GalleryThingBulkEditPanel {
  @bindable public bulkEditOptions: GalleryThingPictureBulkEditOptions;

  @bindable public thing: Thing | null = null;

  @bindable public enabled = false;

  private static bulkEditPanelWidgetTypeToTemplateNameMap: Record<
    Exclude<GalleryThingBulkEditWidgetType, GalleryThingWidgetType.Property>,
    string
  > = {
    [GalleryThingWidgetType.Region]: PLATFORM.moduleName(
      './widgetTemplates/region.html'
    ),
    [GalleryThingWidgetType.PersonSelect]: PLATFORM.moduleName(
      './widgetTemplates/personSelect.html'
    ),
    [GalleryThingWidgetType.PersonMultiSelect]: PLATFORM.moduleName(
      './widgetTemplates/personMultiSelect.html'
    ),
    [GalleryThingWidgetType.Tags]: PLATFORM.moduleName(
      './widgetTemplates/tags.html'
    )
  };

  private static STANDARD_BULK_EDIT_PANEL_CONFIGURATION: BulkEditConfiguration =
    {
      widgets: [
        { type: GalleryThingWidgetType.Region },
        { type: GalleryThingWidgetType.PersonMultiSelect },
        { type: GalleryThingWidgetType.Tags }
      ]
    };

  private bulkEditConfiguration: BulkEditConfiguration =
    GalleryThingBulkEditPanel.STANDARD_BULK_EDIT_PANEL_CONFIGURATION;

  @observable
  private bulkEditConfigurationSetting: BulkEditConfiguration | null = null;

  protected availableTags: Array<ThingTag> = [];
  protected useCategorizedTags = false;

  private properties: Array<PropertyCreationBaseData> = [];
  private permissionBindingHandle: PermissionBindingHandle;
  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly entityManager: AppEntityManager,
    private readonly activeUsercompanySettingService: ActiveUserCompanySettingService,
    permissionBindingService: PermissionBindingService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      permissionProperties: {
        canUseRegionExtension: 'canUseRegionExtension'
      }
    });

    this.subscriptionManager = subscriptionManagerService.create();
    this.bulkEditOptions =
      GalleryThingPictureBulkEditHelper.createEmptyBulkEditOptions();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'via.bulkEditConfiguration',
        (bulkEditConfiguration) => {
          this.bulkEditConfigurationSetting = bulkEditConfiguration;
        }
      )
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Tag,
      this.updateAvailableTags.bind(this)
    );
    this.updateAvailableTags();

    this.subscriptionManager.addDisposable(
      this.activeUsercompanySettingService.bindSettingProperty(
        'via.useCategorizedTags',
        (value) => {
          this.useCategorizedTags = value;
        }
      )
    );

    this.permissionBindingHandle.subscribe();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
    this.permissionBindingHandle.unsubscribe();
  }

  protected bulkEditOptionsChanged(): void {
    this.updateProperties();
  }

  protected handleTagCreated(name: string): void {
    if (!this.thing?.id) return;

    const tag = this.entityManager.tagRepository
      .getByThingId(this.thing.id)
      .find((t) => t.name === name);
    if (tag) {
      this.bulkEditOptions.tags.push(tag);
    }
  }

  protected handleTagRemoved(tag: ThingTag): void {
    this.bulkEditOptions.tags = this.bulkEditOptions.tags.filter(
      (t) => t.name !== tag.name
    );
  }

  protected handleTagsChanged(evt: TagsChangedEvent): void {
    this.bulkEditOptions.tags = evt.detail.tags;
  }

  protected bulkEditConfigurationSettingChanged(): void {
    this.updateBulkEditConfiguration();
  }

  private updateBulkEditConfiguration(): void {
    if (
      this.bulkEditConfigurationSetting &&
      this.bulkEditConfigurationSetting.widgets
    ) {
      this.bulkEditConfiguration = this.bulkEditConfigurationSetting;
    } else {
      this.bulkEditConfiguration =
        GalleryThingBulkEditPanel.STANDARD_BULK_EDIT_PANEL_CONFIGURATION;
    }
    this.updateProperties();
  }

  private updateProperties(): void {
    const propertyWidgets = this.bulkEditConfiguration.widgets.filter(
      (widget) => widget.type === GalleryThingWidgetType.Property
    );

    const properties: Array<PropertyCreationBaseData> = [];

    propertyWidgets.forEach((widget) => {
      properties.push({
        name: widget.propertyName,
        type: widget.propertyType,
        choices: widget.propertyChoices || undefined,
        options: widget.propertyOptions || undefined,
        value: null
      });
    });

    this.properties = properties;
  }

  protected getContentTemplateName(
    widgetType: GalleryThingBulkEditWidgetType | null
  ): string | null {
    if (!widgetType || widgetType === GalleryThingWidgetType.Property)
      return null;
    return (
      GalleryThingBulkEditPanel.bulkEditPanelWidgetTypeToTemplateNameMap[
        widgetType
      ] ?? null
    );
  }

  /**
   * @param {string} name
   * @param {string} type
   * @param {Array<PropertyCreationBaseData>} _properties - only used to update view correctly
   * @returns {PropertyCreationBaseData|undefined}
   */
  private getProperty(
    name: string,
    type: string,
    _properties: Array<PropertyCreationBaseData>
  ): PropertyCreationBaseData | undefined {
    return this.properties.find((p) => p.name === name && p.type === type);
  }

  private handlePropertyWidgetValueChanged(): void {
    this.properties.forEach((property) => {
      if (!this.bulkEditOptions.properties)
        this.bulkEditOptions.properties = [];
      const index = this.bulkEditOptions.properties.findIndex(
        (p) => p.name === property.name && p.type === property.type
      );
      if (index >= 0) {
        this.bulkEditOptions.properties[index] = property;
      } else {
        this.bulkEditOptions.properties.push(property);
      }
    });
  }

  private updateAvailableTags(): void {
    if (!this.thing) {
      this.availableTags = [];
      return;
    }

    this.availableTags = this.thing.id
      ? this.entityManager.tagRepository.getByThingId(this.thing.id)
      : [];
  }
}

type BulkEditConfiguration = {
  widgets: Array<BulkEditWidgetConfiguration>;
};

type BulkEditWidgetConfiguration = {
  type: GalleryThingBulkEditWidgetType;
  options?: PersonSelectOptions | null;
  propertyName?: string | null;
  propertyType?: PropertyType | null;
  propertyChoices?: Array<string> | null;
  propertyOptions?: Array<PropertyOption> | null;
};

type GalleryThingBulkEditWidgetType = Extract<
  GalleryThingWidgetType,
  | GalleryThingWidgetType.Region
  | GalleryThingWidgetType.PersonSelect
  | GalleryThingWidgetType.PersonMultiSelect
  | GalleryThingWidgetType.Property
  | GalleryThingWidgetType.Tags
>;
