/**
 * Validates that `data` passed is an array of ALL values in the union supplied as `TBaseType`.
 *
 * @example
 * const x = allKeysOfUnion<'hello'|'world'>()(['hello', 'world']); // compiles
 * const x = allKeysOfUnion<'hello'>()(['hello', 'world']); // type error
 * const x = allKeysOfUnion<'hello'|'world'>()(['hello']); // type error
 */
export function allKeysOfUnion<TBaseType>(): <T extends Array<TBaseType>>(
  data: [T, Array<TBaseType>] extends [Array<TBaseType>, T] ? T : never
) => T {
  return (x) => x;
}

/**
 * workaround so we can check if a from code inferred type, extends a BaseType
 *
 * Example:
 *   const x = createTypeExtendsValidator<Array<keyof { propA: string, propB: string }>>()(['propA'])
 * In this example the type of x will be `['propA']`. Also, a type error will be thrown if e.g. you pass `['proAp'] to the function.`
 */
export function createTypeExtendsValidator<TBaseType>(): <T extends TBaseType>(
  data: Readonly<T>
) => T {
  return (x) => x;
}
