import { App } from '@capacitor/app';
import { Geolocation, Position } from '@capacitor/geolocation';

import { Vector } from 'common/Geometry/Vector';

import { MathHelper } from './MathHelper';
import { TestingHelper } from './TestingHelper';
import { IUtilsRateLimitedFunction, Utils } from './Utils/Utils';
import { DataStorageHelper } from './DataStorageHelper/DataStorageHelper';
import { EventDispatcher } from './EventDispatcher/EventDispatcher';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { NoCoordinatesDialog } from '../dialogs/no-coordinates-dialog/no-coordinates-dialog';

export class CoordinateHelper {
  private static LAST_COORDINATES_DATABASE_KEY =
    'CoordinateHelper.lastCoordinates';

  /** maximum age of coordinates in ms */
  private static MAX_TIMESTAMP_AGE = 5 * 60 * 1000;

  private static initalized = false;
  private static currentCoordinates: Coordinates = {
    latitude: null,
    longitude: null,
    accuracy: null,
    timestamp: null,
    expiresAt: null
  };

  private static startWatchingPositionRateLimited: IUtilsRateLimitedFunction;
  private static saveLastCoordinatesRateLimited: IUtilsRateLimitedFunction;
  private static watchId: string | null = null;

  private static paused = false;

  private static earthRadius = 6371000;

  private static eventDispatcher: EventDispatcher<EventDispatcherConfig> | null =
    null;

  /**
   * this will always return the same instance so observers can work on this object
   */
  public static getClientCoordinates(): Coordinates {
    return this.currentCoordinates;
  }

  public static async getClientCoordinatesWithDialog(): Promise<Coordinates | null> {
    if (
      !this.currentCoordinates.expiresAt ||
      this.currentCoordinates.expiresAt < Date.now()
    ) {
      const coordinates = await this.getCoordinatesFromDialogIfNecessary();
      this.setNewPosition(coordinates);
      this.saveLastCoordinatesRateLimited();
    }
    return this.currentCoordinates;
  }

  public static subscribeToCoordinatesChanged(
    context: any,
    callback: (payload: Coordinates) => void
  ): void {
    assertNotNullOrUndefined(
      this.eventDispatcher,
      'cannot subscribe without an event dispatcher'
    );
    this.eventDispatcher.addEventListener(
      context,
      'coordinatesChanged',
      callback
    );
  }

  public static unsubscribeByContext(context: any): void {
    assertNotNullOrUndefined(
      this.eventDispatcher,
      'cannot unsubscribe without an event dispatcher'
    );
    this.eventDispatcher.removeEventListenersByContext(context);
  }

  public static async init(): Promise<void> {
    if (this.initalized) {
      return;
    }

    const lastCoordinates = await this.loadLastCoordinates();
    this.applyLastCoordinatesToClientCoordinates(lastCoordinates);

    this.startWatchingPositionRateLimited = Utils.rateLimitFunction(
      this.startWatchingPosition.bind(this),
      5000
    );
    this.saveLastCoordinatesRateLimited = Utils.rateLimitFunction(
      this.saveLastCoordinates.bind(this),
      5000
    );

    await this.startWatchingPosition();

    // do not update the position while the app is in the background
    void App.addListener('appStateChange', (appState) => {
      if (appState.isActive) {
        this.paused = false;
        void this.startWatchingPosition();
      } else {
        this.startWatchingPositionRateLimited.cancel();
        void this.stopWatchingPosition();
        this.paused = true;
      }
    });

    this.eventDispatcher = new EventDispatcher();

    this.initalized = true;
  }

  /**
   * calculate the distance between two coordinates
   */
  public static calculateDistance(
    lon1: number,
    lat1: number,
    lon2: number,
    lat2: number
  ): number {
    const dLat = MathHelper.degreesToRadiant(lat2 - lat1);
    const dLon = MathHelper.degreesToRadiant(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(MathHelper.degreesToRadiant(lat1)) *
        Math.cos(MathHelper.degreesToRadiant(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return this.earthRadius * c; // distance in m
  }

  /**
   * adds 'm(eters)' and 'km(kilometers)' to the distance
   */
  public static calculateFormattedDistance(
    lon1: number,
    lat1: number,
    lon2: number,
    lat2: number
  ): string {
    const distance = this.calculateDistance(lon1, lat1, lon2, lat2);

    if (distance && distance >= 1000) {
      return (distance != null ? (distance / 1000).toFixed(1) : null) + ' km';
    } else {
      return (distance != null ? distance.toFixed(0) : null) + ' m';
    }
  }

  /**
   * returns an approx vector of 'x' distance and 'y' distance
   * x and y are meters
   */
  public static createDeltaVector(
    lat1: number,
    long1: number,
    lat2: number,
    long2: number
  ): Vector {
    const avgLat = (lat1 + lat2) / 2;
    const xSign = long1 - long2 >= 0 ? 1 : -1;
    const x = this.calculateDistance(avgLat, long1, avgLat, long2) * xSign;

    const avgLong = (long1 + long2) / 2;
    const ySign = lat1 - lat2 >= 0 ? 1 : -1;
    const y = this.calculateDistance(lat1, avgLong, lat2, avgLong) * ySign;
    return new Vector(x, y);
  }

  /**
   * for documentation look at 'createDeltaVector'
   */
  public static createDeltaVectorForLatitudeLongitude(
    latitudeLongitude1: { latitude: number; longitude: number },
    latitudeLongitude2: { latitude: number; longitude: number }
  ): Vector {
    return this.createDeltaVector(
      latitudeLongitude1.latitude,
      latitudeLongitude1.longitude,
      latitudeLongitude2.latitude,
      latitudeLongitude2.longitude
    );
  }

  /**
   * @param {number} coordinate one single coordinate (lat or long)
   */
  public static roundCoordinate(coordinate: number): string {
    return coordinate ? Number(coordinate).toFixed(4) : '';
  }

  /**
   * returns the new latitude moved by distance meters
   *
   * @param {number} lat
   * @param {number} distance - in meters
   */
  public static movePointAlongLatitude(lat: number, distance: number): number {
    return lat + (distance / this.earthRadius) * (180 / Math.PI);
  }

  /**
   * returns the new longitude moved by distance meters
   */
  public static movePointAlongLongitude(
    long: number,
    lat: number,
    distance: number
  ): number {
    return (
      long +
      ((distance / this.earthRadius) * (180 / Math.PI)) /
        Math.cos((lat * Math.PI) / 180)
    );
  }

  public static coordinateToNumber(coordinate: string | number): number {
    switch (typeof coordinate) {
      case 'number':
        return coordinate;

      case 'string':
        const p = parseFloat(coordinate);
        return !isNaN(p) ? p : 0;

      default:
        return 0;
    }
  }

  private static async startWatchingPosition(): Promise<void> {
    this.watchId = await Geolocation.watchPosition(
      {
        maximumAge: 60000,
        enableHighAccuracy: true
      },
      (position) => {
        if (position) this.applyNewPosition(position);
      }
    );
  }

  private static async stopWatchingPosition(): Promise<void> {
    const watchId = this.watchId;

    if (watchId) {
      await Geolocation.clearWatch({ id: watchId });
      if (watchId === this.watchId) {
        this.watchId = null;
      }
    }
  }

  private static applyNewPosition(position: Position): void {
    const timestamp = position.timestamp;
    this.setNewPosition({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      accuracy: position.coords.accuracy,
      timestamp: timestamp,
      expiresAt: timestamp + CoordinateHelper.MAX_TIMESTAMP_AGE
    });

    this.saveLastCoordinatesRateLimited();
  }

  private static async loadLastCoordinates(): Promise<CoordinatesWithDate> {
    let lastCoordinates = await DataStorageHelper.getItem(
      this.LAST_COORDINATES_DATABASE_KEY
    );

    if (!lastCoordinates) {
      lastCoordinates = {
        latitude: null,
        longitude: null,
        accuracy: null,
        timestamp: null,
        expiresAt: null,
        date: null
      };
    }

    if (lastCoordinates.date) {
      // the date is stored as a string in the database, because JSON.stringify automatically does this
      lastCoordinates.date = new Date(lastCoordinates.date);
    }

    return lastCoordinates;
  }

  private static applyLastCoordinatesToClientCoordinates(
    lastCoordinates: CoordinatesWithDate
  ): void {
    this.setNewPosition(lastCoordinates);
  }

  private static setNewPosition(position: Coordinates): void {
    Object.assign(this.currentCoordinates, position);
    this.eventDispatcher?.dispatchEvent(
      'coordinatesChanged',
      this.currentCoordinates
    );
  }

  /**
   * you probably want to call _saveLastCoordinatesRateLimited instead of this
   */
  private static saveLastCoordinates(): void {
    void DataStorageHelper.setItem(this.LAST_COORDINATES_DATABASE_KEY, {
      latitude: this.currentCoordinates.latitude,
      longitude: this.currentCoordinates.longitude,
      accuracy: this.currentCoordinates.accuracy,
      timestamp: this.currentCoordinates.timestamp,
      expiresAt: this.currentCoordinates.expiresAt,
      date: new Date()
    });
  }

  private static async getCoordinatesFromDialogIfNecessary(): Promise<Coordinates> {
    const lastCoordinates = { ...this.currentCoordinates };
    return await new Promise((resolve) => {
      void NoCoordinatesDialog.open({
        lastCoordinates: lastCoordinates,
        onDialogClosedCallback: (coordinates) => resolve(coordinates)
      });
    });
  }
}

TestingHelper.CoordinateHelper = CoordinateHelper;

/**
 * values are null if they are unknown
 */
export type Coordinates = {
  latitude: number | null;
  longitude: number | null;
  accuracy: number | null;
  timestamp: number | null;
  expiresAt: number | null;
};

/**
 * values are null if they are unknown
 */
type CoordinatesWithDate = Coordinates & {
  date: Date | null;
};

type EventDispatcherConfig = {
  coordinatesChanged: Coordinates;
};
