import { DateUtils } from '../DateUtils';
import { DateType, IdType } from '../Types/Entities/Base/types';
import { ProcessTaskGroupDto } from '../Types/Entities/ProcessTaskGroup/ProcessTaskGroupDto';

export class ProcessTaskGroupHelper {
  private constructor() {}

  public static getAutomaticSynchronizationFromToDate(): AutomaticSynchronizationFromTo {
    const today = DateUtils.removeTimeInfoFromDate(new Date());

    const from = new Date(today.getTime() - 5 * 24 * 60 * 60 * 1000); // 5 days in the past
    const to = new Date(today.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days in the future

    return {
      from,
      to
    };
  }

  public static getInvoiceReceiverPersonIdOrFallback<TId extends IdType>({
    offerReceiverIsInvoiceReceiver,
    offerReceiverPersonId,
    invoiceReceiverPersonId,
    contactPersonId
  }: GetInvoiceReceiverPersonIdOrFallbackOptions<TId>): TId | null {
    return (
      this.getInvoiceReceiverPersonId({
        offerReceiverIsInvoiceReceiver,
        offerReceiverPersonId,
        invoiceReceiverPersonId
      }) ?? contactPersonId
    );
  }

  public static getInvoiceReceiverPersonId<TId extends IdType>({
    offerReceiverIsInvoiceReceiver,
    offerReceiverPersonId,
    invoiceReceiverPersonId
  }: {
    offerReceiverIsInvoiceReceiver: boolean;
    offerReceiverPersonId: TId | null;
    invoiceReceiverPersonId: TId | null;
  }): TId | null {
    if (offerReceiverIsInvoiceReceiver) {
      return offerReceiverPersonId;
    }

    return invoiceReceiverPersonId;
  }
}

export type AutomaticSynchronizationFromTo = {
  from: Date;
  to: Date;
};

export type GetInvoiceReceiverPersonIdOrFallbackOptions<TId extends IdType> =
  Pick<
    ProcessTaskGroupDto<TId, DateType>,
    | 'offerReceiverIsInvoiceReceiver'
    | 'offerReceiverPersonId'
    | 'invoiceReceiverPersonId'
    | 'contactPersonId'
  >;
