import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { ProcessConfigurationContactPersonPurpose } from 'common/Types/Entities/ProcessConfiguration/ProcessConfigurationDto';

import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { ProcessTaskGroupContactPerson as CommonProcessTaskGroupContactPerson } from 'common/Types/Entities/ProcessTaskGroup/ProcessTaskGroupDto';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { PersonSelectAndContactWidgetPersonChangedEvent } from '../../personComponents/person-select-and-contact-widget/person-select-and-contact-widget';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { Dialogs } from '../../classes/Dialogs';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';
import { EntityUtils } from '@record-it-npm/synchro-client';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import {
  ProcessTaskGroupToCreate,
  create_process_task_group
} from '../../pages/create_process_task_group/create_process_task_group';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class ProcessTaskGroupMultipleContactPersonsWidget<
  TSupportedProcessTaskGroup extends ProcessTaskGroup | ProcessTaskGroupToCreate
> {
  @bindable()
  public processTaskGroup: TSupportedProcessTaskGroup | null = null;

  @bindable public labelClass =
    'person-select-and-contact-widget--InfoLabel record-it-input-label';

  protected selectedPurposeValue: string | null = null;

  protected displayableContactPersons: Array<ContactPerson> = [];

  @subscribableLifecycle()
  protected permissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskGroup];

  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly i18n: I18N,
    permissionsService: PermissionsService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.permissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskGroup,
        context: this,
        expression: 'processTaskGroup'
      });
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(EntityName.Person, () => {
      this.updateDisplayableContactPersons();
    });
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskGroup,
      () => {
        this.updateDisplayableContactPersons();
      }
    );
    this.subscriptionManager.subscribeToExpression(
      this,
      'processConfiguration.contactPersonPurposes',
      () => {
        this.updateDisplayableContactPersons();
      }
    );
    this.subscriptionManager.subscribeToExpression(
      this,
      'processTaskGroup.thingGroupOwnerPersonId',
      () => {
        this.updateDisplayableContactPersons();
      }
    );
    this.updateDisplayableContactPersons();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  @computed(
    expression('processTaskGroup.processConfigurationId'),
    model(EntityName.ProcessConfiguration)
  )
  private get processConfiguration(): ProcessConfiguration | null {
    if (!this.processTaskGroup || !this.processTaskGroup.processConfigurationId)
      return null;
    return this.entityManager.processConfigurationRepository.getById(
      this.processTaskGroup.processConfigurationId
    );
  }

  protected handleContactPersonChanged(
    event: PersonSelectAndContactWidgetPersonChangedEvent,
    contactPerson: ProcessTaskGroupContactPerson
  ): void {
    assertNotNullOrUndefined(
      this.processTaskGroup,
      'cannot ProcessTaskGroupMultipleContactPersonsWidget.handleContactPersonChanged without processTaskGroup'
    );
    if (
      this.processTaskGroup.contactPersons.find(
        (x) =>
          x.personId === event.detail.personId &&
          x.purposeOfPerson === contactPerson.purposeOfPerson
      )
    ) {
      void Dialogs.errorDialogTk(
        'operationsComponents.processTaskGroupMultipleContactPersonsWidget.combinationAlreadyExists'
      );
      return;
    }
    contactPerson.personId = event.detail.personId;
    this.handleProcessTaskGroupChanged();
  }

  protected handleProcessTaskGroupChanged(): void {
    assertNotNullOrUndefined(
      this.processTaskGroup,
      'cannot ProcessTaskGroupMultipleContactPersonsWidget.handleAddButtonClicked without processTaskGroup'
    );
    // This component shall support both currently being created processTaskGroups (not yet created in entityManager) as well as already created ones.
    // We can only invoke updates if the entity is already created, otherwise we force update the contactPersons and skip the update step.
    if (
      this.processTaskGroup.temporaryGroupName ===
      create_process_task_group.TEMPORARY_GROUP_NAME
    ) {
      this.updateDisplayableContactPersons();
      return;
    }
    this.entityManager.processTaskGroupRepository.update(
      this.processTaskGroup as ProcessTaskGroup
    );
  }

  protected handleAddButtonClicked(): void {
    assertNotNullOrUndefined(
      this.processTaskGroup,
      'cannot ProcessTaskGroupMultipleContactPersonsWidget.handleAddButtonClicked without processTaskGroup'
    );
    const newContactPerson = {
      personId: null,
      purposeOfPerson: this.selectedPurposeValue ?? null
    };
    if (
      this.processTaskGroup.contactPersons.find(
        (x) =>
          x.personId === newContactPerson.personId &&
          x.purposeOfPerson === newContactPerson.purposeOfPerson
      )
    ) {
      void Dialogs.errorDialogTk(
        'operationsComponents.processTaskGroupMultipleContactPersonsWidget.combinationAlreadyExists'
      );
      return;
    }
    this.processTaskGroup.contactPersons.push(newContactPerson);
    this.selectedPurposeValue = null;
    this.handleProcessTaskGroupChanged();
  }

  protected handleDeleteButtonClicked(
    contactPerson: ProcessTaskGroupContactPerson
  ): void {
    assertNotNullOrUndefined(
      this.processTaskGroup,
      'cannot ProcessTaskGroupMultipleContactPersonsWidget.handleDeleteButtonClicked without processTaskGroup'
    );
    const index = this.processTaskGroup.contactPersons.indexOf(contactPerson);
    if (index === -1) {
      throw new Error(
        `Process task group contact person id: ${contactPerson.personId} purpose: ${contactPerson.purposeOfPerson} does not exist.`
      );
    }
    this.processTaskGroup.contactPersons.splice(index, 1);
    this.handleProcessTaskGroupChanged();
  }

  private updateDisplayableContactPersons(): void {
    this.displayableContactPersons = InstancePreserver.createNewArray({
      originalArray: this.displayableContactPersons,
      newArray: this.getNewDisplayableDisplayPersons(),
      getTrackingValue: (x) =>
        (x.person ? EntityUtils.getTrackingKey(x.person) : 'null') +
          x.processTaskGroupContactPerson.purposeOfPerson ?? 'null'
    });
  }

  private getNewDisplayableDisplayPersons(): Array<ContactPerson> {
    return (
      this.processTaskGroup?.contactPersons.map((x) => {
        const person = x.personId
          ? this.entityManager.personRepository.getById(x.personId)
          : null;
        return {
          processTaskGroupContactPerson: x,
          person,
          purposeLabel: this.getPurposeOfPersonLabel(x),
          restrictToCategory: this.getRestrictedCategoryOfPerson(x),
          userIdToRestrict: this.getUserIdRestriction(x),
          canEditRestrictedPersonField: this.canEditRestrictedPersonField(x)
        };
      }) ?? []
    );
  }

  private getPurposeOfPersonLabel(
    contactPersonConfig: ProcessTaskGroupContactPerson
  ): string {
    const fallbackText = this.i18n.tr('modelProperties.contactPersonId');
    if (!this.processConfiguration) return fallbackText;
    return (
      this.getPurposeOfPerson(contactPersonConfig)?.label ??
      contactPersonConfig.purposeOfPerson ??
      fallbackText
    );
  }

  private getRestrictedCategoryOfPerson(
    contactPersonConfig: ProcessTaskGroupContactPerson
  ): string | null {
    if (!this.processConfiguration) return null;
    return (
      this.getPurposeOfPerson(contactPersonConfig)?.restrictToCategory ?? null
    );
  }

  private getPurposeOfPerson(
    contactPersonConfig: ProcessTaskGroupContactPerson
  ): ProcessConfigurationContactPersonPurpose | null {
    if (!this.processConfiguration) return null;
    return (
      this.processConfiguration.contactPersonPurposes.find(
        (x) => contactPersonConfig.purposeOfPerson === x.value
      ) ?? null
    );
  }

  private getUserIdRestriction(
    contactPersonConfig: ProcessTaskGroupContactPerson
  ): string | null {
    return !!this.getPurposeOfPerson(contactPersonConfig)
      ?.restrictToThingGroupOwnerPersonRelations
      ? this.processTaskGroup?.thingGroupOwnerPersonId ?? null
      : null;
  }

  private canEditRestrictedPersonField(
    contactPersonConfig: ProcessTaskGroupContactPerson
  ): boolean {
    if (
      !this.getPurposeOfPerson(contactPersonConfig)
        ?.restrictToThingGroupOwnerPersonRelations
    )
      return true;
    return !!this.processTaskGroup?.thingGroupOwnerPersonId;
  }
}

type ContactPerson = {
  processTaskGroupContactPerson: ProcessTaskGroupContactPerson;
  person: Person | null;
  purposeLabel: string;
  restrictToCategory: string | null;
  userIdToRestrict: string | null;
  canEditRestrictedPersonField: boolean;
};

type ProcessTaskGroupContactPerson =
  CommonProcessTaskGroupContactPerson<string>;
