import { autoinject, bindable } from 'aurelia-framework';
import {
  DraggingService,
  DragInProgressInfo
} from '../services/DraggingService';
import { PointerEventHelper } from '../classes/PointerEventHelper';
import { assertNotNullOrUndefined } from '../../../common/src/Asserts';
import { ArrayUtils } from '../../../common/src/Utils/ArrayUtils';

@autoinject()
export class DragOverCustomAttribute {
  @bindable public onDragOver:
    | ((params: { dragInProgressInfo: DragInProgressInfo }) => void)
    | null = null;
  @bindable public onDragOut: (() => void) | null = null;
  @bindable public onDrop: ((params: { dragData: unknown }) => void) | null =
    null;
  @bindable public onActivate:
    | ((params: { dragData: unknown }) => boolean)
    | null = null;
  @bindable public onDeactivate: (() => void) | null = null;
  @bindable public validateData:
    | ((params: { dragData: unknown }) => boolean)
    | null = null;

  /**
   * readonly!
   */
  @bindable public dataIsValid: boolean = true;
  /**
   * readonly!
   */
  @bindable public dragInProgress: boolean = false;
  /**
   * readonly!
   */
  @bindable public mouseIsInside: boolean = false;

  private static touchEventHandlerInitialized = false;
  private static boundHandleTouchMove =
    DragOverCustomAttribute.handleTouchMove.bind(DragOverCustomAttribute);
  private static attachedInstances: Array<DragOverCustomAttribute> = [];

  private static setupTouchHandler(): void {
    if (!this.touchEventHandlerInitialized) {
      document.body.addEventListener('touchmove', this.boundHandleTouchMove);
      this.touchEventHandlerInitialized = true;
    }
  }

  private static removeTouchHandler(): void {
    if (this.touchEventHandlerInitialized) {
      document.body.removeEventListener('touchmove', this.boundHandleTouchMove);
      this.touchEventHandlerInitialized = false;
    }
  }

  private static handleTouchMove(event: TouchEvent): void {
    this.attachedInstances.forEach((instance) =>
      instance.handleTouchMove(event)
    );
  }

  private currentDragInProgressInfo: DragInProgressInfo | null = null;

  private readonly boundMouseEnterEvent = this.handleMouseEnterEvent.bind(this);
  private readonly boundMouseLeaveEvent = this.handleMouseLeaveEvent.bind(this);

  constructor(
    private readonly domElement: Element,
    private readonly draggingService: DraggingService
  ) {}

  protected attached(): void {
    DragOverCustomAttribute.attachedInstances.push(this);

    this.draggingService.registerEventHandler(
      this,
      'draggingStarted',
      this.handleDraggingStarted.bind(this)
    );
    this.draggingService.registerEventHandler(
      this,
      'droppedElement',
      this.handleElementDropped.bind(this)
    );
    this.draggingService.registerEventHandler(
      this,
      'draggingEnded',
      this.handleDraggingEnded.bind(this)
    );

    const dragInProgressInfo = this.draggingService.getDragInProgressInfo();
    if (dragInProgressInfo) {
      this.handleDraggingStarted(dragInProgressInfo);
    }
  }

  protected detached(): void {
    ArrayUtils.remove(DragOverCustomAttribute.attachedInstances, this);

    this.mouseIsInside = false;
    this.draggingService.unregisterEventHandlerByContext(this);
    this.removeEventHandlers();
  }

  private handleDraggingStarted(info: DragInProgressInfo): void {
    this.dataIsValid = this.validateData?.({ dragData: info.dragData }) ?? true;
    this.dragInProgress = this.dataIsValid
      ? this.onActivate?.({ dragData: info.dragData }) ?? true
      : false;

    if (this.dragInProgress) {
      this.setupEventHandlers();
      this.currentDragInProgressInfo = info;
    }
  }

  private handleElementDropped(): void {
    if (
      this.mouseIsInside &&
      this.dataIsValid &&
      this.currentDragInProgressInfo
    ) {
      this.onDrop?.({ dragData: this.currentDragInProgressInfo.dragData });
    }
  }

  private handleDraggingEnded(): void {
    const oldDragInProgress = this.dragInProgress;

    this.removeEventHandlers();

    this.mouseIsInside = false;
    this.dataIsValid = false;
    this.dragInProgress = false;
    this.currentDragInProgressInfo = null;

    if (oldDragInProgress) {
      this.onDeactivate?.();
    }
  }

  private handleMouseEnterEvent(): void {
    assertNotNullOrUndefined(
      this.currentDragInProgressInfo,
      "can't DragOverCustomAttribute.handleMouseEnterEvent without currentDragInProgressInfo"
    );
    this.mouseIsInside = true;
    this.onDragOver &&
      this.onDragOver({ dragInProgressInfo: this.currentDragInProgressInfo });
  }

  private handleMouseLeaveEvent(): void {
    this.mouseIsInside = false;
    this.onDragOut && this.onDragOut();
  }

  private handleTouchMove(event: TouchEvent): void {
    const position = PointerEventHelper.getClientPositionFromEvent(event);
    const boundingRect = this.domElement.getBoundingClientRect();
    const positionIsInside = PointerEventHelper.clientPointIsInsideClientRect(
      position,
      boundingRect
    );

    if (positionIsInside && !this.mouseIsInside) {
      this.handleMouseEnterEvent();
    } else if (!positionIsInside && this.mouseIsInside) {
      this.handleMouseLeaveEvent();
    }
  }

  private setupEventHandlers(): void {
    DragOverCustomAttribute.setupTouchHandler();
    this.domElement.addEventListener('mouseenter', this.boundMouseEnterEvent);
    this.domElement.addEventListener('mouseleave', this.boundMouseLeaveEvent);
  }

  private removeEventHandlers(): void {
    DragOverCustomAttribute.removeTouchHandler();
    if (this.mouseIsInside) {
      this.handleMouseLeaveEvent();
    }
    this.domElement.removeEventListener(
      'mouseenter',
      this.boundMouseEnterEvent
    );
    this.domElement.removeEventListener(
      'mouseleave',
      this.boundMouseLeaveEvent
    );
  }
}
