import { assertNotNullOrUndefined } from '../../Asserts';
import { KeyValueCache } from '../../Cache/KeyValueCache/KeyValueCache';
import { EntityName } from '../../Types/BaseEntities/EntityName';
import { UserDto } from '../../Types/Entities/User/UserDto';
import { UserGroupDto } from '../../Types/Entities/UserGroup/UserGroupDto';
import {
  UserRoleDto,
  UserRolePermissionFields
} from '../../Types/Entities/UserRole/UserRoleDto';
import { UserRoleToUserDto } from '../../Types/Entities/UserRoleToUser/UserRoleToUserDto';
import { Utils } from '../../Utils';
import { StringUtils } from '../../Utils/StringUtils/StringUtils';
import {
  CombinedUserRole,
  UserRoleCombiner
} from '../UserRoleCombiner/UserRoleCombiner';
import { entityNameToRoleInfo } from './entityNameToRoleInfo';

export class RoleBasedPermissions {
  private readonly user: UserDto<string, string> | null;
  private readonly userGroupsById: UserGroupsById;
  private readonly userRolesByUserGroupId: UserRolesByUserGroupId;
  private readonly userRoleToUsersOfUserByUserGroupId: UserRoleToUsersOfUserByUserGroupId;

  private readonly userGroupSpecificPermissionsByUserGroupIdCache: KeyValueCache<
    string,
    RoleBasedUserGroupSpecificPermissions
  >;

  constructor({
    user,
    userGroupsOfUser,
    userRolesOfUserGroups,
    userRoleToUsersOfUser
  }: RoleBasedPermissionsOptions) {
    this.user = user;
    this.userGroupsById = Utils.keyValues(
      userGroupsOfUser,
      (userGroup) => userGroup.id
    );
    this.userRolesByUserGroupId = Utils.groupValues(
      userRolesOfUserGroups,
      (userRole) => userRole.ownerUserGroupId
    );
    this.userRoleToUsersOfUserByUserGroupId = Utils.groupValues(
      userRoleToUsersOfUser,
      (userRoleToUser) => userRoleToUser.ownerUserGroupId
    );

    this.userGroupSpecificPermissionsByUserGroupIdCache = new KeyValueCache({
      getValue: this.createUserGroupSpecificPermissions.bind(this)
    });
  }

  public inUserGroupId(
    userGroupId: string
  ): RoleBasedUserGroupSpecificPermissions {
    return this.userGroupSpecificPermissionsByUserGroupIdCache.get(userGroupId);
  }

  private createUserGroupSpecificPermissions(
    userGroupId: string
  ): RoleBasedUserGroupSpecificPermissions {
    const userGroup = this.userGroupsById.get(userGroupId) ?? null;
    return new RoleBasedUserGroupSpecificPermissions({
      user: this.user,
      userGroup,
      combinedUserRole: this.getCombinedUserRole({
        userGroup
      })
    });
  }

  private getCombinedUserRole({
    userGroup
  }: {
    userGroup: UserGroupDto<string, string> | null;
  }): CombinedUserRole {
    const user = this.user;
    if (!userGroup || !user) {
      return UserRoleCombiner.createEmptyCombinedUserRole();
    }

    const userRoleToUsers =
      this.userRoleToUsersOfUserByUserGroupId.get(userGroup.id) ?? [];
    const allUserRoles = this.userRolesByUserGroupId.get(userGroup.id) ?? [];

    const relevantUserRoles = userRoleToUsers.map((userRoleToUser) => {
      if (userRoleToUser.userId !== user.id) {
        throw new Error(
          `found a userRole which doesn't belong to the user. userRoleToUserId: ${userRoleToUser.id} ; userId: ${user.id}`
        );
      }

      const userRole = allUserRoles.find(
        (role) => role.id === userRoleToUser.userRoleId
      );
      assertNotNullOrUndefined(
        userRole,
        `no userRole found for ${userRoleToUser.userRoleId}`
      );

      return userRole;
    });

    return UserRoleCombiner.combineUserRoles(relevantUserRoles);
  }
}

export class RoleBasedUserGroupSpecificPermissions
  implements UserRolePermissionFieldGetters
{
  private readonly user: UserDto<string, string> | null;
  private readonly userGroup: UserGroupDto<string, string> | null;
  private readonly combinedUserRole: CombinedUserRole;
  private readonly canEditInUserGroup: boolean;

  constructor({
    user,
    userGroup,
    combinedUserRole
  }: {
    user: UserDto<string, string> | null;
    userGroup: UserGroupDto<string, string> | null;
    combinedUserRole: CombinedUserRole;
  }) {
    this.user = user;
    this.userGroup = userGroup;
    this.combinedUserRole = combinedUserRole;
    this.canEditInUserGroup = this.calculateCanEditInUserGroup({
      user,
      userGroup
    });
  }

  public getCanEditInUserGroup(): boolean {
    return this.canEditInUserGroup;
  }

  public getControlEntityVisibilityWithAuthorizations(): boolean {
    return this.userGroup?.controlEntityVisibilityWithAuthorizations ?? false;
  }

  public getCanCreateEntity(entityName: EntityName): boolean {
    const roleInfo = entityNameToRoleInfo[entityName];

    if (!roleInfo.canCreateFieldName) {
      return this.canEditInUserGroup;
    }

    const functionName = `get${StringUtils.upperCaseFirstLetter(
      roleInfo.canCreateFieldName
    )}` as keyof UserRolePermissionFieldGetters;

    return this[functionName]();
  }

  public getCanCreateDefects(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canCreateDefects');
  }

  public getCanUpdateDefects(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canUpdateDefects');
  }

  public getCanDeleteDefects(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canDeleteDefects');
  }

  public getCanCreateEntries(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canCreateEntries');
  }

  public getCanUpdateEntries(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canUpdateEntries');
  }

  public getCanDeleteEntries(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canDeleteEntries');
  }

  public getCanCreateMapLayers(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canCreateMapLayers');
  }

  public getCanUpdateMapLayers(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canUpdateMapLayers');
  }

  public getCanDeleteMapLayers(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canDeleteMapLayers');
  }

  public getCanCreateProcessConfigurations(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessConfigurations'
    );
  }

  public getCanUpdateProcessConfigurations(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessConfigurations'
    );
  }

  public getCanDeleteProcessConfigurations(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessConfigurations'
    );
  }

  public getCanCreateProcessConfigurationActionStates(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessConfigurationActionStates'
    );
  }

  public getCanUpdateProcessConfigurationActionStates(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessConfigurationActionStates'
    );
  }

  public getCanDeleteProcessConfigurationActionStates(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessConfigurationActionStates'
    );
  }

  public getCanCreateProcessConfigurationAuthorizationTypes(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessConfigurationAuthorizationTypes'
    );
  }

  public getCanUpdateProcessConfigurationAuthorizationTypes(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessConfigurationAuthorizationTypes'
    );
  }

  public getCanDeleteProcessConfigurationAuthorizationTypes(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessConfigurationAuthorizationTypes'
    );
  }

  public getCanCreateProcessConfigurationCategories(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessConfigurationCategories'
    );
  }

  public getCanUpdateProcessConfigurationCategories(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessConfigurationCategories'
    );
  }

  public getCanDeleteProcessConfigurationCategories(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessConfigurationCategories'
    );
  }

  public getCanCreateProcessConfigurationDevices(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessConfigurationDevices'
    );
  }

  public getCanUpdateProcessConfigurationDevices(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessConfigurationDevices'
    );
  }

  public getCanDeleteProcessConfigurationDevices(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessConfigurationDevices'
    );
  }

  public getCanCreateProcessConfigurationDeviceExports(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessConfigurationDeviceExports'
    );
  }

  public getCanUpdateProcessConfigurationDeviceExports(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessConfigurationDeviceExports'
    );
  }

  public getCanDeleteProcessConfigurationDeviceExports(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessConfigurationDeviceExports'
    );
  }

  public getCanCreateProcessConfigurationFollowUpAppointments(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessConfigurationFollowUpAppointments'
    );
  }

  public getCanUpdateProcessConfigurationFollowUpAppointments(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessConfigurationFollowUpAppointments'
    );
  }

  public getCanDeleteProcessConfigurationFollowUpAppointments(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessConfigurationFollowUpAppointments'
    );
  }

  public getCanCreateProcessConfigurationForms(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessConfigurationForms'
    );
  }

  public getCanUpdateProcessConfigurationForms(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessConfigurationForms'
    );
  }

  public getCanDeleteProcessConfigurationForms(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessConfigurationForms'
    );
  }

  public getCanCreateProcessConfigurationFormSendTargets(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessConfigurationFormSendTargets'
    );
  }

  public getCanUpdateProcessConfigurationFormSendTargets(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessConfigurationFormSendTargets'
    );
  }

  public getCanDeleteProcessConfigurationFormSendTargets(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessConfigurationFormSendTargets'
    );
  }

  public getCanCreateProcessConfigurationMeasurePointTypes(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessConfigurationMeasurePointTypes'
    );
  }

  public getCanUpdateProcessConfigurationMeasurePointTypes(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessConfigurationMeasurePointTypes'
    );
  }

  public getCanDeleteProcessConfigurationMeasurePointTypes(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessConfigurationMeasurePointTypes'
    );
  }

  public getCanCreateProcessConfigurationSteps(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessConfigurationSteps'
    );
  }

  public getCanUpdateProcessConfigurationSteps(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessConfigurationSteps'
    );
  }

  public getCanDeleteProcessConfigurationSteps(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessConfigurationSteps'
    );
  }

  public getCanCreateProcessConfigurationStepAutoAppointments(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessConfigurationStepAutoAppointments'
    );
  }

  public getCanUpdateProcessConfigurationStepAutoAppointments(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessConfigurationStepAutoAppointments'
    );
  }

  public getCanDeleteProcessConfigurationStepAutoAppointments(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessConfigurationStepAutoAppointments'
    );
  }

  public getCanCreateProcessConfigurationStepAutoForms(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessConfigurationStepAutoForms'
    );
  }

  public getCanUpdateProcessConfigurationStepAutoForms(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessConfigurationStepAutoForms'
    );
  }

  public getCanDeleteProcessConfigurationStepAutoForms(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessConfigurationStepAutoForms'
    );
  }

  public getCanCreateProcessConfigurationStepPositions(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessConfigurationStepPositions'
    );
  }

  public getCanUpdateProcessConfigurationStepPositions(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessConfigurationStepPositions'
    );
  }

  public getCanDeleteProcessConfigurationStepPositions(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessConfigurationStepPositions'
    );
  }

  public getCanCreateProcessTasks(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canCreateProcessTasks');
  }

  public getCanUpdateProcessTasks(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canUpdateProcessTasks');
  }

  public getCanDeleteProcessTasks(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canDeleteProcessTasks');
  }

  public getCanCreateProcessTaskAppointments(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessTaskAppointments'
    );
  }

  public getCanUpdateProcessTaskAppointments(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessTaskAppointments'
    );
  }

  public getCanDeleteProcessTaskAppointments(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessTaskAppointments'
    );
  }

  public getCanCreateProcessTaskAppointmentsInFieldCalendar(): boolean {
    return this.hasRolePermission(
      'canCreateProcessTaskAppointmentsInFieldCalendar'
    );
  }

  public getCanCreateProcessTaskAppointmentContacts(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessTaskAppointmentContacts'
    );
  }

  public getCanUpdateProcessTaskAppointmentContacts(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessTaskAppointmentContacts'
    );
  }

  public getCanDeleteProcessTaskAppointmentContacts(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessTaskAppointmentContacts'
    );
  }

  public getCanCreateProcessTaskAppointmentToUsers(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessTaskAppointmentToUsers'
    );
  }

  public getCanUpdateProcessTaskAppointmentToUsers(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessTaskAppointmentToUsers'
    );
  }

  public getCanDeleteProcessTaskAppointmentToUsers(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessTaskAppointmentToUsers'
    );
  }

  public getCanCreateProcessTaskChecklistEntries(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessTaskChecklistEntries'
    );
  }

  public getCanUpdateProcessTaskChecklistEntries(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessTaskChecklistEntries'
    );
  }

  public getCanDeleteProcessTaskChecklistEntries(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessTaskChecklistEntries'
    );
  }

  public getCanCreateProcessTaskComments(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessTaskComments'
    );
  }

  public getCanUpdateProcessTaskComments(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessTaskComments'
    );
  }

  public getCanDeleteProcessTaskComments(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessTaskComments'
    );
  }

  public getCanCreateProcessTaskDevices(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessTaskDevices'
    );
  }

  public getCanUpdateProcessTaskDevices(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessTaskDevices'
    );
  }

  public getCanDeleteProcessTaskDevices(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessTaskDevices'
    );
  }

  public getCanCreateProcessTaskGroups(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessTaskGroups'
    );
  }

  public getCanUpdateProcessTaskGroups(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessTaskGroups'
    );
  }

  public getCanDeleteProcessTaskGroups(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessTaskGroups'
    );
  }

  public getCanCreateProcessTaskGroupAuthorizations(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessTaskGroupAuthorizations'
    );
  }

  public getCanUpdateProcessTaskGroupAuthorizations(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessTaskGroupAuthorizations'
    );
  }

  public getCanDeleteProcessTaskGroupAuthorizations(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessTaskGroupAuthorizations'
    );
  }

  public getCanCreateProcessTaskRecurringAppointments(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateProcessTaskRecurringAppointments'
    );
  }

  public getCanUpdateProcessTaskRecurringAppointments(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateProcessTaskRecurringAppointments'
    );
  }

  public getCanDeleteProcessTaskRecurringAppointments(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteProcessTaskRecurringAppointments'
    );
  }

  public getCanCreateProjects(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canCreateProjects');
  }

  public getCanUpdateProjects(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canUpdateProjects');
  }

  public getCanDeleteProjects(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canDeleteProjects');
  }

  public getCanCreateReports(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canCreateReports');
  }

  public getCanUpdateReports(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canUpdateReports');
  }

  public getCanDeleteReports(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canDeleteReports');
  }

  public getCanCreateSharepointListColumnToThingMappingItems(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateSharepointListColumnToThingMappingItems'
    );
  }

  public getCanUpdateSharepointListColumnToThingMappingItems(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateSharepointListColumnToThingMappingItems'
    );
  }

  public getCanDeleteSharepointListColumnToThingMappingItems(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteSharepointListColumnToThingMappingItems'
    );
  }

  public getCanCreateTextBricks(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canCreateTextBricks');
  }

  public getCanUpdateTextBricks(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canUpdateTextBricks');
  }

  public getCanDeleteTextBricks(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canDeleteTextBricks');
  }

  public getCanCreateTextBrickTemplates(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateTextBrickTemplates'
    );
  }

  public getCanUpdateTextBrickTemplates(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateTextBrickTemplates'
    );
  }

  public getCanDeleteTextBrickTemplates(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteTextBrickTemplates'
    );
  }

  public getCanCreateThings(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canCreateThings');
  }

  public getCanUpdateThings(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canUpdateThings');
  }

  public getCanDeleteThings(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canDeleteThings');
  }

  public getCanCreateThingAuthorizations(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateThingAuthorizations'
    );
  }

  public getCanUpdateThingAuthorizations(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateThingAuthorizations'
    );
  }

  public getCanDeleteThingAuthorizations(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteThingAuthorizations'
    );
  }

  public getCanCreateThingGroups(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canCreateThingGroups');
  }

  public getCanUpdateThingGroups(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canUpdateThingGroups');
  }

  public getCanDeleteThingGroups(): boolean {
    return this.canEditInUserGroupOrHasRolePermission('canDeleteThingGroups');
  }

  public getCanCreateThingToPersons(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canCreateThingToPersons'
    );
  }

  public getCanUpdateThingToPersons(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canUpdateThingToPersons'
    );
  }

  public getCanDeleteThingToPersons(): boolean {
    return this.canEditInUserGroupOrHasRolePermission(
      'canDeleteThingToPersons'
    );
  }

  public getCanCreateUserRoles(): boolean {
    return this.isAdminOrHasRolePermission('canCreateUserRoles');
  }

  public getCanUpdateUserRoles(): boolean {
    return this.isAdminOrHasRolePermission('canUpdateUserRoles');
  }

  public getCanDeleteUserRoles(): boolean {
    return this.isAdminOrHasRolePermission('canDeleteUserRoles');
  }

  public getCanCreateUserRoleToUsers(): boolean {
    return this.isAdminOrHasRolePermission('canCreateUserRoleToUsers');
  }

  public getCanUpdateUserRoleToUsers(): boolean {
    return this.isAdminOrHasRolePermission('canUpdateUserRoleToUsers');
  }

  public getCanDeleteUserRoleToUsers(): boolean {
    return this.isAdminOrHasRolePermission('canDeleteUserRoleToUsers');
  }

  public getCanCreateReportSignatures(): boolean {
    return this.hasRolePermission('canCreateReportSignatures');
  }

  public getCanSeeEntitiesWithoutProcessTaskGroupAuthorization(): boolean {
    return this.hasRolePermission(
      'canSeeEntitiesWithoutProcessTaskGroupAuthorization'
    );
  }

  public getCanSeeEntitiesWithoutThingAuthorization(): boolean {
    return this.hasRolePermission('canSeeEntitiesWithoutThingAuthorization');
  }

  public getCanSeePersonsWithoutExplicitAuthorization(): boolean {
    return this.hasRolePermission('canSeePersonsWithoutExplicitAuthorization');
  }

  private isAdminOrHasRolePermission(
    permissionName: keyof CombinedUserRole
  ): boolean {
    if (this.isAdminOrUserAdmin()) {
      return true;
    }

    return this.hasRolePermission(permissionName);
  }

  private canEditInUserGroupOrHasRolePermission(
    permissionName: keyof CombinedUserRole
  ): boolean {
    return this.canEditInUserGroup || this.hasRolePermission(permissionName);
  }

  private hasRolePermission(permissionName: keyof CombinedUserRole): boolean {
    return this.combinedUserRole[permissionName];
  }

  private isAdminOrUserAdmin(): boolean {
    if (!this.user) {
      return false;
    }

    return this.user.admin || !!this.user.permissions.canAdministerUsers;
  }

  private calculateCanEditInUserGroup({
    user,
    userGroup
  }: {
    user: UserDto<string, string> | null;
    userGroup: UserGroupDto<string, string> | null;
  }): boolean {
    if (!user || !userGroup) {
      return false;
    }

    const userSpec = userGroup.userSpecs.find((spec) => spec._id === user.id);
    if (!userSpec) {
      return false;
    }

    return userSpec.group_admin || userSpec.can_edit;
  }
}

export type RoleBasedPermissionsOptions = {
  user: UserDto<string, string> | null;
  userGroupsOfUser: Array<UserGroupDto<string, string>>;
  userRolesOfUserGroups: Array<UserRoleDto<string, string>>;
  userRoleToUsersOfUser: Array<UserRoleToUserDto<string, string>>;
};

export type UserRolePermissionFieldGetters = Record<
  `get${Capitalize<keyof UserRolePermissionFields>}`,
  () => boolean
>;

type UserGroupsById = Map<string, UserGroupDto<string, string>>;
type UserRolesByUserGroupId = Map<string, Array<UserRoleDto<string, string>>>;
type UserRoleToUsersOfUserByUserGroupId = Map<
  string,
  Array<UserRoleToUserDto<string, string>>
>;
