import { autoinject } from 'aurelia-framework';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../classes/EntityManager/entities/User/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { computed } from '../../hooks/computed';
import {
  activeUserCompanySetting,
  currentUser,
  expression,
  model
} from '../../hooks/dependencies';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';

@autoinject()
export class home_page {
  constructor(
    private readonly currentUserService: CurrentUserService,
    private readonly entityManager: AppEntityManager,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService
  ) {}

  @computed(currentUser())
  private get currentUser(): User | null {
    return this.currentUserService.getCurrentUser();
  }

  @computed(expression('currentUser'))
  protected get userHasNoAvailableModule(): boolean {
    if (!this.currentUser) return false;
    return (
      PermissionHelper.getAvailableModulesForUser(this.currentUser).length === 0
    );
  }

  @computed(
    expression('currentUser'),
    expression('currentUser.userCompanySettingId'),
    model(EntityName.UserCompanySetting)
  )
  protected get userHasLegacyModuleTiles(): boolean {
    if (!this.currentUser) return false;
    const currentCompanySetting = this.currentUser?.userCompanySettingId
      ? this.entityManager.userCompanySettingRepository.getById(
          this.currentUser.userCompanySettingId
        )
      : null;
    return currentCompanySetting
      ? currentCompanySetting.homePage.usesLegacyTileHomePage ?? false
      : false;
  }

  @computed(activeUserCompanySetting('homePage.showKukDefectShortcut'))
  protected get showKukDefectShortcut(): boolean {
    return this.activeUserCompanySettingService.getSettingProperty(
      'homePage.showKukDefectShortcut'
    );
  }
}
