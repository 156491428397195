import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DateUtils } from 'common/DateUtils';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskRecurringAppointment } from '../../classes/EntityManager/entities/ProcessTaskRecurringAppointment/types';
import {
  CorrespondingAppointmentsWidget,
  OnAppointmentSplitOffEvent
} from './corresponding-appointments-widget/corresponding-appointments-widget';
import { ProcessTaskRecurringAppointmentHelper } from 'common/EntityHelper/ProcessTaskRecurringAppointmentHelper';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

/**
 * @event on-appointment-split-off triggered whenever the user creates a new appointment through the corresponding-appointments-widget
 */
@autoinject()
export class EditProcessTaskRecurringAppointmentWidget {
  @bindable public appointment: ProcessTaskRecurringAppointment | null = null;

  @subscribableLifecycle()
  protected readonly appointmentPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskRecurringAppointment];

  protected appointmentEndTime: Date | null = null;

  protected correspondingAppointmentsWidget: CorrespondingAppointmentsWidget | null =
    null;

  protected processConfigurationId: string | null = null;

  private element: HTMLElement;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.element = element as HTMLElement;

    this.appointmentPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskRecurringAppointment,
        context: this as EditProcessTaskRecurringAppointmentWidget,
        propertyName: 'appointment'
      });
  }

  public setSelectedDate(date: Date): void {
    this.correspondingAppointmentsWidget?.setSelectedDate(date);
  }

  protected appointmentChanged(): void {
    if (!this.appointment) {
      this.appointmentEndTime = null;
      this.processConfigurationId = null;
      return;
    }

    this.appointmentEndTime = ProcessTaskRecurringAppointmentHelper.getEndTime(
      this.appointment
    );
    const ownerProcessTaskGroup = this.appointment.ownerProcessTaskGroupId
      ? this.entityManager.processTaskGroupRepository.getById(
          this.appointment.ownerProcessTaskGroupId
        )
      : null;
    this.processConfigurationId = ownerProcessTaskGroup
      ? this.entityManager.processConfigurationRepository.getById(
          ownerProcessTaskGroup.processConfigurationId
        )?.id ?? null
      : null;
  }

  protected handleAppointmentChanged(): void {
    assertNotNullOrUndefined(
      this.appointment,
      'cannot handleAppointmentChanged without an appointment'
    );

    if (this.appointment.startTime) {
      // Set the end time for convenience if the start date has just been set
      if (!this.appointmentEndTime) {
        this.appointmentEndTime = DateUtils.getDateWithHourOffset(
          this.appointment.startTime
        );
      }

      // Calculate the durationInMs from the end time
      const durationInMs = DateUtils.getDurationInMillisecondsBetween(
        this.appointment.startTime,
        this.appointmentEndTime
      );
      if (durationInMs > 0) {
        this.appointment.durationInMs = durationInMs;
      }
    }

    this.entityManager.processTaskRecurringAppointmentRepository.update(
      this.appointment
    );
  }

  protected handleAppointmentSplitOff(event: OnAppointmentSplitOffEvent): void {
    DomEventHelper.fireEvent<OnAppointmentSplitOffEvent>(this.element, {
      name: 'on-appointment-split-off',
      detail: { processTaskAppointment: event.detail.processTaskAppointment }
    });
  }

  @computedFrom(
    'appointment.dateFrom',
    'appointment.startTime',
    'appointment.durationInMs',
    'appointment.recurrence'
  )
  protected get shouldDisplayCorrespondingAppointments(): boolean {
    return (
      !!this.appointment?.dateFrom &&
      !!this.appointment.startTime &&
      !!this.appointment.durationInMs &&
      !!this.appointment.recurrence
    );
  }
}
