import {
  bindable,
  observable,
  autoinject,
  computedFrom
} from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { CreatePersonDialog } from '../create-person-dialog/create-person-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';
import { PersonToPerson } from '../../classes/EntityManager/entities/PersonToPerson/types';
import { OriginalIdUtils } from '../../classes/EntityManager/utils/OriginalIdUtils/OriginalIdUtils';

/**
 * Dropdown list for choosing a person. Can optionally be restricted to a user group.
 *
 * @event {TValueChangedEvent} value-changed
 */
@autoinject()
export class PersonSelect {
  @bindable public value: string | null = null;

  @bindable public selectedPerson: Person | null = null;

  @bindable public enabled: boolean = false;

  /**
   * Limit the persons to select via the user group.
   */
  @bindable public userGroupId: string | null = null;

  /**
   * The string to display if no value is selected.
   */
  @bindable public nullOption: string | null = null;

  /**
   * The label to display for the dropdown.
   * Is set by default.
   */
  @bindable public label: string | null = null;

  /**
   * If set to true, the label will not be displayed
   * & the dropdown will take up the full width.
   */
  @bindable public noLabel: boolean = false;

  /**
   * Offers a selection of persons which are related to the personId (and the personId itself).
   * Also displays the name of the relation at the end of the name in the select element.
   */
  @bindable public relatedToPersonId: string | null = null;

  /**
   * Array of person ids to exclude from the selection.
   */
  @bindable public excludedPersonIds: Array<string> = [];

  @bindable public filterByCategoryName: string | null = null;

  @bindable public allowCreation = false;

  @observable protected selectedPersonOption: ISelectablePerson | null;

  protected selectablePersons: Array<ISelectablePerson> = [];

  private readonly subscriptionManager: SubscriptionManager;

  private isAttached = false;

  private readonly element: HTMLElement;

  protected boundCreatePersonCallback = this.createPersonCallback.bind(this);

  constructor(
    element: Element,
    subManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager,
    private readonly i18n: I18N
  ) {
    this.element = element as HTMLElement;
    this.subscriptionManager = subManagerService.create();
    this.selectedPersonOption = null;
  }

  // /////////// LIFECYCLE /////////////

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Person,
      this.updateSelectablePersons.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PersonToPerson,
      () => this.relatedToPersonId && this.updateSelectablePersons()
    );

    this.subscriptionManager.addDisposable(
      this.entityManager.entitySynchronization.registerEntitySpecificEntityIdUpgradedHook(
        EntityName.Person,
        this.handlePersonIdUpdated.bind(this)
      )
    );

    this.updateSelectablePersons();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  // /////////// METHODS /////////////

  private updateSelectablePersonsIfAttached(): void {
    if (this.isAttached) {
      this.updateSelectablePersons();
    }
  }

  private getDisplayNameForPerson(person: Person): string {
    const parts: Array<string | null> = [
      PersonUtils.getPersonDisplayNameForPerson(person)
    ];

    const personIdToRelationMap = this.personIdToRelationMap;
    const personToPerson = personIdToRelationMap?.[person.id];
    if (personToPerson) {
      parts.push(personToPerson.name);
    }

    return parts.join(' - ');
  }

  // /////////// OBSERVABLES /////////////

  protected relatedToPersonIdChanged(): void {
    this.updateSelectablePersonsIfAttached();
  }

  protected userGroupIdChanged(): void {
    this.updateSelectablePersonsIfAttached();
  }

  protected excludedPersonIdsChanged(): void {
    this.updateSelectablePersonsIfAttached();
  }

  protected filterByCategoryNameChanged(): void {
    this.updateSelectablePersonsIfAttached();
  }

  protected selectedPersonOptionChanged(): void {
    this.selectedPerson = this.selectedPersonOption?.person ?? null;
  }

  // /////////// UPDATERS /////////////

  /**
   * Updates selectable persons by retrieving all available persons
   * and mapping them to selectablePersons.
   */
  private updateSelectablePersons(): void {
    this.selectablePersons = this.availablePersons
      .map((person) => ({
        displayName: this.getDisplayNameForPerson(person),
        personId: person.id,
        person: person
      }))
      .sort((a, b) => a.displayName.localeCompare(b.displayName));
  }

  // /////////// EVENT HANDLERS /////////////

  private handlePersonIdUpdated(person: Person): void {
    const originalIds = OriginalIdUtils.getOriginalIdsForEntity(person);
    if (this.value && originalIds.indexOf(this.value) >= 0) {
      this.updateSelectablePersons();
      this.value = person.id;
      this.selectedPerson = person;
      this.fireValueChangedEvent();
    }
  }

  protected handleSelectChanged(): void {
    this.fireValueChangedEvent();
  }

  private createPersonCallback(text: string): void {
    if (!this.allowCreation || !this.userGroupId) return;

    const personData = this.parseNewPersonInput(text);
    void CreatePersonDialog.open({
      userGroupId: this.userGroupId,
      personToCreate: {
        ownerUserGroupId: this.userGroupId,
        firstName: personData.firstName,
        lastName: personData.lastName
      },
      onDialogClosed: (person: Person | null) => {
        if (person) {
          this.value = person.id;
          setTimeout(() => {
            this.fireValueChangedEvent();
          }, 0);
        }
      }
    });
  }

  private parseNewPersonInput(userInput: string): {
    firstName: string;
    lastName: string;
  } {
    let firstName = '';
    let lastName = '';
    const parts = userInput.split(' ');

    if (parts.length > 1) {
      firstName = parts.slice(0, -1).join(' ');
      lastName = parts[parts.length - 1]!;
    }
    if (parts.length === 1) {
      firstName = parts[0]!;
    }

    return {
      firstName: firstName,
      lastName: lastName
    };
  }

  // /////////// GETTERS /////////////

  /**
   * Returns persons available for selection
   */
  private get availablePersons(): Array<Person> {
    const personIdToRelationMap = this.personIdToRelationMap;
    return this.entityManager.personRepository
      .getAll()
      .filter((person) => {
        // If personIdToRelationMap is defined, only return persons which are in it
        if (personIdToRelationMap) {
          return (
            personIdToRelationMap.hasOwnProperty(person.id) ||
            person.id === this.relatedToPersonId
          );
        } else return true;
      })
      .filter((person) => {
        // If userGroupId is defined, only return persons who belong to this group
        if (this.userGroupId) {
          return person.ownerUserGroupId === this.userGroupId;
        } else return true;
      })
      .filter((person) => {
        // If person ids are set as excluded, don't return them
        return !this.excludedPersonIds.includes(person.id);
      })
      .filter((person) => {
        // If filterByCategoryName is defined, only return persons who belong to this category
        if (this.filterByCategoryName) {
          return this.filterByCategoryName === person.categoryName;
        } else return true;
      });
  }

  /**
   * A map with relations from one main person to other persons.
   * Computed from the personToPerson relations retrieved from the PersonToPersonService.
   * Returns null if relatedToPersonId is not given.
   */
  private get personIdToRelationMap(): IPersonIdToRelationMap | null {
    if (!this.relatedToPersonId) return null;

    const personToPersons =
      this.entityManager.personToPersonRepository.getByPersonId(
        this.relatedToPersonId
      );

    const relationMap: IPersonIdToRelationMap = {};

    personToPersons.forEach((personToPerson) => {
      const otherPersonId =
        personToPerson.person1Id === this.relatedToPersonId
          ? personToPerson.person2Id
          : personToPerson.person1Id;
      relationMap[otherPersonId] = personToPerson;
    });

    return relationMap;
  }

  @computedFrom('nullOption')
  protected get nullOptionForCustomSelect(): string {
    return this.nullOption
      ? this.nullOption
      : this.i18n.tr('personComponents.personSelect.nullValue');
  }

  private fireValueChangedEvent(): void {
    DomEventHelper.fireEvent<TValueChangedEvent>(this.element, {
      name: 'value-changed',
      detail: {
        value: this.value,
        selectedPerson: this.selectedPerson
      }
    });
  }
}

interface ISelectablePerson {
  displayName: string;
  personId: string;
  person: Person;
}

interface IPersonIdToRelationMap {
  [s: string]: PersonToPerson;
}

export type TValueChangedEvent = NamedCustomEvent<
  'value-changed',
  { value: string | null; selectedPerson: Person | null }
>;
