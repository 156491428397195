import {
  CoordsFromPositionedPictureInfo,
  IPictureCoords,
  IPictureLocationInfo
} from 'common/Types/Entities/Picture/PictureDto';
import { Vector } from 'common/Geometry/Vector';

import { AppEntityManager } from '../EntityManager/entities/AppEntityManager';
import { CoordinateHelper } from '../CoordinateHelper';

export class GalleryThingPictureCreatorUtils {
  public static getCoordsFromPositionedPictureInfo(
    entityManager: AppEntityManager,
    coords: IPictureCoords,
    thingId: string
  ): CoordsFromPositionedPictureInfo | null {
    const galleryThingOverviewPicture = entityManager.pictureRepository
      .getByGalleryThingId(thingId)
      .find((x) => x.selected);
    if (
      !galleryThingOverviewPicture ||
      !galleryThingOverviewPicture.location_info
    )
      return null;

    const relativePosition = this.getRelativePositionOfCoordsToOverviewPicture(
      coords,
      galleryThingOverviewPicture.location_info
    );
    if (!relativePosition) return null;

    const x = relativePosition.getX() * 100;
    const y = relativePosition.getY() * 100;

    // We cannot auto-set the marking if the position given by coords is not displayable on overview image
    if (x < 0 || x > 100) return null;
    if (y < 0 || y > 100) return null;

    return {
      top: `${y}%`,
      left: `${x}%`,
      pictureId: galleryThingOverviewPicture.id
    };
  }

  /**
   * @returns {Vector|null} - vector in percent relative to the picture
   */
  public static getRelativePositionOfCoordsToOverviewPicture(
    coordinates: IPictureCoords,
    locationInfo: IPictureLocationInfo
  ): Vector | null {
    if (
      !coordinates.longitude ||
      !coordinates.latitude ||
      !locationInfo.topLeftPosition
    ) {
      return null;
    }

    // calculate all the necessary vectors as distance vectors (since you can't rotate latLong)
    const deltaWidth = CoordinateHelper.createDeltaVectorForLatitudeLongitude(
      locationInfo.topRightPosition,
      locationInfo.topLeftPosition
    );

    const deltaHeight = CoordinateHelper.createDeltaVectorForLatitudeLongitude(
      locationInfo.bottomLeftPosition,
      locationInfo.topLeftPosition
    );

    const deltaCoords = CoordinateHelper.createDeltaVectorForLatitudeLongitude(
      { latitude: coordinates.latitude, longitude: coordinates.longitude },
      locationInfo.topLeftPosition
    );

    // rotate everything so the width aligns with the x-axis
    // this makes it easier so we only need to skew everything along the x-axis instead of x and y
    // we need to skew this, since rotated rectangles end up as being a parallelogram (don't ask me why), but we are only displaying a rectangle
    // fixing that with skewing is easier than "rotating" it into the correct position, but this will fuck up the distances
    // since we don't need any actual distance, but only relative values, we don't care ¯\_(ツ)_/¯
    const rotationAngle = (deltaWidth.getAngle() ?? 0) * -1;
    deltaWidth.rotate(rotationAngle);
    deltaHeight.rotate(rotationAngle);
    deltaCoords.rotate(rotationAngle);

    // skew all the x coordinates depending on the y coordinate (basically this is just a linear function)
    const xSkewK = deltaHeight.getX() / deltaHeight.getY();
    deltaHeight.setX(deltaHeight.getX() - deltaHeight.getY() * xSkewK);
    deltaCoords.setX(deltaCoords.getX() - deltaCoords.getY() * xSkewK);

    return new Vector(
      deltaCoords.getX() / deltaWidth.getLength(),
      (deltaCoords.getY() * -1) / deltaHeight.getLength()
    );
  }
}
