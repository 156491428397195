import { bindable, autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { PersonContactType } from 'common/Types/Entities/PersonContact/PersonContactDto';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { Dialogs } from '../../classes/Dialogs';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { PersonContact } from '../../classes/EntityManager/entities/PersonContact/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

/**
 * @techdebt [REC-2997] this widget currently uses the permissionsHandle.canManageContacts permission to control the permission to the contacts.
 *  Ideally permissions should be handled on the PersonContact level though. That would require to extract the content for a contact into it's own component.
 */
@autoinject()
export class EditPersonContactsWidget {
  @bindable()
  public person: Person | null = null;

  /**
   * if this is set, then all entities will be created with a temporaryGroupName + shadowEntity = true
   */
  @bindable()
  public temporaryGroupName: string | null = null;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.Person];

  private readonly subscriptionManager: SubscriptionManager;

  protected readonly contactTypeDefinitions: Array<PersonContactOption> = [
    {
      contactType: PersonContactType.PHONE,
      labelTk: 'modelsDetail.PersonContactModel.phone'
    },
    {
      contactType: PersonContactType.EMAIL,
      labelTk: 'modelsDetail.PersonContactModel.email'
    }
  ];

  private isAttached: boolean = false;
  protected availablePersonContacts: Array<PersonContact> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.permissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Person,
        context: this,
        expression: 'person'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PersonContact,
      this.updateAvailablePersonContacts.bind(this)
    );
    this.updateAvailablePersonContacts();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected personChanged(): void {
    if (this.isAttached) {
      this.updateAvailablePersonContacts();
    }
  }

  private updateAvailablePersonContacts(): void {
    if (this.person) {
      this.availablePersonContacts =
        this.entityManager.personContactRepository.getByPersonId(
          this.person.id
        );
    } else {
      this.availablePersonContacts = [];
    }
  }

  protected handleAddContactClick(): void {
    assertNotNullOrUndefined(
      this.person,
      "can't EditPersonContactsWidget.handleAddContactClick without person"
    );

    const contact = this.entityManager.personContactRepository.create({
      personId: this.person.id,
      ownerUserGroupId: this.person.ownerUserGroupId,
      temporaryGroupName: this.person.temporaryGroupName,
      shadowEntity: this.person.shadowEntity
    });

    this.updateAvailablePersonContacts();
    this.goToContact(contact);
  }

  protected handleDeleteContactClick(contact: PersonContact): void {
    void Dialogs.deleteEntityDialog(contact).then(() => {
      this.entityManager.personContactRepository.delete(contact);
    });
  }

  protected handleContactChanged(contact: PersonContact): void {
    this.entityManager.personContactRepository.update(contact);
  }

  private goToContact(contact: PersonContact): void {
    void ScrollHelper.autoScrollToSubtleListItem(
      '#' + this.getContactElementId(contact.id)
    );
  }

  private getContactElementId(contactId: string): string {
    return 'edit-person-contacts-widget--contact-' + contactId;
  }
}

type PersonContactOption = {
  contactType: PersonContactType;
  labelTk: string;
};
