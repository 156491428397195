import { bindable } from 'aurelia-framework';

/**
 * A simple aurelia component to switch between pages of an element (like tabs)
 * The elements inside the components have to have a 'data-page-name' attribute which contains the name/identifier of the page
 *
 * Example:
 *  <page-switcher view-model.ref="page-switcher">
 *    <div data-page-name="overview"> ...content... </div>
 *    <div data-page-name="detail"> ...content... </div>
 *  </page-switcher>
 *
 *
 * Example for dynamic/generated content:
 *  <page-switcher view-model.ref="page-switcher"
 *                 intermediate-container.one-way="true">
 *    <div>
 *      <div repeat.for="definition of pageDefinitions"
 *           data-page-name="${definition.pageName}">
 *           ${definition.contentText}
 *      </div>
 *    </div>
 *  </page-switcher>
 */
export class PageSwitcher {
  /**
   * determines if the content has an intermediateContainer
   * this is needed to support dynamic pages, since we need an intermediate container to prevent a memory leak
   * see https://github.com/aurelia/templating-resources/issues/310
   */
  @bindable public intermediateContainer = false;

  @bindable public currentPageName: string | null = null;

  private contentContainer: HTMLElement | null = null;

  private pageElements: Array<HTMLElement> = [];
  private activePageElement: HTMLElement | null = null;

  /**
   * this is needed so we can differentiate between an external and internal change
   */
  private ignoreCurrentPageNameChange: boolean = false;

  protected attached(): void {
    this.updatePageElements();

    if (this.pageElements[0]) {
      this.switchToPage(this.getPageNameFromElement(this.pageElements[0]));
    }
  }

  /**
   * switch to pageName, if pageName is not found, switch to first page
   */
  public switchToPage(pageName: string | null): void {
    const newActivePageElement = this.getPageElementForNameOrDefault(pageName);

    if (newActivePageElement) {
      newActivePageElement.style.display = '';
    }

    if (
      this.activePageElement &&
      this.activePageElement !== newActivePageElement
    ) {
      this.activePageElement.style.display = 'none';
    }

    this.activePageElement = newActivePageElement;
    this.ignoreCurrentPageNameChange = true;
    this.currentPageName = pageName;
    this.ignoreCurrentPageNameChange = false;
  }

  /**
   * call this everytime your dynamic pages have been changed/updated
   */
  public pageElementsChanged(): void {
    this.updatePageElements();
    if (
      this.activePageElement &&
      this.pageElements.indexOf(this.activePageElement) < 0
    ) {
      if (this.activePageElement) {
        this.switchToPage(this.getPageNameFromElement(this.activePageElement));
      } else {
        this.switchToPage(null);
      }
    }
  }

  protected currentPageNameChanged(): void {
    if (!this.ignoreCurrentPageNameChange) {
      this.switchToPage(this.currentPageName);
    }
  }

  private updatePageElements(): void {
    const pageElements: Array<HTMLElement> = [];

    const children = this.getChildElements();
    children.forEach((child) => {
      if (child.getAttribute('data-page-name')) {
        pageElements.push(child);
      }

      if (child !== this.activePageElement) {
        child.style.display = 'none';
      }
    });

    this.pageElements = pageElements;
  }

  private getChildElements(): Array<HTMLElement> {
    let container = this.contentContainer;
    if (this.intermediateContainer && this.contentContainer) {
      container = this.contentContainer.children[0] as HTMLElement | null;
    }

    // convert the HTMLCollection to an array
    const children = container?.children ?? [];
    return Array.from(children) as Array<HTMLElement>;
  }

  private getPageElementForNameOrDefault(
    pageName: string | null
  ): HTMLElement | null {
    const pageElement = pageName ? this.getPageElementForName(pageName) : null;
    return pageElement ? pageElement : this.pageElements[0] ?? null;
  }

  private getPageElementForName(pageName: string): HTMLElement | null {
    return (
      this.pageElements.find((page) => {
        return this.getPageNameFromElement(page) === pageName;
      }) ?? null
    );
  }

  private getPageNameFromElement(pageElement: HTMLElement): string | null {
    return pageElement.getAttribute('data-page-name');
  }
}
