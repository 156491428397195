import expressions, {
  CompileFuncOptions,
  ParserOptions
} from 'angular-expressions';

export class ExprEvalParser {
  constructor() {
    // keep for setup filters in the future
  }

  public evaluateExpression(expr: string, scope: Record<string, any>): any {
    const evaluateFunction = expressions.compile(expr, {
      csp: true
    } as FullCompileFuncOptions);

    return evaluateFunction(scope);
  }

  public evaluateStringWithExpressions({
    template,
    context
  }: {
    template: string;
    context: Record<string, any>;
  }): string {
    const templateParts = template.split(/({{[\w\d\s\."'\[\]äüö]*}})/g);

    const result = templateParts.map((part) => {
      if (part.startsWith('{{')) {
        return this.evaluateExpression(part.slice(2, -2), context);
      } else {
        return part;
      }
    });

    return result.join('');
  }
}

type FullCompileFuncOptions = CompileFuncOptions & ParserOptions;
