import { inject, bindable } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EntityName } from '../../classes/EntityManager/entities/types';

/**
 * @event value-changed
 */
@inject(AppEntityManager, SubscriptionManagerService)
export class ReportTypeSelect {
  /** @type {string|null} */
  @bindable selectedReportTypeId = null;

  @bindable enabled = false;
  @bindable label = '';
  @bindable noLabel = false;

  /** @type {HTMLElement|null} */
  _domElement = null;

  /** @type {Array<import('../../classes/EntityManager/entities/ReportType/types').ReportType>} */
  _availableReportTypes = [];

  /**
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(entityManager, subscriptionManagerService) {
    this._entityManager = entityManager;

    this._subscriptionManager = subscriptionManagerService.create();
  }

  attached() {
    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ReportType,
      this._updateAvailableReportTypes.bind(this)
    );
    this._updateAvailableReportTypes();
  }

  detached() {
    this._subscriptionManager.disposeSubscriptions();
  }

  _updateAvailableReportTypes() {
    this._availableReportTypes =
      this._entityManager.reportTypeRepository.getAll();
  }

  _handleSelectChanged() {
    if (!this._domElement) return;
    DomEventHelper.fireEvent(this._domElement, {
      name: 'value-changed',
      detail: null
    });
  }
}
