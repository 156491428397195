import { Binding, bindingMode } from 'aurelia-binding';
import { Observable, OperatorFunction, Subscription } from 'rxjs';

export class ObservableBindingBehavior {
  public bind(
    binding: ObservableBinding,
    _source: unknown,
    operator?: OperatorFunction<unknown, unknown>
  ): void {
    if (binding.mode !== bindingMode.toView) {
      throw new Error(
        'only the to-view binding mode is supported by the ObservableBindingBehavior'
      );
    }

    if (!binding.updateTarget) {
      return;
    }

    const state: ObservableState = {
      originalUpdateTarget: binding.updateTarget,
      subscription: null
    };
    binding.observableState = state;

    binding.updateTarget = this.createUpdateTargetOverride({
      binding,
      state,
      operator
    });
  }

  public unbind(binding: ObservableBinding): void {
    if (!binding.observableState) {
      return;
    }

    binding.updateTarget = binding.observableState.originalUpdateTarget;
    binding.observableState.subscription?.unsubscribe();

    delete binding.observableState;
  }

  private createUpdateTargetOverride({
    binding,
    state,
    operator
  }: {
    binding: Binding;
    state: ObservableState;
    operator: OperatorFunction<unknown, unknown> | undefined;
  }): (observable: any) => void {
    return (observable) => {
      state.subscription?.unsubscribe();

      if (observable == null) {
        return;
      }

      if (!(observable instanceof Observable)) {
        throw new Error(
          `observable only supports values of type Observable, but got ${typeof observable} ${observable
            .constructor?.name}`
        );
      }

      const observableWithOperator = operator
        ? observable.pipe(operator)
        : observable;
      state.subscription = observableWithOperator.subscribe((value) => {
        state.originalUpdateTarget.call(binding, value);
      });
    };
  }
}

type ObservableBinding = Binding & {
  observableState?: ObservableState;
};

type ObservableState = {
  originalUpdateTarget: Required<Binding>['updateTarget'];
  subscription: Subscription | null;
};
