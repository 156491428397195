import { autoinject, bindable } from 'aurelia-framework';
import { MomentInput } from 'moment';

import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { DateUtils } from 'common/DateUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { Dialogs } from '../../classes/Dialogs';
import { NotificationHelper } from '../../classes/NotificationHelper';
import { CoordinateHelper } from '../../classes/CoordinateHelper';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { ProjectActionService } from '../../classes/EntityManager/entities/Project/ProjectActionService';
import { ProjectEntityDashboardInfo } from '../../classes/EntityManager/entities/EntityDashboardInfo/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { computed } from '../../hooks/computed';
import { currentUser, expression, model } from '../../hooks/dependencies';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { ProjectValueCalculationResultAdapter } from '../../valueCalculationComponents/ValueCalculationResultAdapter/ProjectValueCalculationResultAdapter';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

/**
 * @event enter-project-clicked
 * @event edit-project-clicked
 * @event show-project-properties-clicked
 * @event export-project-clicked
 */
@autoinject()
export class ProjectListItem {
  @bindable public project: Project | null = null;

  private subscriptionManager: SubscriptionManager;

  protected panelOpen = false;

  protected projectIsJoined = false;

  private domElement: Element;

  protected listItemElement: HTMLElement | null = null;

  protected valueCalculationEntityListItemAdapter: ProjectValueCalculationResultAdapter | null =
    null;

  @subscribableLifecycle()
  protected projectPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Project];

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly projectActionService: ProjectActionService,
    private readonly currentUserService: CurrentUserService,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.domElement = element;

    this.subscriptionManager = subscriptionManagerService.create();
    this.projectPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Project,
        context: this,
        expression: 'project'
      });
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToProjectJoinedChanged(
      (projectId: string) => {
        if (this.project && this.project.id === projectId)
          this.updateProjectIsJoined();
      }
    );
    this.updateValueCalculationEntityListItemAdapter();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
    this.valueCalculationEntityListItemAdapter = null;
  }

  @computed(expression('project'), model(EntityName.EntityDashboardInfo))
  protected get dashboardInfo(): ProjectEntityDashboardInfo | null {
    if (!this.project) return null;
    return (
      this.entityManager.entityDashboardInfoRepository.getByProjectId(
        this.project.id
      )[0] ?? null
    );
  }

  @computed(
    currentUser(),
    expression('currentUser.userCompanySettingId'),
    model(EntityName.UserCompanySetting)
  )
  protected get userHasLegacyModuleTiles(): boolean {
    const user = this.currentUserService.getCurrentUser();
    if (!user) return false;
    const currentCompanySetting = user.userCompanySettingId
      ? this.entityManager.userCompanySettingRepository.getById(
          user.userCompanySettingId
        )
      : null;
    return currentCompanySetting
      ? currentCompanySetting.homePage.usesLegacyTileHomePage ?? false
      : false;
  }

  public highlight(): void {
    if (this.listItemElement)
      EntityListItemHelper.highlightListItemElement(this.listItemElement);
  }

  protected projectChanged(): void {
    this.updateProjectIsJoined();
    this.updateValueCalculationEntityListItemAdapter();
  }

  private updateProjectIsJoined(): void {
    if (this.project) {
      this.projectIsJoined =
        this.entityManager.joinedProjectsManager.projectIsJoined(
          this.project.id
        );
    } else {
      this.projectIsJoined = false;
    }
  }

  protected getPictureOfProject(project: Project): Picture | null {
    return this.entityManager.pictureRepository.getSelectedProjectTitlePicture(
      project.id
    );
  }

  protected handleNavigateToProjectClick(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'enter-project-clicked',
      detail: null
    });
  }

  protected handleMoreButtonClick(): void {
    this.panelOpen = !this.panelOpen;
  }

  private handleProjectChanged(): void {
    assertNotNullOrUndefined(
      this.project,
      "can't update project without a project"
    );
    this.entityManager.projectRepository.update(this.project);
  }

  protected handleEditProjectClick(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'edit-project-clicked',
      detail: null
    });
  }

  protected handleDeleteProjectClick(project: Project): void {
    void Dialogs.deleteEntityDialog(project).then(() => {
      this.entityManager.projectRepository.delete(project);
    });
  }

  protected handleUpdateProjectCoordinatesClick(): void {
    assertNotNullOrUndefined(
      this.project,
      'cannot update project coordinates without a project'
    );

    const clientCoordinates = CoordinateHelper.getClientCoordinates();

    if (clientCoordinates.latitude == null) {
      NotificationHelper.notifyDanger('Position konnte nicht bestimmt werden!');
      return;
    }

    this.project.latitude = clientCoordinates.latitude;
    this.project.longitude = clientCoordinates.longitude;

    this.handleProjectChanged();
  }

  protected handleCopyProjectClick(project: Project): void {
    assertNotNullOrUndefined(this.project, 'no project available');

    this.projectActionService.copyProject(project);
  }

  protected async handleToggleProjectJoinedClick(
    project: Project
  ): Promise<void> {
    const projectIsJoined =
      this.entityManager.joinedProjectsManager.projectIsJoined(project.id);
    if (!projectIsJoined) {
      await this.entityManager.joinedProjectsManager.joinProject(project.id);
    } else {
      await this.entityManager.joinedProjectsManager.leaveProject(project.id);
    }
  }

  protected handleToggleHideOnHomepageStatus(): void {
    assertNotNullOrUndefined(
      this.project,
      "can't ProjectListItem.handleToggleArchivedStatus without project"
    );

    this.projectActionService.toggleHideStatusOnHomepage(this.project);
  }

  protected handleToggleArchivedStatus(): void {
    assertNotNullOrUndefined(
      this.project,
      "can't toggle archived status without a project"
    );

    this.projectActionService.toggleArchivedStatusOfProject(this.project);
  }

  protected handleShowProjectPropertiesClicked(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'show-project-properties-clicked',
      detail: null
    });
  }

  protected handleExportProjectClicked(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'export-project-clicked',
      detail: null
    });
  }

  protected formatToDate(time: MomentInput): string {
    return DateUtils.formatToDateString(time);
  }

  protected formatToTime(time: MomentInput): string {
    return DateUtils.formatToTimeString(time);
  }

  protected get projectTypeColor(): string {
    switch (this.project?.projectType) {
      case ProjectType.B1300:
        return 'var(--record-it-color-module-b1300)';

      case ProjectType.INSPECT:
        return 'var(--record-it-color-module-inspect)';

      case ProjectType.BASIC:
      default:
        return 'var(--record-it-color-module-basic)';
    }
  }

  private updateValueCalculationEntityListItemAdapter(): void {
    this.valueCalculationEntityListItemAdapter = null;
    if (this.project) {
      this.valueCalculationEntityListItemAdapter =
        new ProjectValueCalculationResultAdapter({
          entityManager: this.entityManager,
          subscriptionManagerService: this.subscriptionManagerService,
          baseEntity: this.project
        });
    }
  }
}
