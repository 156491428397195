import { autoinject, bindable } from 'aurelia-framework';

import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { EditPersonDialog } from '../../personComponents/edit-person-dialog/edit-person-dialog';
import { CreatePersonDialog } from '../../personComponents/create-person-dialog/create-person-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { Property } from '../../classes/EntityManager/entities/Property/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { CheckedChangedEvent } from '../expandable-container/expandable-container';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { ComputedValueService } from '../../computedValues/ComputedValueService';

/**
 * @event thing-group-changed
 */
@autoinject()
export class ThingGroupFields {
  @bindable()
  public thingGroup: ThingGroup | null = null;

  /**
   * if this is set, then all sub entities will be created with a temporaryGroupName + shadowEntity = true
   */
  @bindable()
  public temporaryGroupName: string | null = null;

  /**
   * if no thingGroup is given and the user edits a field (e.g. the name), then this callback will be called to create a new thingGroup (if possible)
   */
  @bindable()
  public createThingGroupCallback: (() => ThingGroup | null) | null = null;

  private readonly subscriptionManager: SubscriptionManager;
  private properties: Array<Property> = [];
  private filterOwnerPersonByCategoryName: string | null = null;

  @subscribableLifecycle()
  protected permissionsHandle: EntityNameToPermissionsHandle[EntityName.ThingGroup];

  @subscribableLifecycle()
  protected userGroupPermissionsHandle: EntityNameToPermissionsHandle[EntityName.UserGroup];

  protected sharepointSynchronizationOfThingsEnabled = false;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.permissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ThingGroup,
        context: this,
        expression: 'thingGroup'
      });

    this.userGroupPermissionsHandle =
      permissionsService.getPermissionsHandleForEntityIdOfPropertyValue({
        entityName: EntityName.UserGroup,
        context: this as ThingGroupFields,
        propertyName: 'thingGroup',
        idPropertyName: 'ownerUserGroupId'
      });
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateProperties.bind(this)
    );
    this.updateProperties();

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'general.thingGroupOwnerPersonCategory',
        (thingGroupOwnerPersonCategory) => {
          this.filterOwnerPersonByCategoryName = thingGroupOwnerPersonCategory;
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'externalSynchronization.sharepointSynchronizationOfThings',
        (enableSharepointSynchronizationOfThings) => {
          this.sharepointSynchronizationOfThingsEnabled =
            enableSharepointSynchronizationOfThings;
        }
      )
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private thingGroupChanged(): void {
    this.updateProperties();
  }

  private updateProperties(): void {
    if (this.thingGroup) {
      this.properties = this.entityManager.propertyRepository.getByThingGroupId(
        this.thingGroup.id
      );
    } else {
      this.properties = [];
    }
  }

  private handleEditPersonClick(personId: string): void {
    const person = this.entityManager.personRepository.getById(personId);

    if (person) {
      void EditPersonDialog.open({
        person: person
      });
    }
  }

  private handleCreateOwnerPersonClick(): void {
    this.createThingGroupIfNeeded();
    if (!this.thingGroup) {
      return;
    }

    void CreatePersonDialog.open({
      userGroupId: this.thingGroup.ownerUserGroupId,
      onDialogClosed: (person) => {
        if (person) {
          this.modifyThingGroup('ownerPersonId', person.id);
        }
      }
    });
  }

  private handleCreateContactPersonClick(): void {
    this.createThingGroupIfNeeded();
    if (!this.thingGroup || !this.thingGroup.ownerPersonId) {
      return;
    }

    // TODO: automatically create a relation to the owner
    void CreatePersonDialog.open({
      userGroupId: this.thingGroup.ownerUserGroupId,
      relatedPersonIds: [this.thingGroup.ownerPersonId],
      onDialogClosed: (person) => {
        if (person) {
          this.modifyThingGroup('contactPersonId', person.id);
        }
      }
    });
  }

  private modifyThingGroup<T extends keyof ThingGroup>(
    propertyName: T,
    value: ThingGroup[T]
  ): void {
    this.createThingGroupIfNeeded();

    if (!this.thingGroup) {
      return;
    }

    this.thingGroup[propertyName] = value;

    this.fireThingGroupChangedEvent();
  }

  private handleAddPropertyClick(): void {
    this.createThingGroupIfNeeded();
    if (!this.thingGroup) return;

    this.entityManager.propertyRepository.create({
      ownerUserGroupId: this.thingGroup.ownerUserGroupId,
      thingGroupId: this.thingGroup.id,
      temporaryGroupName: this.temporaryGroupName
        ? this.temporaryGroupName
        : null,
      shadowEntity: !!this.temporaryGroupName
    });

    this.updateProperties();
  }

  private createThingGroupIfNeeded(): void {
    // thing group is not null here because aurelia automatically creates an empty object when values are modified, so we need to check it via the id if it is valid
    if (this.thingGroup && this.thingGroup.id) {
      return;
    }

    this.thingGroup = this.createThingGroupCallback
      ? this.createThingGroupCallback()
      : null;
  }

  protected handleSharepointSynchronizationOfThingsEnabledChanged(
    event: CheckedChangedEvent
  ): void {
    this.createThingGroupIfNeeded();
    if (!this.thingGroup) return;

    this.thingGroup.sharepointSettings.enabled = event.detail.checked;

    this.fireThingGroupChangedEvent();
  }

  private fireThingGroupChangedEvent(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'thing-group-changed',
      detail: null
    });
  }
}
