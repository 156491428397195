import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { DefectChangeLogEntryAction } from 'common/Types/Entities/DefectChangeLogEntry/DefectChangeLogEntryDto';

import { InviteUserEvent } from '../../aureliaComponents/assignee-invitation-email-widget/assignee-invitation-email-widget';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { DefectLoggingService } from '../../classes/EntityManager/entities/Defect/DefectLoggingService';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { UserSelectOption } from '../user-select/user-select';
import { SocketService } from '../../services/SocketService';
import { Dialogs } from '../../classes/Dialogs';
import { computed } from '../../hooks/computed';
import { currentUser, expression, isConnected } from '../../hooks/dependencies';
import { DefectUtils } from '../../classes/EntityManager/entities/Defect/DefectUtils';
import { EventAggregator } from 'aurelia-event-aggregator';
import { EventAggregatorPromiseHelper } from '../../classes/Promise/EventAggregatorPromiseHelper';
import { IWebUser } from '../../services/SessionService/SessionService';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';

/**
 * Dropdown list for choosing an assignee for a defect.
 */
@autoinject()
export class DefectAssigneeSelect {
  /**
   * The defect for which to set the assignee.
   */
  @bindable public defect: Defect | null = null;

  /**
   * The currently selected assignee (e.g. the userId string).
   *
   * Is set to `null` if no assignee is selected.
   * Is set to `-!-inviteNewUserOptionId-!-` if the "invite new user" option is selected.
   */
  @bindable public selectedUserId: string | null = null;

  /**
   * true if a user is currently being invited (with the assignee-invitation-email-widget),
   * false otherwise.
   */
  @bindable public isInvitingUser: boolean = false;

  protected SendEmailUiState = SendEmailUiState;

  protected currentSendEmailUiState = SendEmailUiState.INIT;

  protected additionalOptions: Array<UserSelectOption> = [
    {
      label: this.i18n.tr('inputComponents.assigneeSelect.inviteNewUserLabel'),
      value: inviteNewUserKey
    }
  ];

  @subscribableLifecycle()
  protected defectPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Defect];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly defectLoggingService: DefectLoggingService,
    private readonly i18n: I18N,
    private readonly socketService: SocketService,
    private readonly currentUserService: CurrentUserService,
    private readonly eventAggregator: EventAggregator,
    permissionsService: PermissionsService
  ) {
    this.defectPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Defect,
        context: this,
        expression: 'defect'
      });
  }

  protected attached(): void {
    if (!this.defect) return;
    this.selectedUserId = this.defect.assigneeId;
  }

  protected defectChanged(
    newDefect: Defect | null | undefined,
    oldDefect: Defect | null | undefined
  ): void {
    if (!oldDefect && !newDefect) {
      // if no defects, set userId to null
      this.selectedUserId = null;
    } else if (!oldDefect && !!newDefect) {
      // if a defect has just been assigned, set the userId to that
      this.selectedUserId = newDefect.assigneeId;
    } else if (!!oldDefect && !!newDefect && oldDefect.id !== newDefect.id) {
      // if the new defect isn't the same instance, set the userId
      this.selectedUserId = newDefect.assigneeId;
    }
  }

  public handleSelectedUserIdChanged(): void {
    assertNotNullOrUndefined(
      this.defect,
      'cannot update defect if its undefined'
    );

    if (this.defect.assigneeId !== this.selectedUserId) {
      void this.defectLoggingService.log(
        this.defect,
        DefectChangeLogEntryAction.CHANGED_ASSIGNEE
      );
    }
    if (this.selectedUserId === inviteNewUserKey) {
      this.defect.assigneeId = null;
    } else {
      this.defect.assigneeId = this.selectedUserId;
    }

    this.entityManager.defectRepository.update(this.defect);
  }

  protected handleUserInvited(evt: InviteUserEvent): void {
    this.selectedUserId = evt.detail.user.id;
  }

  /**
   * Manually notify the currently set user.
   */
  protected handleSendEmailClicked(): void {
    if (this.currentSendEmailUiState !== SendEmailUiState.INIT) return;

    const user = this.defect?.assigneeId
      ? this.entityManager.userRepository.getById(this.defect.assigneeId)
      : null;
    assertNotNullOrUndefined(
      user,
      'cannot handleSendEmailClicked without assigned user'
    );

    this.currentSendEmailUiState = SendEmailUiState.LOADING;
    EventAggregatorPromiseHelper.createConnectedPromise<IWebUser>(
      this.eventAggregator,
      new Promise((res, rej) => {
        this.socketService.inviteUser(
          {
            userName: user.username,
            email: user.email,
            url: this.defect
              ? DefectUtils.createWorkerDefectUrl(this.defect)
              : undefined
          },
          (data) =>
            data.success ? res(data.user) : rej(new Error(data.status))
        );
      })
    )
      .then(() => {
        this.currentSendEmailUiState = SendEmailUiState.SENT;
      })
      .catch((error: Error) => {
        void Dialogs.errorDialogTk(`serverResponses.${error.message}`);
      })
      .finally(() => {
        setTimeout(() => {
          this.currentSendEmailUiState = SendEmailUiState.INIT;
        }, 2000);
      });
  }

  /**
   * The widget for inviting a new user should only be shown if the "Invite User" option is selected,
   * e.g. if the selectedUserId equals the inviteNewUserKey.
   */
  @computedFrom('selectedUserId')
  protected get showAssigneeInvitationEmailWidget(): boolean {
    return this.selectedUserId === inviteNewUserKey;
  }

  @computed(
    expression('showAssigneeInvitationEmailWidget'),
    expression('selectedUserId'),
    currentUser(),
    isConnected()
  )
  protected get showSendEmailWidget(): boolean {
    return (
      !this.showAssigneeInvitationEmailWidget &&
      !!this.selectedUserId &&
      this.currentUserService.getCurrentUser()?.id !== this.selectedUserId &&
      this.socketService.isConnected()
    );
  }
}

const inviteNewUserKey = '-!-inviteNewUserOptionId-!-';

enum SendEmailUiState {
  /**
   * An email can be sent.
   */
  INIT = 'init',
  /**
   * An email is currently being sent.
   */
  LOADING = 'loading',
  /**
   * An email has been sent. The UI will reset to the INIT state shortly.
   */
  SENT = 'sent'
}
