export class ExpressionEditorScopeCreationUtils {
  public static createFieldConfigs<TConfigs extends FieldConfigs>(
    configs: Readonly<TConfigs>
  ): CreateValidatedFieldConfigsType<TConfigs> {
    return configs as CreateValidatedFieldConfigsType<TConfigs>;
  }

  public static createFieldConfig<TConfig extends FieldConfig<any>>(
    config: Readonly<TConfig>
  ): TConfig {
    return config;
  }

  public static createContextSpecificFieldConfigsFactory<
    TFieldConfigs extends FieldConfigs,
    TContext extends Record<string, any>
  >({
    fieldConfigs,
    createContextSpecificFieldConfigData: createContextSpecificFieldConfigs
  }: {
    fieldConfigs: TFieldConfigs;
    createContextSpecificFieldConfigData: (context: TContext) => {
      [key in keyof TFieldConfigs]: TFieldConfigs[key]['valueType'] extends ValueType.NESTED
        ? TFieldConfigs[key]['fieldConfigs'] extends FieldConfigs
          ? ApplyNullable<
              FieldConfigConfigurationForFieldConfig<TFieldConfigs[key]>,
              ContextSpecificFieldConfigs<TFieldConfigs[key]['fieldConfigs']>
            >
          : 'error: nested ValueType without fieldConfigs'
        : ContextSpecificFieldConfigData<
            FieldConfigConfigurationForFieldConfig<TFieldConfigs[key]>
          >;
    };
  }): (context: TContext) => ContextSpecificFieldConfigs<TFieldConfigs> {
    return (context) => {
      const contextSpecificData = createContextSpecificFieldConfigs(context);
      const contextConfigs: Record<string, unknown> = {};

      for (const key of Object.keys(fieldConfigs)) {
        contextConfigs[key] = {
          ...fieldConfigs[key],
          contextSpecific: contextSpecificData[key]
        };
      }
      return contextConfigs as ContextSpecificFieldConfigs<TFieldConfigs>;
    };
  }
}

export type FieldConfigs = {
  [key in string]: FieldConfig<{
    Name: key;
    ValueType: ValueType;
    Nullable: boolean;
    FieldConfigs: any;
  }>;
};

export type ContextSpecificFieldConfigs<TFieldConfigs extends FieldConfigs> = {
  [key in keyof TFieldConfigs]: ContextSpecificFieldConfig<
    FieldConfigConfigurationForFieldConfig<TFieldConfigs[key]>
  >;
};

type CreateValidatedFieldConfigsType<TConfigs extends FieldConfigs> =
  IsValidFieldConfigs<TConfigs> extends true
    ? TConfigs
    : IsValidFieldConfigs<TConfigs> extends string
      ? `invalid config: ${IsValidFieldConfigs<TConfigs>}`
      : 'unknown invalid config';

type IsValidFieldConfigs<TConfigs extends FieldConfigs> = {
  [key in keyof TConfigs]: TConfigs[key]['name'] extends key ? never : key;
}[keyof TConfigs];

type FieldConfig<TConfig extends FieldConfigConfiguration<any>> = {
  name: TConfig['Name'];
  valueType: TConfig['ValueType'];
  fieldConfigs?: TConfig['FieldConfigs'];
  nullable: TConfig['Nullable'];
  descriptionTk: string;
};

type ContextSpecificFieldConfig<TConfig extends FieldConfigConfiguration<any>> =
  FieldConfig<TConfig> & {
    contextSpecific: ContextSpecificFieldConfigData<TConfig>;
  };

type ContextSpecificFieldConfigData<
  TConfig extends FieldConfigConfiguration<any>
> = ValueType.NESTED extends TConfig['ValueType']
  ? TConfig['FieldConfigs'] extends FieldConfigs
    ? ApplyNullable<
        TConfig,
        {
          [key in keyof TConfig['FieldConfigs']]: ContextSpecificFieldConfigData<
            FieldConfigConfigurationForFieldConfig<TConfig['FieldConfigs'][key]>
          >;
        }
      >
    : ApplyNullable<TConfig, Record<string, never>>
  : {
      getValue: () => Promise<GetValueReturnTypeForConfig<TConfig>>;
    };

type ApplyNullable<
  TConfig extends FieldConfigConfiguration<any>,
  TBaseType
> = TConfig['Nullable'] extends true ? TBaseType | null : TBaseType;

type FieldConfigConfigurationForFieldConfig<
  TFieldConfig extends FieldConfig<any>
> = {
  Name: TFieldConfig['name'];
  Nullable: TFieldConfig['nullable'];
  ValueType: TFieldConfig['valueType'];
  FieldConfigs: TFieldConfig['fieldConfigs'] extends FieldConfigs
    ? TFieldConfig['fieldConfigs']
    : null;
};

type FieldConfigConfiguration<TValueType extends ValueType> = {
  Name: string;
  ValueType: TValueType;
  Nullable: boolean;
  FieldConfigs?: FieldConfigs | null;
};

type GetValueReturnTypeForConfig<
  TConfig extends FieldConfigConfiguration<any>
> = ApplyNullable<TConfig, ValueTypeToPrimitiveType<TConfig>>;

type ValueTypeToPrimitiveType<TConfig extends FieldConfigConfiguration<any>> =
  TConfig['ValueType'] extends ValueType.NUMBER
    ? number
    : TConfig['ValueType'] extends ValueType.STRING
      ? string
      : never;

export enum ValueType {
  STRING = 'string',
  NUMBER = 'number',
  NESTED = 'object'
}
