import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ThingToPerson, ThingToPersonCreationEntity } from './types';

export class ThingToPersonRepository extends AppEntityRepository<EntityName.ThingToPerson> {
  /**
   * Ensures that only the provided `personIds` have a relation to the provided `thingId`.
   *
   * The `ownerUserGroupId` needs to be specified and is used for new ThingToPerson relations
   * that need to be created.
   */
  public setPersonIdsForThingId({
    thingId,
    personIds,
    ownerUserGroupId
  }: {
    thingId: string;
    personIds: Array<string>;
    ownerUserGroupId: string;
  }): void {
    const relations = this.getByThingId(thingId);
    const relationPersonIds = relations.map((r) => r.personId);

    // Existing relations with a `personId` that do not occur in `personIds` need to be deleted
    const relationsToDelete = relations.filter(
      (r) => !personIds.includes(r.personId)
    );
    // IDs in `personIds` that do not have a relation need to be created
    const relationsToCreate = personIds
      .filter((p) => !relationPersonIds.includes(p))
      .map(
        (p): ThingToPersonCreationEntity => ({
          personId: p,
          thingId: thingId,
          ownerUserGroupId: ownerUserGroupId
        })
      );

    // Create & delete relations
    for (const relation of relationsToDelete) {
      this.delete(relation);
    }
    for (const relation of relationsToCreate) {
      this.create(relation);
    }
  }

  /**
   * returns the mainContact if available, if not it returns the first contact
   */
  public getMainContactOrFallbackForThingId(
    thingId: string
  ): ThingToPerson | null {
    const thingToPersons = this.getByThingId(thingId);
    const mainThingToPerson = thingToPersons.find((ttp) => ttp.mainContact);
    return mainThingToPerson ? mainThingToPerson : thingToPersons[0] ?? null;
  }

  public getByThingId(thingId: string): Array<ThingToPerson> {
    return this.getAll().filter(
      (thingToPerson) => thingToPerson.thingId === thingId
    );
  }

  public getByPersonId(personId: string): Array<ThingToPerson> {
    return this.getAll().filter(
      (thingToPerson) => thingToPerson.personId === personId
    );
  }
}
