export enum GalleryThingWidgetType {
  DateRange = 'dateRange',
  LatLongArea = 'latLongArea',
  Tags = 'tags',
  PictureDescription = 'pictureDescription',
  Region = 'region',
  Person = 'person',
  PersonSelect = 'personSelect',
  PersonMultiSelect = 'personMultiSelect',
  Project = 'project',
  Property = 'property'
}
