import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { IWebUser } from '../../services/SessionService/SessionService';
import { SocketService } from '../../services/SocketService';
import { DefectUtils } from '../../classes/EntityManager/entities/Defect/DefectUtils';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { EventAggregator } from 'aurelia-event-aggregator';
import { EventAggregatorPromiseHelper } from '../../classes/Promise/EventAggregatorPromiseHelper';

/**
 * Widget that allows for sending assignee invitations to a given email.
 * Displays an input for an email, and a "Send" button.
 *
 * @event user-invited fired after the "send invite" button has been clicked.
 * @event failed-to-invite fired after a user could not be invited.
 */
@autoinject()
export class AssigneeInvitationEmailWidget {
  @bindable() public defect: Defect | null = null;

  /**
   * While the widget is loading, it should NOT be
   * able to be closed.
   */
  @bindable() public isLoading: boolean = false;

  protected userName: string | null = null;

  protected userEmail: string | null = null;

  protected messageKey: string | null = null;

  protected messageIsError: boolean = false;

  private element: HTMLElement;

  constructor(
    element: Element,
    private readonly socketService: SocketService,
    private readonly entityManager: AppEntityManager,
    private readonly eventAggregrator: EventAggregator
  ) {
    this.element = element as HTMLElement;
  }

  private resetResponseUi(): void {
    this.messageKey = null;
    this.messageIsError = false;
  }

  private resetUi(): void {
    this.userName = null;
    this.userEmail = null;
    this.resetResponseUi();
  }

  protected handleInviteUserButtonClicked(): void {
    this.resetResponseUi();
    this.isLoading = true;
    EventAggregatorPromiseHelper.createConnectedPromise<IWebUser>(
      this.eventAggregrator,
      new Promise((res, rej) => {
        this.socketService.inviteUser(
          {
            userName: this.userName!,
            email: this.userEmail!,
            url: this.defect
              ? DefectUtils.createWorkerDefectUrl(this.defect)
              : undefined
          },
          (data) =>
            data.success ? res(data.user) : rej(new Error(data.status))
        );
      })
    )
      .then((user) => {
        this.entityManager.userRepository.addExternalEntityLocally(user);
        // Set success message
        this.messageKey =
          'aureliaComponents.assigneeInvitationEmailWidget.successMessage';
        this.messageIsError = false;
        // Notify parents (after a 5 sec timeout so users can read the success message)
        setTimeout(() => {
          this.isLoading = false;
          DomEventHelper.fireEvent<InviteUserEvent>(this.element, {
            name: 'user-invited',
            detail: {
              user: {
                id: user.id,
                email: user.email,
                username: user.username
              }
            }
          });
          this.resetUi();
        }, 5000);
      })
      .catch((error: Error) => {
        this.isLoading = false;
        this.messageKey = `serverResponses.${error.message}`;
        this.messageIsError = true;

        DomEventHelper.fireEvent<FailedToInviteEvent>(this.element, {
          name: 'failed-to-invite',
          detail: null
        });
      });
  }

  @computedFrom('userName', 'userEmail', 'isLoading')
  protected get isSubmitButtonEnabled(): boolean {
    if (!this.userName) {
      return false;
    }
    if (this.userName.length === 0) {
      return false;
    }
    if (!this.userEmail) {
      return false;
    }
    if (this.userEmail.length === 0) {
      return false;
    }
    if (this.isLoading) {
      return false;
    }
    return true;
  }
}

export type InviteUserEvent = NamedCustomEvent<
  'user-invited',
  { user: IWebUser }
>;
export type FailedToInviteEvent = NamedCustomEvent<'failed-to-invite', null>;
