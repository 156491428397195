import { bindable, inject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { ProcessConfigurationStepPositionUtils } from '../../classes/EntityManager/entities/ProcessConfigurationStepPosition/ProcessConfigurationStepPositionUtils';

/**
 * @event edit-process-configuration-step-clicked
 */
@inject(Element, I18N, Router)
export class ProcessConfigurationStepListItem {
  /** @type {import('../../classes/EntityManager/entities/ProcessConfigurationStep/types').ProcessConfigurationStep|null} */
  @bindable processConfigurationStep = null;

  /**
   * @type {boolean}
   */
  @bindable enabled = false;

  /** @type {I18N} */
  _i18n;
  /** @type {Router} */
  _router;

  /** @type {boolean} */
  _panelOpened = false;

  /** @type {HTMLElement} */
  _domElement;
  /** @type {HTMLElement} */
  _listItemElement;

  /**
   * @param {HTMLElement} element
   * @param {I18N} i18n
   * @param {Router} router
   */
  constructor(element, i18n, router) {
    this._domElement = element;
    this._i18n = i18n;
    this._router = router;
  }

  highlight() {
    EntityListItemHelper.highlightListItemElement(this._listItemElement);
  }

  _handleMoreButtonClick() {
    this._panelOpened = !this._panelOpened;
  }

  _handleEnterProcessConfigurationStepClick() {
    ProcessConfigurationStepPositionUtils.navigateToEditProcessConfigurationStepPositionsPage(
      this._router,
      this.processConfigurationStep.id
    );
  }

  _handleEditProcessConfigurationStepClick() {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'edit-process-configuration-step-clicked',
      detail: null
    });
  }

  _handleToggleProcessConfigurationStepActivatedClick() {
    // TODO: actually implement this
    throw new Error('Not Implemented');
  }
}
