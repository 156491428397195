import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { DateUtils } from '../../../../common/src/DateUtils';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { CoordinateHelper } from '../../classes/CoordinateHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { MomentInput } from 'moment';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

/**
 * @event thing-group-changed
 */
@autoinject()
export class ThingGroupDialogFields {
  @bindable public thingGroup: ThingGroup | null = null;

  /**
   * if this is set, then all sub entities will be created with a temporaryGroupName + shadowEntity = true
   */
  @bindable public temporaryGroupName: string | null = null;

  private domElement: HTMLElement;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.ThingGroup];

  protected readonly clientCoordinates =
    CoordinateHelper.getClientCoordinates();

  constructor(
    element: Element,
    private readonly i18n: I18N,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.domElement = element as HTMLElement;

    this.permissionsHandle = permissionsService.getPermissionsHandleForProperty(
      {
        entityName: EntityName.ThingGroup,
        context: this as ThingGroupDialogFields,
        propertyName: 'thingGroup'
      }
    );
  }

  protected handleThingGroupChanged(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'thing-group-changed',
      detail: null
    });
  }

  protected formatToDateString(date: MomentInput): string {
    return DateUtils.formatToDateString(date);
  }

  protected getUserGroupNameById(userGroupId: string): string {
    const userGroup =
      this.entityManager.userGroupRepository.getById(userGroupId);

    return userGroup
      ? userGroup.name ?? ''
      : this.i18n.tr('modelsDetail.UserGroupModel.userGroupNotFound');
  }

  protected canCalculateDistance(
    latitude: number | null,
    longitude: number | null,
    clientLatitude: number | null,
    clientLongitude: number | null
  ): boolean {
    return (
      this.isNotNull(latitude) &&
      this.isNotNull(longitude) &&
      this.isNotNull(clientLatitude) &&
      this.isNotNull(clientLongitude)
    );
  }

  protected calculateDistance(
    latitude: number,
    longitude: number,
    clientLatitude: number,
    clientLongitude: number
  ): string {
    return CoordinateHelper.calculateFormattedDistance(
      clientLongitude,
      clientLatitude,
      longitude,
      latitude
    );
  }

  private isNotNull(i: any): boolean {
    return i != null;
  }
}
