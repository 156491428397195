import { autoinject } from 'aurelia-framework';
import { ActiveEntitiesService } from '../../services/ActiveEntitiesService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProjectQuestion } from '../../classes/EntityManager/entities/ProjectQuestion/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { Router } from 'aurelia-router';
import { QuestionChoice } from 'common/Types/Entities/Question/QuestionDto';
import { SelectedChoiceChangedEvent } from '../../inputComponents/selection-switch/selection-switch';
import { ProjectQuestionUtils } from '../../classes/EntityManager/entities/ProjectQuestion/ProjectQuestionUtils';
import { ProjectQuestionService } from '../../services/ProjectQuestionService';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  Picture,
  ProjectQuestionPicture
} from '../../classes/EntityManager/entities/Picture/types';
import { PictureSelectAndEditDialog } from '../../dialogs/picture-select-and-edit-dialog/picture-select-and-edit-dialog';

@autoinject()
export class ChecklistInspectionQuestion {
  protected getSelectedChoice = ProjectQuestionUtils.getSelectedChoice;

  private projectId: string | null = null;
  private projectCategoryId: string | null = null;
  private projectQuestionId: string | null = null;

  constructor(
    private readonly activeEntitiesService: ActiveEntitiesService,
    private readonly entityManager: AppEntityManager,
    private readonly router: Router,
    private readonly projectQuestionService: ProjectQuestionService
  ) {}

  protected activate(params: {
    project_id: string;
    project_category_id: string;
    project_question_id: string;
  }): void {
    this.projectId = params.project_id;
    this.projectCategoryId = params.project_category_id;
    this.projectQuestionId = params.project_question_id;
    this.activeEntitiesService.setActiveChecklistInspectionQuestion(
      this.entityManager.projectQuestionRepository.getById(
        this.projectQuestionId
      )
    );
  }

  protected deactivate(): void {
    this.activeEntitiesService.setActiveChecklistInspectionQuestion(null);
  }

  protected handleLinkToCategoryButtonClicked(): void {
    this.router.navigateToRoute('checklist_inspection_category', {
      project_id: this.projectId,
      project_category_id: this.projectCategoryId
    });
  }

  protected handleQuestionChoiceSelected(
    question: ProjectQuestion,
    event: SelectedChoiceChangedEvent<QuestionChoice>
  ): void {
    this.projectQuestionService.setQuestionAnswer(
      question,
      event.detail.selectedChoice
    );
  }

  protected handleQuestionUpdated(): void {
    assertNotNullOrUndefined(
      this.question,
      'cannot handleQuestionUpdated without question'
    );

    this.entityManager.projectQuestionRepository.update(this.question);
  }

  protected handlePictureClicked(picture: Picture): void {
    assertNotNullOrUndefined(
      this.projectId,
      'cannot handlePictureClicked without projectId'
    );
    assertNotNullOrUndefined(
      this.projectQuestionId,
      'cannot handlePictureClicked without projectQuestionId'
    );
    assertNotNullOrUndefined(
      this.question,
      'cannot handlePictureClicked without question'
    );

    void PictureSelectAndEditDialog.open({
      canCreatePictures: true,
      mainEntityIdField: 'ownerProjectId',
      mainEntityId: this.projectId,
      subEntityField: 'projectQuestionId',
      subEntityValue: this.projectQuestionId,
      ownerUserGroupId: this.question.ownerUserGroupId
    });
  }

  @computed(expression('projectQuestionId'), model(EntityName.ProjectQuestion))
  protected get question(): ProjectQuestion | null {
    if (!this.projectQuestionId) return null;

    return this.entityManager.projectQuestionRepository.getById(
      this.projectQuestionId
    );
  }

  @computed(expression('projectQuestionId'), model(EntityName.Picture))
  protected get pictures(): Array<ProjectQuestionPicture> {
    if (!this.projectQuestionId) return [];

    return this.entityManager.pictureRepository.getByProjectQuestionId(
      this.projectQuestionId
    );
  }
}
