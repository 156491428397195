import _ from 'lodash';

import { bindable, computedFrom, autoinject } from 'aurelia-framework';
import { MomentInput } from 'moment';

import { ThingGroupHelper } from 'common/EntityHelper/ThingGroupHelper';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { DateUtils } from 'common/DateUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { Dialogs } from '../../classes/Dialogs';
import { NotificationHelper } from '../../classes/NotificationHelper';
import { CoordinateHelper } from '../../classes/CoordinateHelper';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { CopyThingDialog } from '../../dialogs/copy-thing-dialog/copy-thing-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { TitleThingPicture } from '../../classes/EntityManager/entities/Picture/types';
import { ThingActionService } from '../../classes/EntityManager/entities/Thing/ThingActionService';
import { computed } from '../../hooks/computed';
import { currentUser, expression, model } from '../../hooks/dependencies';
import { ThingEntityDashboardInfo } from '../../classes/EntityManager/entities/EntityDashboardInfo/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { ThingValueCalculationResultAdapter } from '../../valueCalculationComponents/ValueCalculationResultAdapter/ThingValueCalculationResultAdapter';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { SharepointHelper } from '../../classes/SharepointHelper';

/**
 * @event edit-thing-clicked
 * @event enter-thing-clicked
 * @event open-parameters-clicked - detail: thing
 */
@autoinject()
export class ThingListItem {
  @bindable()
  public thing: Thing | null = null;

  private readonly subscriptionManager: SubscriptionManager;
  protected listItemElement: HTMLElement | null = null;
  protected thingGroup: ThingGroup | null = null;
  protected thingPerson: Person | null = null;
  protected isMobile: boolean = false;
  protected titlePicture: TitleThingPicture | null = null;

  private isAttached: boolean = false;
  private panelOpen: boolean = false;
  private readonly clientCoordinates = CoordinateHelper.getClientCoordinates();

  protected ThingGroupHelper = ThingGroupHelper;
  protected PersonUtils = PersonUtils;
  protected valueCalculationEntityListItemAdapter: ThingValueCalculationResultAdapter | null =
    null;

  @subscribableLifecycle()
  protected thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  @subscribableLifecycle()
  protected userGroupPermissionsHandle: EntityNameToPermissionsHandle[EntityName.UserGroup];

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly thingActionService: ThingActionService,
    private readonly currentUserService: CurrentUserService,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.entityManager = entityManager;

    this.subscriptionManager = subscriptionManagerService.create();
    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Thing,
        context: this as ThingListItem,
        propertyName: 'thing'
      });

    this.userGroupPermissionsHandle =
      permissionsService.getPermissionsHandleForEntityIdOfPropertyValue({
        entityName: EntityName.UserGroup,
        context: this as ThingListItem,
        propertyName: 'thing',
        idPropertyName: 'ownerUserGroupId'
      });
  }

  public highlight(): void {
    if (this.listItemElement) {
      EntityListItemHelper.highlightListItemElement(this.listItemElement);
    }
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isSmallMobile', (isSmallMobile) => {
        this.isMobile = isSmallMobile;
      })
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingGroup,
      this.updateThingGroup.bind(this)
    );
    this.updateThingGroup();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      this.updateTitlePicture.bind(this)
    );
    this.updateTitlePicture();
    this.updateValueCalculationEntityListItemAdapter();
  }

  protected detached(): void {
    this.isAttached = true;
    this.subscriptionManager.disposeSubscriptions();
    this.valueCalculationEntityListItemAdapter = null;
  }

  @computed(expression('thing'), model(EntityName.EntityDashboardInfo))
  protected get dashboardInfo(): ThingEntityDashboardInfo | null {
    if (!this.thing) return null;
    return (
      this.entityManager.entityDashboardInfoRepository.getByThingId(
        this.thing.id
      )[0] ?? null
    );
  }

  @computed(
    currentUser(),
    expression('currentUser.userCompanySettingId'),
    model(EntityName.UserCompanySetting)
  )
  protected get userHasLegacyModuleTiles(): boolean {
    const user = this.currentUserService.getCurrentUser();
    if (!user) return false;
    const currentCompanySetting = user.userCompanySettingId
      ? this.entityManager.userCompanySettingRepository.getById(
          user.userCompanySettingId
        )
      : null;
    return currentCompanySetting
      ? currentCompanySetting.homePage.usesLegacyTileHomePage ?? false
      : false;
  }

  protected thingChanged(): void {
    if (this.isAttached) {
      this.updateTitlePicture();
    }

    if (this.thing) {
      const thingToPerson =
        this.entityManager.thingToPersonRepository.getMainContactOrFallbackForThingId(
          this.thing.id
        );
      this.thingPerson = thingToPerson
        ? this.entityManager.personRepository.getById(thingToPerson.personId) ||
          null
        : null;
    } else {
      this.thingPerson = null;
    }
    this.updateValueCalculationEntityListItemAdapter();
  }

  private updateThingGroup(): void {
    if (this.thing && this.thing.thingGroupId) {
      this.thingGroup =
        this.entityManager.thingGroupRepository.getById(
          this.thing.thingGroupId
        ) || null;
    } else {
      this.thingGroup = null;
    }
  }

  private updateTitlePicture(): void {
    if (this.thing) {
      this.titlePicture =
        this.entityManager.pictureRepository.getSelectedTitleThingPicture(
          this.thing.id
        );
    } else {
      this.titlePicture = null;
    }
  }

  protected handleThingChanged(): void {
    assertNotNullOrUndefined(
      this.thing,
      "can't ThingListItem.handleThingChanged without thing"
    );
    this.entityManager.thingRepository.update(this.thing);
  }

  protected handleEnterThingClick(): void {
    this.sendDomEvent('enter-thing-clicked');
  }

  protected handleEditThingClick(): void {
    this.sendDomEvent('edit-thing-clicked');
  }

  protected handleDeleteThingClick(): void {
    const thing = this.thing;
    assertNotNullOrUndefined(
      thing,
      "can't ThingListItem.handleDeleteThingClick without thing"
    );

    void Dialogs.deleteEntityDialog(thing).then(() => {
      this.entityManager.thingRepository.delete(thing);
    });
  }

  protected handleUpdateThingCoordinatesClick(): void {
    assertNotNullOrUndefined(
      this.thing,
      "can't ThingListItem.handleUpdateThingCoordinatesClick without thing"
    );

    if (!this.clientCoordinates || this.clientCoordinates.latitude == null) {
      NotificationHelper.notifyDanger('Position konnte nicht bestimmt werden!');
      return;
    }

    this.thing.latitude = this.clientCoordinates.latitude;
    this.thing.longitude = this.clientCoordinates.longitude;

    this.handleThingChanged();
  }

  protected handleCopyThingClick(): void {
    assertNotNullOrUndefined(
      this.thing,
      "can't ThingListItem.handleUpdateThingCoordinatesClick without thing"
    );

    void CopyThingDialog.open({
      thingId: this.thing.id,
      newName: this.thing.name + ' (Kopie) '
    });
  }

  protected handleToggleArchivedStatus(): void {
    assertNotNullOrUndefined(
      this.thing,
      "can't ThingListItem.handleToggleArchivedStatus without thing"
    );

    this.thingActionService.toggleArchivedStatusOfThing(this.thing);
  }

  protected handleToggleHideOnHomepageStatus(): void {
    assertNotNullOrUndefined(
      this.thing,
      "can't ThingListItem.handleToggleHideOnHomepageStatus without thing"
    );

    this.thingActionService.toggleHideStatusOnHomepage(this.thing);
  }

  protected handleMoreButtonClick(): void {
    this.panelOpen = !this.panelOpen;
  }

  protected handleOpenParametersClick(): void {
    this.sendDomEvent('open-parameters-clicked', this.thing);
  }

  protected handleOpenInSharepointClick(): void {
    if (
      !this.thing ||
      !this.userGroup?.sharepointCredentials?.sharepointExportSite
    ) {
      throw new Error(
        'cannot open thing in sharepoint without a thing or a sharepoint export site'
      );
    }

    const url = SharepointHelper.getSharepointExportUrlForThingId({
      entityManager: this.entityManager,
      thingId: this.thing.id
    });

    window.open(url, DeviceInfoHelper.isApp() ? '_self' : '_blank');
  }

  private sendDomEvent(eventName: string, detail?: any): void {
    DomEventHelper.fireEvent(this.element, {
      name: eventName,
      detail: detail,
      bubbles: true
    });
  }

  protected formatToDate(time: MomentInput): string {
    return DateUtils.formatToDateString(time);
  }

  protected canCalculateDistance(
    latitude: number | null,
    longitude: number | null,
    clientLatitude: number | null,
    clientLongitude: number | null
  ): boolean {
    return (
      this.isNumber(latitude) &&
      this.isNumber(longitude) &&
      this.isNumber(clientLatitude) &&
      this.isNumber(clientLongitude)
    );
  }

  protected calculateDistance(
    latitude: number,
    longitude: number,
    clientLatitude: number,
    clientLongitude: number
  ): string {
    return CoordinateHelper.calculateFormattedDistance(
      clientLongitude,
      clientLatitude,
      longitude,
      latitude
    );
  }

  private isNumber(i: any): boolean {
    return !isNaN(parseFloat(i));
  }

  @computedFrom('thing.id')
  protected get thingTypeBackground(): string {
    if (!this.thing) return 'inherit';

    const projects =
      this.entityManager.projectRepository.getByThingIdWithoutOperationsProjects(
        this.thing.id
      );
    const projectTypes = _.uniq(projects.map((p) => p.projectType)).sort(
      (pT1, pT2) => pT1.localeCompare(pT2)
    );

    const gradientColors = [];
    for (const projectType of projectTypes) {
      switch (projectType) {
        case ProjectType.B1300:
          gradientColors.push('var(--record-it-color-module-b1300)');
          break;

        case ProjectType.INSPECT:
          gradientColors.push('var(--record-it-color-module-inspect)');
          break;

        case ProjectType.GALLERY:
          gradientColors.push('var(--record-it-color-module-via)');
          break;

        case ProjectType.BASIC:
          gradientColors.push('var(--record-it-color-module-basic)');
          break;

        default:
          break;
      }
    }

    if (gradientColors.length > 0) {
      return (
        'linear-gradient(' +
        gradientColors
          .map(
            (c, i) =>
              `${c} ${(100 / gradientColors.length) * i}% ${
                i < gradientColors.length
                  ? (100 / gradientColors.length) * (i + 1)
                  : '0'
              }%`
          )
          .join(',') +
        ');'
      );
    } else {
      return 'inherit';
    }
  }

  @computed(expression('userGroup.sharepointCredentials'))
  protected get sharepointEnabled(): boolean {
    const sharepointCredentials = this.userGroup?.sharepointCredentials;
    return [
      sharepointCredentials?.applicationId,
      sharepointCredentials?.tenantId,
      sharepointCredentials?.clientCertificate.thumbprint,
      sharepointCredentials?.clientCertificate.privateKey,
      sharepointCredentials?.sharepointExportSite,
      sharepointCredentials?.sharepointExportPath
    ].every(Boolean);
  }

  @computed(expression('thing.ownerUserGroupId'), model(EntityName.UserGroup))
  private get userGroup(): UserGroup | null {
    if (!this.thing) {
      return null;
    }

    return this.entityManager.userGroupRepository.getById(
      this.thing.ownerUserGroupId
    );
  }

  private updateValueCalculationEntityListItemAdapter(): void {
    this.valueCalculationEntityListItemAdapter = null;
    if (this.thing) {
      this.valueCalculationEntityListItemAdapter =
        new ThingValueCalculationResultAdapter({
          entityManager: this.entityManager,
          subscriptionManagerService: this.subscriptionManagerService,
          baseEntity: this.thing
        });
    }
  }
}
