import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ApplyPropertiesService } from '../../classes/EntityManager/entities/Property/ApplyPropertiesService';
import { ValidStructureTemplateRatingCategory } from '../../classes/EntityManager/entities/StructureTemplateRatingCategory/StructureTemplateRatingCategoryRepository';
import {
  StructureTemplateEntryProperty,
  StructureTemplateStructureTemplateEntryProperty
} from '../../classes/EntityManager/entities/StructureTemplateEntryProperty/types';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { EditPropertyDialog } from '../../dialogs/edit-property-dialog/edit-property-dialog';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { EntryProperty } from '../../classes/EntityManager/entities/Property/types';

@autoinject()
export class StructureListTreeItemRatingWidget {
  @bindable public entry: Entry | null = null;

  @bindable public project: Project | null = null;

  private structureTemplateRatingCategories: Array<ValidStructureTemplateRatingCategory> =
    [];
  private structureTemplateEntryProperties: Array<StructureTemplateEntryProperty> =
    [];
  private entryProperties: Array<EntryProperty> = [];

  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly applyPropertiesService: ApplyPropertiesService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Project,
      this.updateStructureTemplateRatingCategories.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplateRatingCategory,
      this.updateStructureTemplateRatingCategories.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplateEntryProperty,
      this.updateStructureTemplateEntryProperties.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateEntryProperties.bind(this)
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private createEntryProperties(): void {
    if (!this.entry || !this.project) {
      return;
    }

    const structureTemplateEntryPropertyIds =
      this.structureTemplateRatingCategories.map(
        (c) => c.structureTemplateEntryPropertyId
      );
    const structureTemplateEntryProperties =
      this.entityManager.structureTemplateEntryPropertyRepository.getByIds(
        structureTemplateEntryPropertyIds
      );
    const structureTemplateStructureTemplateEntryProperties =
      structureTemplateEntryProperties.filter(
        (p): p is StructureTemplateStructureTemplateEntryProperty =>
          !p.structureTemplateEntryId
      );
    this.applyPropertiesService.applyCustomPropertiesToStructureEntry(
      structureTemplateStructureTemplateEntryProperties,
      this.entry,
      this.project
    );
  }

  private updateEntryProperties(): void {
    if (!this.entry) {
      this.entryProperties = [];
      return;
    }

    this.createEntryProperties();
    this.entryProperties = this.entityManager.propertyRepository.getByEntryId(
      this.entry.id
    );
  }

  private updateStructureTemplateRatingCategories(): void {
    if (!this.project || !this.project.structureTemplateId) {
      this.structureTemplateRatingCategories = [];
      return;
    }

    this.structureTemplateRatingCategories =
      this.entityManager.structureTemplateRatingCategoryRepository.getValidByStructureTemplateId(
        this.project.structureTemplateId
      );

    this.updateStructureTemplateEntryProperties();
  }

  private updateStructureTemplateEntryProperties(): void {
    this.structureTemplateEntryProperties =
      this.entityManager.structureTemplateEntryPropertyRepository.getByIds(
        this.structureTemplateRatingCategories.map(
          (c) => c.structureTemplateEntryPropertyId
        )
      );
  }

  protected entryChanged(): void {
    this.updateEntryProperties();
  }

  protected projectChanged(): void {
    this.updateStructureTemplateRatingCategories();
    this.updateEntryProperties();
  }

  protected propertyValue(property: EntryProperty | null): string | null {
    if (!property) return null;
    const text = PropertyHelper.getPropertyText(
      property.type,
      property.name,
      property.value,
      property.custom_choice
    );
    return text.length > 2 ? `${text.slice(0, 2)}..` : text;
  }

  @computedFrom(
    'structureTemplateRatingCategories',
    'structureTemplateEntryProperties',
    'entryProperties'
  )
  protected get ratingCategoriesWithProperties(): Array<CategoryWithProperty> {
    return this.structureTemplateRatingCategories
      .map((c) => ({
        category: c,
        structureTemplateEntryProperty:
          this.structureTemplateEntryProperties.find(
            (p) => p.id === c.structureTemplateEntryPropertyId
          )
      }))
      .map((c) => ({
        category: c.category,
        property: c.structureTemplateEntryProperty
          ? this.entryProperties.find((p) =>
              PropertyHelper.isTheSameProperty(
                c.structureTemplateEntryProperty!,
                p
              )
            ) ?? null
          : null
      }));
  }

  protected handleRatingCategoryButtonClick(
    structureTemplateRatingCategory: ValidStructureTemplateRatingCategory
  ): void {
    assertNotNullOrUndefined(
      this.entry,
      'cannot handleRatingCategoryButtonClick without entry'
    );

    const structureTemplateEntryProperty =
      this.entityManager.structureTemplateEntryPropertyRepository.getRequiredById(
        structureTemplateRatingCategory.structureTemplateEntryPropertyId
      );

    const entryProperty = this.entryProperties.find((p) =>
      PropertyHelper.isTheSameProperty(structureTemplateEntryProperty, p)
    );
    if (entryProperty) {
      void EditPropertyDialog.open({
        property: entryProperty,
        onPropertyChanged: (property) => {
          // Make sure entry & property are synchronized to server
          this.entityManager.entryRepository.createShadowEntitiesInPath(
            this.entry!.id
          );
          if (property.shadowEntity) {
            property.shadowEntity = false;
            this.entityManager.propertyRepository.update(property);
          }
        }
      });
    }
  }
}

/**
 * A rating category with their corresponding property already retrieved.
 */
type CategoryWithProperty = {
  category: ValidStructureTemplateRatingCategory;
  property: EntryProperty | null;
};
