import { inject } from 'aurelia-framework';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';
import { PersonFilterer } from '../../classes/EntityManager/entities/Person/PersonFilterer';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

@inject(AppEntityManager)
export class CreatePersonDialog {
  static TEMPORARY_GROUP_NAME = 'CreatePersonDialog';

  static PAGE_SEARCH = 'search';
  static PAGE_CREATE = 'create';

  /** @type {(function(import('../../classes/EntityManager/entities/Person/types').Person|null):void)|null} */
  _onDialogClosed = null;
  /** @type {import('../../classes/EntityManager/entities/Person/types').Person|null} */
  _personToCreate = null;
  /** @type {import('../../classes/EntityManager/entities/Person/types').Person|null} */
  _lastSelectedPerson = null;
  /** @type {Array<import('../../classes/EntityManager/entities/Person/PersonFilterer').FilterResult>} */
  _searchResults = [];
  /** @type {string} */
  _currentPageName = CreatePersonDialog.PAGE_SEARCH;
  /** @type {PersonFilterer} */
  _personFilterer;

  /** @type {import('../../dialogs/record-it-dialog/record-it-dialog').RecordItDialog|null} */
  _dialog = null;

  /**
   * @param {AppEntityManager} entityManager
   */
  constructor(entityManager) {
    this._entityManager = entityManager;

    this._personFilterer = new PersonFilterer();

    this._PersonUtils = PersonUtils;
    this._CreatePersonDialog = CreatePersonDialog;
  }

  /**
   * @param {TCreatePersonDialogOpenOptions} options
   */
  open(options) {
    this._personToCreate = this._entityManager.personRepository.create({
      temporaryGroupName: CreatePersonDialog.TEMPORARY_GROUP_NAME,
      ownerUserGroupId: options.userGroupId,
      ...options.personToCreate,
      shadowEntity: true
    });

    if (options.relatedPersonIds) {
      this._createRelations(this._personToCreate, options.relatedPersonIds);
    }

    this._onDialogClosed = options.onDialogClosed ?? null;

    if (options.personToCreate)
      this._currentPageName = CreatePersonDialog.PAGE_CREATE;

    if (this._dialog) {
      this._dialog.open();
    }
  }

  _handleDialogClosed() {
    const onClosed = this._onDialogClosed;
    const person = this._lastSelectedPerson;

    this._onDialogClosed = null;
    this._personToCreate = null;
    this._lastSelectedPerson = null;
    this._currentPageName = CreatePersonDialog.PAGE_SEARCH;
    this._entityManager.entityRepositoryContainer.clearShadowEntitiesWithTemporaryGroupName(
      CreatePersonDialog.TEMPORARY_GROUP_NAME
    );

    onClosed && onClosed(person);
  }

  /**
   * @param {import('../../classes/EntityManager/entities/Person/types').Person} personToCreate
   * @param {Array<string>} relatedPersonIds
   */
  _createRelations(personToCreate, relatedPersonIds) {
    relatedPersonIds.forEach((id) => {
      this._entityManager.personToPersonRepository.create({
        person1Id: personToCreate.id,
        person2Id: id,
        ownerUserGroupId: personToCreate.ownerUserGroupId,
        temporaryGroupName: CreatePersonDialog.TEMPORARY_GROUP_NAME,
        shadowEntity: true
      });
    });
  }

  _handleSearchTermChanged() {
    if (!this._personToCreate) {
      return;
    }

    this._personFilterer.clear();
    this._personFilterer.addPersonToIgnore(this._personToCreate);

    if (this._personToCreate.companyName) {
      this._personToCreate.company = true;
      this._personFilterer.addFilterText(this._personToCreate.companyName);
    } else {
      this._personToCreate.company = false;
    }

    if (this._personToCreate.firstName) {
      this._personFilterer.addFilterText(this._personToCreate.firstName);
    }

    if (this._personToCreate.lastName) {
      this._personFilterer.addFilterText(this._personToCreate.lastName);
    }

    const availablePersons =
      this._entityManager.personRepository.getByUserGroupId(
        this._personToCreate.ownerUserGroupId
      );
    this._searchResults = this._personFilterer.filter(availablePersons);
  }

  _handleCreatePersonButtonClick() {
    this._currentPageName = CreatePersonDialog.PAGE_CREATE;
  }

  _handleBackToSearchButtonClick() {
    this._currentPageName = CreatePersonDialog.PAGE_SEARCH;
  }

  _handleAcceptButtonClicked() {
    if (this._currentPageName === CreatePersonDialog.PAGE_CREATE) {
      this._entityManager.entityRepositoryContainer.createShadowEntitiesWithTemporaryGroupName(
        CreatePersonDialog.TEMPORARY_GROUP_NAME
      );
      this._lastSelectedPerson = this._personToCreate;
    }
  }

  /**
   * @param {import('../../classes/EntityManager/entities/Person/PersonFilterer').FilterResult} result
   */
  _handleSearchResultClick(result) {
    if (this._dialog) {
      this._lastSelectedPerson = result.person;
      this._dialog.close();
    }
  }

  /**
   * @param {TCreatePersonDialogOpenOptions} options
   */
  static async open(options) {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }
}

/**
 * @typedef {Object} TCreatePersonDialogOpenOptions
 * @property {string} userGroupId
 * @property {import('../../classes/EntityManager/entities/Person/types').PersonCreationEntity} [personToCreate]
 * @property {Array<string>|null} [relatedPersonIds] - automatically create relations to these personIds
 * @property {(function(import('../../classes/EntityManager/entities/Person/types').Person|null):void)|null} [onDialogClosed] - receives the created/selected person or null if the dialog has just been closed
 */
