import {
  GetProcessTaskToProjectRelationInfoRequest,
  ProcessTaskToProjectRelationInfo,
  ProcessTaskToProjectRelationInfoRequestType,
  SetProcessTaskToProjectRelationResponse
} from 'common/EndpointTypes/ProcessTaskToProjectRelationInfoEndpointsHandler';
import { ProcessTaskToProjectType } from 'common/Types/Entities/ProcessTaskToProject/ProcessTaskToProjectDto';
import { projectTypesOnObjectPage } from 'common/Types/Entities/Project/ProjectDto';
import { Utils } from 'common/Utils';

import {
  AbstractProcessTaskToProjectCrudStrategy,
  ProcessTaskToProjectUpdateOptions
} from './AbstractProcessTaskToProjectCrudStrategy';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';

import { Project } from '../../../classes/EntityManager/entities/Project/types';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskToProject } from '../../../classes/EntityManager/entities/ProcessTaskToProject/types';

export class EntityManagerProcessTaskToProjectCrudStrategy extends AbstractProcessTaskToProjectCrudStrategy {
  constructor(
    processTaskToProjectType: ProcessTaskToProjectType.PROJECT_FROM_OTHER_MODULE,
    private readonly entityManager: AppEntityManager
  ) {
    super(processTaskToProjectType);
  }

  public updateProcessTaskToProjectsAndCandidates(
    request: GetProcessTaskToProjectRelationInfoRequest,
    options: ProcessTaskToProjectUpdateOptions
  ): Promise<void> {
    const processTask =
      this.entityManager.processTaskRepository.getRequiredById(
        request.activeTargetEntityId
      );

    const relationsByProjectIdMap =
      this.getRelationsByProjectIdMap(processTask);

    const projects =
      request.type === ProcessTaskToProjectRelationInfoRequestType.ALL
        ? this.entityManager.projectRepository
            .getByThingId(processTask.thingId)
            .filter((p) => projectTypesOnObjectPage().includes(p.projectType))
        : this.entityManager.projectRepository.getByIds(
            Array.from(relationsByProjectIdMap.keys())
          );

    const projectsWithConsideredArchivedStatus =
      this.getProjectWithArchivedStatusOfRequest(
        projects,
        relationsByProjectIdMap,
        request
      );

    const filteredProjects = this.getFilteredProjects(
      projectsWithConsideredArchivedStatus,
      request
    );

    const slicedProjects = this.getSlicedProjects(filteredProjects, request);

    const relationInfos: Array<ProcessTaskToProjectRelationInfo> =
      slicedProjects.map((p) => {
        return {
          mainInfoText: p.name ?? '',
          subInfoText: p.report_type
            ? this.entityManager.reportTypeRepository.getById(p.report_type)
                ?.name ?? ''
            : '',
          processTaskToProjectId:
            relationsByProjectIdMap.get(p.id)?.at(0)?.id ?? null,
          projectId: p.id,
          url: p.id,
          isArchived: p.archived
        };
      });
    options.setRelationsToDisplay(relationInfos);
    options.setTotalRelationsCount(projectsWithConsideredArchivedStatus.length);

    return Promise.resolve();
  }

  public createProcessTaskToProject({
    processTaskId,
    projectId
  }: {
    processTaskId: string;
    projectId: string;
  }): Promise<SetProcessTaskToProjectRelationResponse> {
    const processTask =
      this.entityManager.processTaskRepository.getRequiredById(processTaskId);
    const relation = this.entityManager.processTaskToProjectRepository.create({
      ownerUserGroupId: processTask.ownerUserGroupId,
      ownerProcessTaskGroupId: processTask.ownerProcessTaskGroupId,
      ownerProcessTaskId: processTask.id,
      projectId,
      type: this.processTaskToProjectType
    });
    return Promise.resolve({
      newProcessTaskToProjectId: relation.id,
      success: true
    });
  }

  public deleteProcessTaskToProject({
    processTaskId,
    projectId
  }: {
    processTaskId: string;
    projectId: string;
  }): Promise<SetProcessTaskToProjectRelationResponse> {
    const relations =
      this.entityManager.processTaskToProjectRepository.getByProcessTaskIdAndProjectIdAndType(
        { processTaskId, projectId, type: this.processTaskToProjectType }
      );

    for (const r of relations) {
      this.entityManager.processTaskToProjectRepository.delete(r);
    }

    return Promise.resolve({
      newProcessTaskToProjectId: null,
      success: true
    });
  }

  public setupSubscriptions(
    subscriptionManager: SubscriptionManager,
    updateCallback: () => Promise<void>
  ): void {
    subscriptionManager.subscribeToModelChanges(EntityName.Project, () => {
      void updateCallback();
    });
    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskToProject,
      () => {
        void updateCallback();
      }
    );
  }

  private getSlicedProjects(
    allProjects: Array<Project>,
    request: GetProcessTaskToProjectRelationInfoRequest
  ): Array<Project> {
    const sliceBegin =
      (request.paginationInfo.currentPageNumber - 1) *
      request.paginationInfo.currentPageSize;
    return allProjects.slice(
      sliceBegin,
      sliceBegin + request.paginationInfo.currentPageSize
    );
  }

  private getFilteredProjects(
    allProjects: Array<Project>,
    request: GetProcessTaskToProjectRelationInfoRequest
  ): Array<Project> {
    if (
      !!request.filterString &&
      request.type ===
        ProcessTaskToProjectRelationInfoRequestType.WITH_EXISTING_RELATION
    )
      throw new Error(
        'Filtering with InfoRequestType WITH_EXISTING_RELATION is not implemented.'
      );

    return !!request.filterString
      ? allProjects.filter(
          (p) =>
            p.name
              ?.toLocaleLowerCase()
              .includes(request.filterString!.toLocaleLowerCase())
        )
      : allProjects;
  }

  private getProjectWithArchivedStatusOfRequest(
    allProjects: Array<Project>,
    relationsByProjectIdMap: Map<string, Array<ProcessTaskToProject>>,
    request: GetProcessTaskToProjectRelationInfoRequest
  ): Array<Project> {
    const projects = [];
    for (const project of allProjects) {
      if (!project.archived) {
        projects.push(project);
        continue;
      }
      const hasRelation = !!relationsByProjectIdMap.get(project.id);
      if (request.includeArchivedEntities || hasRelation) {
        projects.push(project);
      }
    }
    return projects;
  }

  private getRelationsByProjectIdMap(
    processTask: ProcessTask
  ): Map<string, Array<ProcessTaskToProject>> {
    const existingRelations = this.entityManager.processTaskToProjectRepository
      .getByProcessTaskId(processTask.id)
      .filter((r) => r.type === this.processTaskToProjectType);

    return Utils.groupValues(existingRelations, (r) => r.projectId);
  }
}
