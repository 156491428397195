import $ from 'jquery';

import { Aurelia, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { I18N } from 'aurelia-i18n';
import { PLATFORM } from 'aurelia-pal';

import { DataStorageHelper } from '../classes/DataStorageHelper/DataStorageHelper';

import { IWebUser, SessionService } from './SessionService/SessionService';
import { NotificationHelper } from '../classes/NotificationHelper';
import { UrlManager } from '../classes/UrlManager';
import { LogoutLockService } from './LogoutLockService';
import { Dialogs } from '../classes/Dialogs';

@autoinject()
export class AuthenticationService {
  constructor(
    private readonly aurelia: Aurelia,
    private readonly router: Router,
    private readonly i18n: I18N,
    private readonly sessionService: SessionService,
    private readonly logoutLockService: LogoutLockService
  ) {}

  public async login(email: string, password: string): Promise<void> {
    try {
      const data = await new Promise<any>((res, rej) => {
        const deferred = $.post(`${UrlManager.webFolder}/login`, {
          identifier: email,
          password: password
        })
          .done((d: any) => res(d))
          .catch((error) => {
            rej(error);
          });

        void deferred.fail((error) => {
          this.showNotificationMessageForError(
            error,
            '$t(services.AuthenticationService.loginFailed) $t(services.AuthenticationService.pleaseTryAgainOrContactAdministrator)'
          );
          rej(error);
        });
      });

      if (data.jwt && data.user) {
        await this.updateLoginData(data.jwt, data.user);
        const root = data.user.hasAssignedDefects
          ? 'edit_via_worker_defects'
          : 'home';
        this.navigateToNewRoot(root, PLATFORM.moduleName('pageRoots/app/app'));
      } else {
        NotificationHelper.notifyDanger(data.message);
      }
    } catch (error) {
      this.showNotificationMessageForError(error);
      throw error;
    }
  }

  /**
   * Logs in with an invite token.
   * @returns a token for a password reset.
   */
  public async loginWithInviteToken(
    token: string
  ): Promise<{ email: string; token: string } | null> {
    try {
      const data = await $.post(`${UrlManager.webFolder}/invite-token`, {
        token: token
      });
      if (!data.success) {
        throw new Error(data);
      }
      await this.reloginWithToken(data.loginToken, data.user);
      return { email: data.user.email, token: data.resetPasswordToken };
    } catch (error) {
      if (this.isJQueryXHR(error)) {
        const status = error.responseJSON?.status ?? error.status;

        NotificationHelper.notifyDanger(
          this.i18n.tr(`serverResponses.${status}`)
        );

        return null;
      } else {
        throw error;
      }
    }
  }

  public async logout(): Promise<void> {
    if (this.logoutLockService.isAnyLockActive())
      throw new Error('logout lock is active');
    setTimeout(() => {
      Dialogs.waitDialogTk('services.AuthenticationService.deleteLocalData');
    }, 1000);

    await DataStorageHelper.lockDatabaseAndClearAll();
    Dialogs.closeAllDialogs();
    this.router.navigate('auth', { replace: true, trigger: false });
    window.location.reload();
  }

  public async reloginWithToken(
    token: string,
    user: IWebUser,
    options?: { navigate: boolean }
  ): Promise<void> {
    setTimeout(() => {
      Dialogs.waitDialogTk('services.AuthenticationService.deleteLocalData');
    }, 1000);

    await DataStorageHelper.lockDatabaseAndClearAll();
    DataStorageHelper.resetDatabaseLock();
    Dialogs.closeAllDialogs();
    await this.updateLoginData(token, user);

    // we can't use _navigateToNewRoot here because it will put the router in an invalid state (not nullable values will be null)
    // if the aurelia typing would be correct we could add checks for this value (currentInstruction) everywhere, but the way it is now we aren't forced to do that
    if (options?.navigate) {
      this.router.navigate('home', { replace: true, trigger: false });
      window.location.reload();
    }
  }

  public register(
    username: string,
    email: string,
    password: string,
    passwordConfirmation: string
  ): Promise<void> {
    // todo email mit bestätigung schicken?
    return new Promise((res, rej) => {
      void $.post(`${UrlManager.webFolder}/register`, {
        username: username,
        email: email,
        password: password,
        passwordConfirmation: passwordConfirmation
      })
        .done((data) => {
          NotificationHelper.notifyNeutral(data.message);
          res();
        })
        .fail((error) => {
          console.log(error);
          this.showNotificationMessageForError(
            error,
            '$t(services.AuthenticationService.registrationFailed) $t(services.AuthenticationService.pleaseTryAgainOrContactAdministrator)'
          );
          rej(error);
        });
    });
  }

  public sendPasswordResetEmail(email: string): Promise<void> {
    return new Promise((res, rej) => {
      void $.post(`${UrlManager.webFolder}/sendpassword`, {
        email: email
      })
        .done(() => {
          NotificationHelper.notifySuccess(
            `Eine E-Mail wurde an "${email}" versendet.`
          );
          res();
        })
        .fail((error) => {
          this.showNotificationMessageForError(error);
          rej(error);
        });
    });
  }

  public acceptUserGroupInvitationToken(token: string): Promise<void> {
    return new Promise((res, rej) => {
      void $.post(`${UrlManager.webFolder}/api/usergroup-invitation-token`, {
        token
      })
        .done((r: any) => {
          this.router.navigateToRoute('home', {
            replace: true,
            trigger: false
          });
          NotificationHelper.notifySuccess(
            this.i18n.tr(
              'services.AuthenticationService.userGroupAdditionSuccess',
              { userGroupName: r.userGroupName }
            )
          );
          res();
        })
        .fail((error) => {
          this.showNotificationMessageForError(error);
          rej(error);
        });
    });
  }

  public resetPassword(
    token: string,
    password: string,
    confirmationPassword: string,
    options?: { navigate?: boolean }
  ): Promise<void> {
    return new Promise((res, rej) => {
      void $.post('/set-new-pass', {
        pass1: password,
        pass2: confirmationPassword,
        token: token
      })
        .then((data) => {
          return this.reloginWithToken(data.token, data.user);
        })
        .done(() => {
          if (options?.navigate) {
            this.router.navigate('auth', { replace: true, trigger: true });
          }
          res();
        })
        .fail((error) => {
          this.showNotificationMessageForError(error);
          rej(error);
        });
    });
  }

  public async acceptConfirmationToken(token: string): Promise<void> {
    try {
      const response = await new Promise<any>((res, rej) => {
        void $.post('/confirmation-token', {
          token: token
        })
          .done((r: any) => res(r))
          .fail((error) => rej(error));
      });

      if (response.message) {
        NotificationHelper.notifyNeutral(response.message);
      }

      const currentJWT = this.sessionService.getCurrentJWT();
      if (!currentJWT && response.jwt && response.user) {
        await this.sessionService.setCurrentJWT(response.jwt);
        await this.sessionService.setCurrentUser(response.user);
      }

      let route = 'home';
      if (response.action) {
        if (response.action === 'register-account') {
          route = 'home';
        } else if (response.action === 'new-email') {
          route = 'home'; // TODO find a way to open user profile dialog after navigating
        }
      }
      this.navigateToNewRoot(route, PLATFORM.moduleName('pageRoots/app/app'));
    } catch (error) {
      this.showNotificationMessageForError(error);
      throw error;
    }
  }

  private async updateLoginData(jwt: string, user: IWebUser): Promise<void> {
    await this.sessionService.setCurrentJWT(jwt);
    await this.sessionService.setCurrentUser(user);
    NotificationHelper.notifyInverse(
      `${this.i18n.tr('general.welcome')} ${user.username}`
    );
  }

  private showNotificationMessageForError(
    error: unknown,
    defaultMessageTk = 'services.AuthenticationService.anErrorOccurred'
  ): void {
    if (this.isJQueryXHR(error)) {
      this.showNotificationMessageForErrorJSONResponse(error, defaultMessageTk);
    } else {
      NotificationHelper.notifyDanger(
        this.i18n.tr('services.AuthenticationService.anErrorOccurred')
      );
    }
  }

  private showNotificationMessageForErrorJSONResponse(
    response: JQueryXHR,
    defaultMessageTk: string
  ): void {
    let message = this.i18n.tr(defaultMessageTk);
    if (response.responseJSON) {
      message =
        this.getMessageFromServerStatus(response.responseJSON.status) ||
        response.responseJSON.message;
    }

    NotificationHelper.notifyDanger(message);
  }

  private getMessageFromServerStatus(status: string): string {
    return this.i18n.tr(`serverResponses.${status}`);
  }

  public navigateToNewRoot(routeName: string, rootModule: string): void {
    this.router.navigate(routeName, { replace: true, trigger: false });
    this.router.reset();
    // @ts-ignore - deactivate actually exists
    this.router.deactivate();
    void this.aurelia.setRoot(rootModule);
  }

  private isJQueryXHR(value: unknown): value is JQueryXHR {
    if (value == null || typeof value !== 'object') {
      return false;
    }

    // Since JQuery just uses plain objects we can't 100% determine if this is really a JQueryXHR
    // So we check if some of the keys are in the object which is propably good enough
    return (
      'abort' in value &&
      'overrideMimeType' in value &&
      'statusCode' in value &&
      'then' in value
    );
  }
}
